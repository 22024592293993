import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { recipientSchema } from '../../../../shipments/schema/recipientDetailsSchema';
import { useHookFormSubmission } from '../../../../../hook/useHookFormSubmission';
import {
  resetReportClaimFormAction,
  updateReportClaimFormAction,
} from '../../../../../store';

export const useRecipientDetails = (increaseStepHandler) => {
  const navigate = useNavigate();
  const { step1 } = useSelector((state) => state.forms.claimFile.formData);
  const dispatch = useDispatch();

  const { handleSubmit, register, errors, watch, setValue, reset } =
    useHookFormSubmission(recipientSchema);

  const { contact } = watch();

  useEffect(() => {
    setValue('contact', step1 && step1.contact);
  }, [step1]);

  const onCancel = () => {
    // reset();
    dispatch(resetReportClaimFormAction());
    navigate(-1);
  };

  const onSubmit = (values) => {
    increaseStepHandler();
    dispatch(updateReportClaimFormAction({ step: 1, values }));
  };

  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    contact,
    onCancel,
  };
};
