import React from 'react';
import CustomContainer from '../../common/custom/CustomContainer';
import {
  ClientsPanelHeader,
  CustomButton,
  CustomInput,
} from '../../common/custom';
import { DOWNLOAD_FILE, FORMATE_AMOUNT } from '../../helpers/helpers';
import { CustomIcon } from '../../common/customAssets';
import { Tooltip } from '@material-tailwind/react';
import { download } from '../../assets';
import { useProfile } from './useProfile';
import ESignModal from './ESignPolicy';
import { PATHS } from '../../constants/paths';

const Profile = () => {
  const { user, navigate, openSignModal, handleSignModal } = useProfile();

  return (
    <>
      <CustomContainer>
        <ClientsPanelHeader />
        <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
          <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
            Profile
          </h1>
          <div>
            <div className='items-center grid grid-cols-1 sm:grid-cols-2 gap-[16px] mb-[22px] sm:mb-[16x]'>
              <div className='cursor-pointer'>
                <CustomInput
                  readOnly={true}
                  placeholder={user.firstName ? user.firstName : '---'}
                  label='First Name'
                />
              </div>
              <div className='cursor-pointer'>
                <CustomInput
                  readOnly={true}
                  placeholder={user.lastName ? user.lastName : '---'}
                  label='Last Name'
                />
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='mb-4'>
                <CustomInput
                  readOnly
                  // name='declaredValue'
                  placeholder={user.emailAddress ? user.emailAddress : '---'}
                  type='text'
                  label='Email'
                />
              </div>

              <div className='mb-4'>
                <CustomInput
                  readOnly
                  // name='declaredValue'
                  placeholder={user.phoneNumber ? user.phoneNumber : '---'}
                  type='text'
                  label='Phone Number'
                />
              </div>
            </div>
            {/*  */}

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='mb-4'>
                <CustomInput
                  readOnly
                  // name='declaredValue'
                  placeholder={user.entity.DBA ? user.entity.DBA : '---'}
                  type='text'
                  label='DBA'
                />
              </div>

              <div className='mb-4'>
                <CustomInput
                  readOnly
                  // name='declaredValue'
                  placeholder={user?.role ? user?.role : '---'}
                  type='text'
                  label='Role'
                />
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='mb-4'>
                <CustomInput
                  readOnly
                  // name='declaredValue'
                  placeholder={
                    user?.additionalNote ? user?.additionalNote : '---'
                  }
                  type='text'
                  label='Additional Note'
                />
              </div>

              <div className='mb-4'>
                <CustomInput
                  readOnly
                  // name='declaredValue'
                  placeholder={user?.entity?.EIN ? user?.entity?.EIN : '---'}
                  type='text'
                  label='EIN'
                />
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='mb-4'>
                <CustomInput
                  readOnly
                  // name='declaredValue'
                  placeholder={
                    user?.entity?.rate
                      ? FORMATE_AMOUNT(user?.entity?.rate, 2)
                      : '---'
                  }
                  type='text'
                  label='Rate'
                />
              </div>

              <div className='mb-4'>
                <CustomInput
                  readOnly
                  // name='declaredValue'
                  placeholder={
                    user?.entity?.donation ? user?.entity?.donation : '---'
                  }
                  type='text'
                  label='Donation%'
                />
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='mb-4'>
                <CustomInput
                  readOnly
                  // name='declaredValue'
                  placeholder={
                    user?.entity?.since ? user?.entity?.since : '---'
                  }
                  type='text'
                  label='Since'
                />
              </div>

              <div className='mb-4'>
                <CustomInput
                  readOnly
                  // name='declaredValue'
                  placeholder={
                    user?.entity?.website ? user?.entity?.website : '---'
                  }
                  type='text'
                  label='Website'
                />
              </div>
            </div>
            <div className='mb-4'>
              <CustomInput
                readOnly
                name='declaredValue'
                placeholder={user.entity.address ? user.entity.address : '---'}
                type='text'
                label='Address'
              />
            </div>

            {user?.entity?.policyDocument && (
              <div className='mb-4'>
                <div className='flex gap-8'>
                  <div className='flex gap-2'>
                    <div className='font-bold'>My Policy Document:</div>
                    <div className='flex gap-2'>
                      {user?.entity?.policyDocument
                        ? JSON.parse(user?.entity?.policyDocument)?.originalname
                        : 'N/A'}
                      <Tooltip className='mt-[-10px] z-50' content='Download'>
                        <div
                          className='cursor-pointer'
                          onClick={() =>
                            DOWNLOAD_FILE(
                              JSON.parse(user?.entity?.policyDocument)?.location
                            )
                          }
                        >
                          <CustomIcon icon={download} className='w-6 h-6' />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className='flex justify-center'>
                    <CustomButton
                      className='bg-foundation-brown rounded-[8px] text-white w-full h-[28px] text-sm px-3'
                      text='View and Sign'
                      onClick={() => navigate(PATHS.SIGN_POLICY)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='flex justify-center'>
            <CustomButton
              type='button'
              onClick={() => navigate(-1)}
              className='bg-none rounded-[8px] border-[1px] border-foundation-brown  text-foundation-brown max-w-[160px] w-full h-[48px]'
              variant='outline'
              text='Cancel'
            />
          </div>
        </div>
      </CustomContainer>
      {/* {openSignModal && (
        <ESignModal entity={user?.entity} handleClose={handleSignModal} />
      )} */}
    </>
  );
};

export default Profile;
