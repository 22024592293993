import * as Yup from 'yup';

export const addShipFromAddressSchema = Yup.object().shape({
  locationName: Yup.string().required('Location name is required'),
  streetAddress: Yup.string().required('Street address is required'),
  zipCode: Yup.string().required('ZIP code is required'),
  // .matches(/^\d{5}$/, 'Invalid ZIP code'),
});

export default addShipFromAddressSchema;
