import React from 'react';
import { uploadFileIcon } from '../../../../assets';
import FileUpload from '../../../../common/custom/file-upload/file-upload.component';
import { FILE_TYPE } from '../../../../helpers/helpers';
import useConcludeTransit from './useConcludeTransit';
import CircularILoader from '../../../../common/custom/circularLoader';
import FilePreview from '../../../../common/custom/file-preview/file-preview';
import { CustomButton, ValidationErrorText } from '../../../../common/custom';

function ConcludeTransit() {
  const {
    fileError,
    transitFile,
    isLoading,
    handleCancel,
    handleConclude,
    handleUploadTransit,
    handleUploadTransitCancel,
    concludeTransitLoading,
  } = useConcludeTransit();

  const customHtml = `
    <div class="flex flex-col h-[126px] cursor-pointer items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]">
      <div class="flex flex-col items-center">
      <img src=${uploadFileIcon} alt="upload" class="w-[20px] h-[20px]"/>
      <div class="flex flex-col gap-1 mt-3 items-center">
       <div class="flex gap-1">
       <div class="text-[#B2B7BF] text-sm not-italic font-semibold leading-5">Click to upload</div>
       <div class="text-[#475467] text-sm not-italic font-normal leading-5">or drag and drop</div>
       </div>
        <div class="text-[#475467] text-sm not-italic font-normal leading-5">SVG, PNG, JPG, JPEG or PDF</div>
      </div>
      </div>
    </div> 
  `;

  return (
    <div className='flex justify-center mt-[60px]'>
      <div className='flex min-w-[996px] min-h-[375px] flex-col items-start gap-8 shadow-[0px_1px_9px_0px_rgba(0,0,0,0.11)] p-10 rounded-xl'>
        <div className='text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]'>
          Conclude Transit
        </div>
        <div className='flex gap-[6px] w-full flex-col'>
          <div className='text-[#344054] text-sm not-italic font-medium leading-5'>
            You may choose to upload the Deliver Receipts
          </div>
          {transitFile && isLoading ? (
            <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
              <CircularILoader />{' '}
            </div>
          ) : transitFile ? (
            <FilePreview
              files={transitFile}
              handleCancel={handleUploadTransitCancel}
            />
          ) : (
            <FileUpload
              handleChange={handleUploadTransit}
              fileTypes={FILE_TYPE}
              customHtml={customHtml}
            />
          )}
          {!transitFile && fileError ? (
            <div className='mt-1 mb-2'>
              <ValidationErrorText>
                Deliver Receipts File is required
              </ValidationErrorText>
            </div>
          ) : null}

          <div className='flex justify-end mt-[32px]'>
            <div className='flex gap-[32px]'>
              {/* <button
                className='flex border border-solid text-[#77553D] text-base not-italic font-medium leading-6 border-[#77553D] w-40 justify-center bg-[ #fff] items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg border-[#77553D]'
                onClick={handleCancel}
              >
                Cancel
              </button> */}
              {/* <button
                className='flex text-white text-base not-italic font-medium leading-6 w-40 bg-[#77553d] justify-center items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg'
                onClick={handleConclude}
              >
                Conclude
              </button> */}
              <CustomButton
                type='button'
                onClick={handleCancel}
                className='white-bg-btn'
                variant='outline'
                text='Cancel'
              />
              <CustomButton
                disabled={concludeTransitLoading ? true : false}
                className='brown-bg-btn'
                text=' Conclude'
                onClick={handleConclude}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConcludeTransit;
