import { useHookFormSubmission } from '../../../../hook/useHookFormSubmission';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateReportShipmentFormAction } from '../../../../store';
import reportShipmentStepTwoSchema from '../../schema/reportShipmentStepTwoSchema';
import toast from 'react-hot-toast';
import {
  CONFIRMATION_DECLARED_VALUE,
  MAXIMUM_DECLARED_VALUE,
} from '../../../../constants/declaredValue';
import { TOAST_TIME } from '../../../../constants/toastNotifications';
import { HANDLE_FILE_SIZE_CHECK, PARSIFY } from '../../../../helpers/helpers';
import axios from 'axios';
import _ from 'lodash';
import { getShipmentTypesQuery } from '../../../../api/services/admin.service';
import useApi from '../../../../hook/useApi';

export const useReportShipmentStepTwo = (increaseStepHandler, isTrial) => {
  const [declaredValue, setDeclaredValue] = useState('');
  const [declaredValueModal, setDeclaredValueModal] = useState(false);
  const [declaredValueModalClose, setDeclaredValueModalClose] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isIntlShipment, setIsIntlShipment] = useState(false);

  const [merchandiseTypeOptions, setMerchandiseTypeOptions] = useState([]);
  const [getShipmentTypes] = useApi(getShipmentTypesQuery);

  const { addresses } = useSelector((state) => state);
  const { reportShipment } = useSelector((state) => state.forms);
  const dispatch = useDispatch();

  const { handleSubmit, register, errors, trigger, setValue, watch } =
    useHookFormSubmission(reportShipmentStepTwoSchema);

  const watchedMerchandiseType = watch('merchandiseType');

  const user = JSON.parse(localStorage.getItem('login-user'));
  const fromAddress = PARSIFY(addresses.selectedAddress?.address);

  useEffect(() => {
    const _isIntlShipment =
      (fromAddress?.country &&
        fromAddress?.country !== 'USA' &&
        fromAddress?.country !== 'US' &&
        fromAddress?.country !== 'United States') ||
      (reportShipment.formData.step1?.country &&
        reportShipment.formData.step1?.country !== 'USA' &&
        reportShipment.formData.step1?.country !== 'US' &&
        reportShipment.formData.step1?.country !== 'United States');

    setIsIntlShipment(_isIntlShipment);
  }, [fromAddress?.country, reportShipment.formData.step1?.country]);

  const rate =
    user &&
    user.entity &&
    (!isIntlShipment
      ? reportShipment.formData.step1?.state === 'Alaska' ||
        reportShipment.formData.step1?.state === 'Hawaii'
        ? 0.02
        : user?.entity?.rate
      : user?.entity?.internationalRate);

  console.log(
    'from:',
    fromAddress.country,
    'to:',
    reportShipment.formData.step1?.country,
    'rate:',
    rate
  );

  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const _data = await getShipmentTypes();
      setMerchandiseTypeOptions(_data?.data?.allShipmentTypes);
    };
    getData();
    // console.log('isTrial', isTrial);
  }, []);

  const handleUploadAttachments = async (files) => {
    setIsLoading(true);
    toast.loading('Uploading the file. Please wait...');

    const promises = [];
    const uploadedAttachments = [];

    Object.keys(files).forEach((key) => {
      const document = HANDLE_FILE_SIZE_CHECK(20, files[key]);

      if (document) {
        const formData = new FormData();
        formData.append('upload', files[key]);

        const promise = axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => {
            uploadedAttachments.push(response.data);
          })
          .catch((error) => {
            console.error('Error uploading file:', error);
          });

        promises.push(promise);
      }
    });

    try {
      await Promise.all(promises);
      toast.dismiss();
      setIsLoading(false);

      setAttachments([...attachments, ...uploadedAttachments]);
    } catch (error) {
      console.error('Error uploading files:', error);
      toast.dismiss();
      setIsLoading(false);
    }
  };

  const handleUploadAttachmentsCancel = (file) => {
    const flattenedAttachments = _.flatten(attachments);
    setAttachments(() => {
      return flattenedAttachments.filter((attachment) => {
        return attachment.location !== file.location;
      });
    });
  };

  useEffect(() => {
    setValue('document', attachments);
  }, [attachments]);

  useEffect(() => {
    setValue(
      'declaredValue',
      declaredValue ||
        (reportShipment.formData.step2 &&
          reportShipment.formData.step2?.declaredValue)
    );
    setDeclaredValue(
      reportShipment.formData.step2 &&
        reportShipment.formData.step2?.declaredValue
    );
    setValue(
      'merchandiseType',
      reportShipment.formData.step2?.merchandiseType
        ? reportShipment.formData.step2.merchandiseType
        : ''
    );
    setValue('document', reportShipment.formData.step2?.document || null);
    setAttachments(reportShipment.formData.step2?.document || []);
    setValue(
      'additionalNote',
      reportShipment.formData.step2
        ? reportShipment.formData.step2?.additionalNote
        : ''
    );
  }, []);

  const closeDeclaredValueModal = () => {
    setDeclaredValueModal(false);
    setDeclaredValueModalClose(true);
  };

  const openDeclaredValueModal = () => {
    setDeclaredValueModal(true);
  };

  const handleDeclaredValue = (value) => {
    setDeclaredValue(value);
  };

  const onSubmit = (values) => {
    // if (Number(declaredValue) > MAXIMUM_DECLARED_VALUE) {
    //   toast.dismiss();
    //   return toast.error('Value exceed', { duration: TOAST_TIME });
    // }
    // if (
    //   Number(declaredValue) > CONFIRMATION_DECLARED_VALUE &&
    //   !declaredValueModalClose
    // ) {
    //   return setDeclaredValueModal(true);
    // }
    increaseStepHandler();
    values.isTrial = isTrial;
    // console.log(values, 'values');
    dispatch(updateReportShipmentFormAction({ step: 2, values }));
  };

  return {
    rate,
    handleSubmit,
    onSubmit,
    register,
    errors,
    trigger,
    setValue,
    watchedMerchandiseType,
    declaredValueModalClose,
    closeDeclaredValueModal,
    declaredValueModal,
    declaredValue,
    isLoading,
    attachments: _.flatten(attachments),
    handleUploadAttachments,
    handleUploadAttachmentsCancel,
    handleDeclaredValue,
    merchandiseTypeOptions,
  };
};
