import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { PATHS } from './constants/paths';

import ThankYouPage from './features/auth/thank-you-page/thank-you-page.component';
import ConcludeTransit from './features/claims/components/concludeTransit/conclude-transit';
import CreateClaim from './features/claims/create/create-claim.component';
import EditClaim from './features/claims/edit/edit-claim.component';
import PrivateRoute from './features/private';
import Shipments from './features/shipments/shipments';
import Footer from './layout/footer';
import Navbar from './layout/navbar/Navbar';
import {
  AddFromShipAddressPage,
  BillingsPage,
  CheckEmailPage,
  ClaimsPage,
  EditShipmentPage,
  ExtendedWarranty,
  ForgetPasswordPage,
  HomePage,
  LoginPage,
  PageNotFound,
  ProfilePage,
  RebateProgramme,
  RefundPolicy,
  ReportShipmentsPage,
  ResetPasswordPage,
  SignUpStepperPage,
  StaffListPage,
  TermsAndCondition,
  FineArtTerms,
  FurnitureTermsAndCondition,
  ViewShipmentDetailPage,
  DashboardPage,
} from './pages';

import PaymentMethodListPage from './pages/payment/PaymentMethodListPage';
import SaveCardPage from './pages/payment/SaveCardPage';

import EditShipFromAddressPage from './pages/ship-from/EditShipFromAddressPage';
import ShipFromPage from './pages/ship-from/ShipFromPage';
import AddStaffMemberPage from './pages/staff/AddStaffMemberPage';
import ForgetuserIdPage from './pages/auth/ForgetUserId';
import { CustomScrollToTop } from './common/custom';
import { ViewShipmentDetail } from './features/shipments';
import ReportManualClaim from './features/claims/report-manual-claim/create-claim.component';
import SignPoloicyPage from './pages/sign-policy';
import ClaimDetails from './features/claims/detail/claim-detail.component';
import ManualClaimDetails from './features/claims/detail/manual-claim-detail.component';
import CalculatorPage from './pages/calculator/CalculatorPage';

const App = () => {
  const token = JSON.parse(localStorage.getItem('token'));

  const location = useLocation();
  const authPage = location.pathname.split('/')[1] === 'auth';
  const defaultRoute = location.pathname === '/';

  useEffect(() => {}, []);

  return (
    <div className='flex flex-col justify-between   h-[100vh]'>
      {/* {defaultRoute && token ? (
        <Navigate
          to={PATHS.SHIPMENTS}
          replace
        />
      ) : defaultRoute ? (
        <Navigate
          to={PATHS.DEFAULT_LOGIN}
          replace
        />
      ) : null} */}

      {defaultRoute && token ? (
        <Navigate to={PATHS.HOME} replace />
      ) : defaultRoute ? (
        <Navigate to={PATHS.DEFAULT_LOGIN} replace />
      ) : null}

      {authPage ? null : <Navbar />}
      <div className=' pt-[55px]'>
        <Routes>
          <Route exact path={PATHS.DEFAULT_LOGIN} element={<LoginPage />} />
          <Route exact path={PATHS.PROFILE} element={<ProfilePage />} />
          <Route exact path={PATHS.SIGN_POLICY} element={<SignPoloicyPage />} />

          <Route
            exact
            path={PATHS.FORGET_USER_ID_PAGE}
            element={<ForgetuserIdPage />}
          />
          <Route
            exact
            path={PATHS.FORGET_PASSWORD}
            element={<ForgetPasswordPage />}
          />

          <Route exact path={PATHS.CHECK_EMAIL} element={<CheckEmailPage />} />
          <Route
            exact
            path={PATHS.RESET_PASSWORD}
            element={<ResetPasswordPage />}
          />

          <Route
            exact
            path={PATHS.SIGN_UP_STEPPER}
            element={<SignUpStepperPage />}
          />

          <Route exact path={PATHS.THANK_YOU_PAGE} element={<ThankYouPage />} />

          {/*********************HOME********/}
          <Route
            exact
            path={PATHS.HOME}
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          {/*********************************/}
          {/***********CLAIMS************/}
          <Route
            exact
            path={PATHS.CLAIMS}
            element={
              <PrivateRoute>
                <ClaimsPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.REPORT_MANUAL_CLAIM}
            element={
              <PrivateRoute>
                <ReportManualClaim />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.CLAIM_FILE}
            element={
              <PrivateRoute>
                <CreateClaim />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.EDIT_CLAIM}/:id`}
            element={
              <PrivateRoute>
                <EditClaim />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.CONCLUDE_TRANSIT}/:id`}
            element={
              <PrivateRoute>
                <ConcludeTransit />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.CLAIM_DETAIL}/:id`}
            element={
              <PrivateRoute>
                <ClaimDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.MANUAL_CLAIM_DETAIL}/:id`}
            element={
              <PrivateRoute>
                <ManualClaimDetails />
              </PrivateRoute>
            }
          />

          {/******************************/}

          {/******** SHIPMENTS*******/}
          <Route
            exact
            path={PATHS.SHIPMENTS}
            element={
              <PrivateRoute>
                <Shipments />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.REPORTS_SHIPMENTS}
            element={
              <PrivateRoute>
                <ReportShipmentsPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.EDIT_SHIPMENT}/:id`}
            element={
              <PrivateRoute>
                <EditShipmentPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.VIEW_SHIPMENT_DETAILS}/:id`}
            element={
              <PrivateRoute>
                <ViewShipmentDetailPage />
              </PrivateRoute>
            }
          />
          {/**************************/}

          {/******** SHip From *****************/}

          <Route
            exact
            path={PATHS.SHIP_FROM}
            element={
              <PrivateRoute>
                <ShipFromPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.ADD_SHIP_FROM_ADDRESS}
            element={
              <PrivateRoute>
                <AddFromShipAddressPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`${PATHS.EDIT_SHIP_FROM_ADDRESS}/:id`}
            element={
              <PrivateRoute>
                <EditShipFromAddressPage />
              </PrivateRoute>
            }
          />

          {/************************************/}
          {/*********** Staff *******************/}
          <Route
            exact
            path={PATHS.STAFF}
            element={
              <PrivateRoute>
                <StaffListPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.STAFF_ADD_STAFF_MEMBER}
            element={
              <PrivateRoute>
                <AddStaffMemberPage />
              </PrivateRoute>
            }
          />
          {/* ***********************************/}
          {/****************payments*/}

          <Route
            exact
            path={PATHS.PAYMENT}
            element={
              <PrivateRoute>
                <PaymentMethodListPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.PAYMENT_ADD_PAYMENT_METHOD_CARD}
            element={
              <PrivateRoute>
                <SaveCardPage />
              </PrivateRoute>
            }
          />

          {/* <Route
            exact
            path='/auth/terms-of-use'
            element={<TermsOfUse />}
          /> */}
          {/* <Route
            exact
            path='/auth/privacy-policy'
            element={<PrivacyPolicy />}
          /> */}

          {/**********Footer Links Page**************/}
          {/* <Route
            exact
            path={PATHS.CHARITY}
            element={
              <PrivateRoute>
                <Charity />
              </PrivateRoute>
            }
          /> */}
          <Route
            exact
            path={PATHS.REBATE_PROGRAMME}
            element={
              <PrivateRoute>
                <RebateProgramme />
              </PrivateRoute>
            }
          />
          {/* <Route
            exact
            path={PATHS.NO_CLAIM_REWARD_PROGRAMME}
            element={
              <PrivateRoute>
                <NoClaimRewardProgramme />
              </PrivateRoute>
            }
          /> */}
          <Route
            exact
            path={PATHS.EXTENDED_WARRANTY}
            element={
              <PrivateRoute>
                <ExtendedWarranty />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.TERMS_AND_CONDITION}
            element={
              <PrivateRoute>
                <TermsAndCondition />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.FINE_ART_TERMS}
            element={
              <PrivateRoute>
                <FineArtTerms />
              </PrivateRoute>
            }
          />
          {/* <Route
            exact
            path={PATHS.FURNITURE_TERMS_AND_CONDITION}
            element={
              <PrivateRoute>
                <FurnitureTermsAndCondition />
              </PrivateRoute>
            }
          /> */}
          <Route
            exact
            path={PATHS.REFUND_POLICY}
            element={
              <PrivateRoute>
                <RefundPolicy />
              </PrivateRoute>
            }
          />
          {/* **********************/}
          {/************ Dashboard ***********************/}
          <Route
            exact
            path={PATHS.DASHBOARD}
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            }
          />
            <Route
            exact
            path={PATHS.TEST_REPORT_SHIPMENT}
            element={
              <PrivateRoute>
                <ReportShipmentsPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={PATHS.CALCULATOR}
            element={
              <PrivateRoute>
                <CalculatorPage />
              </PrivateRoute>
            }
          />
          {/* BillingsPage */}
          <Route
            exact
            path={PATHS.BILLINGS}
            element={
              <PrivateRoute>
                <BillingsPage />
              </PrivateRoute>
            }
          />
          {/*******************/}

          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default CustomScrollToTop(App);
