import React from "react";

import { DesktopNavbar } from "./components";
import MobileNavbar from "./components/mobileNavbar";
import { useNavbar } from "./useNavbar";

const Navbar = () => {
  const { data } = useNavbar();

  return (
    <>
      <div className="lg:hidden">
        <MobileNavbar />
      </div>
      <DesktopNavbar />
    </>
  );
};

export default Navbar;
