import { Tooltip } from '@material-tailwind/react';
import React from 'react';
import { viewIcon } from '../../../../assets';
import { CustomText } from '../../../../common/custom';
import { CustomIcon } from '../../../../common/customAssets';

const ClaimsActionMenu = ({ openViewClaimModal, data, upper }) => {
  return (
    <div
      className={`w-[190px] z-[100]   shadow-md bg-foundational-white  absolute ${
        upper ? 'top-[-50px]' : 'top-[30px]'
      } right-[50px] rounded-[8px] flex flex-col items-center justify-center  gap-[2px] py-[4px] border-[1px] border-solid border-[#EAECF0] `}
    >
      <div
        className='cursor-pointer flex items-center w-full min-h-[40px] px-[8px] bg-hover rounded-[6px]'
        onClick={() => openViewClaimModal(data)}
      >
        <Tooltip className='mt-[-10px] z-50' content='View Claim'>
          <div>
            {' '}
            <CustomIcon icon={viewIcon} />
          </div>
        </Tooltip>

        <CustomText className='font-inter font-[500] ml-[8px]'>
          View/Edit Claim
        </CustomText>
      </div>

      {/* <div className="cursor-pointer flex items-center w-full min-h-[40px] px-[8px] bg-hover rounded-[6px]" onClick={openDeleteModal}>
        <Tooltip className="mt-[-10px] z-50" content="Delete">
          <div>
            {" "}
            <CustomIcon icon={deleteIcon} />
          </div>
        </Tooltip>

        <CustomText className="font-inter font-[500] ml-[8px]">
         Delete
        </CustomText>
      </div> */}
    </div>
  );
};

export default ClaimsActionMenu;
