import React from "react";
import { CustomButton, CustomInput, CustomText } from "../../../common/custom";
import FormImageLayout from "../component/FormImageLayout";
import { useForgetPassword } from "./useForgetPassword";

import { PATHS } from "../../../constants/paths";

import CustomHeading from "../../../common/custom/CustomHeading";

import { backIcon } from "../../../assets";
import BackButton from "../../../common/custom/BackButton";

const ForgetPassword = () => {
  const {
    handleSubmit,
    onSubmit,
    register,
    setValue,
    trigger,
    errors,
    forgetPasswordLoading,
  } = useForgetPassword();
  return (
    <FormImageLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomHeading as="h1">Trouble logging in?</CustomHeading>
        <CustomText
          as="p"
          className="text-para-black   font-[500] font-roboto text-small-para md:text-medium-para text-center mt-[12px] mb-[28px]"
        >
          Please add the info you gave us when you created your account.
        </CustomText>
        <div className="mb-[30px]">
          <CustomInput
            name="email"
            register={register}
            placeholder="Enter your email"
            label="Email"
            errors={errors}
            trigger={trigger}
            setValue={setValue}
          />
        </div>

        <CustomButton
          disabled={forgetPasswordLoading ? true : false}
          text="Reset password"
        />

        <div className=" flex justify-center mt-[32px]">
          <BackButton link={PATHS.DEFAULT_LOGIN} icon={backIcon} />
        </div>
      </form>
    </FormImageLayout>
  );
};

export default ForgetPassword;
