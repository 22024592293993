import React, { useRef, useState } from "react";
import { CustomIcon } from "../../../common/customAssets";
import { logoutBtn, userCircle, userIcon } from "../../../assets";
import { CustomButton } from "../../../common/custom";
import useOutsideClick from "../../../hook/useOutsideClick";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants/paths";

const User = ({ type, logout }) => {
  const [toggleLogoutDropDown, setToggleLogoutDropDown] = useState(false);
  const logoutDropdownRef = useRef(null);
  const navigate = useNavigate();

  useOutsideClick(logoutDropdownRef, () => setToggleLogoutDropDown(false));
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setToggleLogoutDropDown((prevState) => !prevState);
      }}
      className="cursor-pointer relative max-w-[32px] w-full rounded-full bg-foundation-gray h-[32px] flex justify-center items-center"
    >
      <CustomIcon icon={userCircle} />
      {toggleLogoutDropDown ? (
        <div
          ref={logoutDropdownRef}
          className={` flex items-center  w-[161px] h-auto z-10 flex-col bg-pure-white  absolute 
          ${
            type
              ? "left-[-100px] top-[50px] translate-x-[100px] "
              : "right-[-100px] top-[50px] translate-x-[-100px]"
          } 

            rounded-[8px] shadow-lg `}
        >
          <CustomButton
            className="px-[8px] py-1 flex items-center gap-[8px] text-[16px] font-[inter] text-[#101828]  w-full bg-hover "
            text="Logout"
            type="button"
            onClick={logout}
            icon={logoutBtn}
          />
          <CustomButton
            className="px-[8px] py-2 flex items-center gap-[8px] text-[16px] font-[inter] text-[#101828]  w-full bg-hover "
            text="My Account"
            type="button"
            onClick={() => navigate(PATHS.PROFILE)}
            icon={userIcon}
          />
        </div>
      ) : null}
    </div>
  );
};

export default User;
