import { useState, useEffect, useRef } from 'react';
import {
  ClientsPanelHeader,
  CustomButton,
  CustomHeading,
  CustomModal,
  CustomText,
} from '../../common/custom';
import { CustomIcon } from '../../common/customAssets';
import { cross, esign, tickIcon } from '../../assets';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import ReactSignatureCanvas from 'react-signature-canvas';
import { PDFDocument, rgb } from 'pdf-lib';
import toast, { LoaderIcon } from 'react-hot-toast';
import axios from 'axios';
import { updateEntityMutation } from '../../api/services/entityMembers.service';
import useApi from '../../hook/useApi';
import { TOAST_TIME } from '../../constants/toastNotifications';
import CustomContainer from '../../common/custom/CustomContainer';
import SignPopup from './SignPopup';
import { useNavigate } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  textLayerMode: 2,
};

const ESignPolicy = () => {
  const user = JSON.parse(localStorage.getItem('login-user'));
  const document = JSON.parse(user?.entity?.policyDocument);
  const navigate = useNavigate();
  const signatureRef = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [signature, setSignature] = useState(null);
  const [signaturePosition, setSignaturePosition] = useState(null);
  const pdfContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openSignModal, setOpenSignModal] = useState(false);

  const [updateEntity] = useApi(updateEntityMutation);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const clearSignature = () => signatureRef.current.clear();

  //   const saveSignature = () => {
  //     const dataUrl = signatureRef.current.toDataURL('image/png');
  //     setSignature(dataUrl);
  //   };

  const saveSignature = () => {
    const dataUrl = signatureRef.current.toDataURL('image/png');
    setSignature(dataUrl);
    setIsDragging(true); // Set dragging to true initially
    handleCloseSignModal();
  };

  //   const handleMouseDown = (event) => {
  //     if (signature) {
  //       const rect = pdfContainerRef.current.getBoundingClientRect();
  //       const offsetX =
  //         event.clientX -
  //         rect.left -
  //         (signaturePosition ? signaturePosition.x : 0);
  //       const offsetY =
  //         event.clientY -
  //         rect.top -
  //         (signaturePosition ? signaturePosition.y : 0);
  //       setDragOffset({ x: offsetX, y: offsetY });
  //       setIsDragging(true);
  //     }
  //     event.preventDefault();
  //   };

  //   drag sign instantly after click on save
  const handleMouseDown = (event) => {
    if (signature) {
      const rect = pdfContainerRef.current.getBoundingClientRect();
      const offsetX =
        event.clientX -
        rect.left -
        (signaturePosition ? signaturePosition.x : 0);
      const offsetY =
        event.clientY -
        rect.top -
        (signaturePosition ? signaturePosition.y : 0);
      setDragOffset({ x: offsetX, y: offsetY });
      setIsDragging(true);
    }
    event.preventDefault();
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const rect = pdfContainerRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top - dragOffset.y;
      setSignaturePosition({ x, y });
    }
  };

  //   const handleMouseUp = () => {
  //     setIsDragging(false);
  //   };

  const handleMouseUp = () => {
    setIsDragging(false);
    // Ensure the signature is placed correctly
    if (signature && signaturePosition) {
      // setSignaturePosition({ x: signaturePosition.x, y: signaturePosition.y });
    }
  };

  const removeSignature = () => {
    setSignature(null);
    setSignaturePosition(null);
  };

  useEffect(() => {
    const container = pdfContainerRef.current;
    if (isDragging) {
      if (container) {
        container.addEventListener('mousemove', handleMouseMove);
        container.addEventListener('mouseup', handleMouseUp);
      }
    } else {
      if (container) {
        container.removeEventListener('mousemove', handleMouseMove);
        container.removeEventListener('mouseup', handleMouseUp);
      }
    }
    return () => {
      if (container) {
        container.removeEventListener('mousemove', handleMouseMove);
        container.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [isDragging]);

  //   const handleESign = async () => {
  //     try {
  //       const originalPDFBytes = await fetch(document?.location).then((res) =>
  //         res.arrayBuffer()
  //       );
  //       const originalPDFDoc = await PDFDocument.load(originalPDFBytes);

  //       // Embed signature image
  //       const signatureImage = await fetch(signature).then((res) =>
  //         res.arrayBuffer()
  //       );
  //       const signatureImagePage = await originalPDFDoc.embedPng(signatureImage);
  //       const pages = originalPDFDoc.getPages();
  //       const page = pages[0]; // Assuming you want to place the signature on the first page
  //       const { width, height } = signatureImagePage.scale(1);

  //       // Draw the signature on the PDF
  //       page.drawImage(signatureImagePage, {
  //         x: signaturePosition.x,
  //         y: page.getHeight() - signaturePosition.y - height,
  //         width,
  //         height,
  //         opacity: 1,
  //       });

  //       // Save the updated PDF
  //       const updatedPDFBytes = await originalPDFDoc.save();

  //       // Create a new Blob for the updated PDF
  //       const updatedPDFBlob = new Blob([updatedPDFBytes], {
  //         type: document.mimetype,
  //       });

  //       // Create a new File object with the updated PDF and modified metadata
  //       const updatedFileName = document.originalname.replace(
  //         '.pdf',
  //         '-signed.pdf'
  //       ); // Append 'signed' to the original name
  //       const updatedFile = new File([updatedPDFBlob], updatedFileName, {
  //         type: document.mimetype,
  //         lastModified: new Date().getTime(),
  //       });

  //       // Create FormData and append file
  //       const formData = new FormData();
  //       formData.append('upload', updatedFile);

  //       // Upload the updated file
  //       await axios
  //         .post('https://api.ceasta.com/fileUpload', formData, {
  //           headers: {
  //             'Content-Type': 'multipart/form-data',
  //           },
  //         })
  //         .then((response) => {
  //           console.log('File uploaded successfully:', response.data);
  //           toast.success('Policy document signed successfully');
  //           toast.dismiss();
  //         })
  //         .catch((err) => {
  //           console.log('Error in uploading the file:', err);
  //           toast.error('Policy document could not be signed!');
  //           toast.dismiss();
  //         });
  //     } catch (err) {
  //       console.log('Error updating and uploading PDF:', err);
  //       toast.error('Policy document could not be signed!');
  //       toast.dismiss();
  //     }
  //   };

  const handleESign = async () => {
    setLoading(true);
    try {
      const originalPDFBytes = await fetch(document?.location).then((res) =>
        res.arrayBuffer()
      );
      const originalPDFDoc = await PDFDocument.load(originalPDFBytes);

      // Embed signature image
      const signatureImage = await fetch(signature).then((res) =>
        res.arrayBuffer()
      );
      const signatureImagePage = await originalPDFDoc.embedPng(signatureImage);
      const pages = originalPDFDoc.getPages();

      // Get the page where the signature is placed
      const page = pages[pages.length - 1];

      // update these dimensions to set sign position
      // Draw the signature on the PDF
      page.drawImage(signatureImagePage, {
        x: 200,
        y: 240,
        width: 200,
        height: 160,
        opacity: 1,
      });

      // Save the updated PDF
      const updatedPDFBytes = await originalPDFDoc.save();

      // Create a new Blob for the updated PDF
      const updatedPDFBlob = new Blob([updatedPDFBytes], {
        type: document.mimetype,
      });

      // Create a new File object with the updated PDF and modified metadata
      const updatedFileName = document.originalname.replace(
        '.pdf',
        '-signed.pdf'
      ); // Append 'signed' to the original name
      const updatedFile = new File([updatedPDFBlob], updatedFileName, {
        type: document.mimetype,
        lastModified: new Date().getTime(),
      });

      // Create FormData and append file
      const formData = new FormData();
      formData.append('upload', updatedFile);

      // Upload the updated file
      await axios
        .post('https://api.ceasta.com/fileUpload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async (response) => {
          const apiData = {
            id: parseInt(user?.entity.id),
            signedPolicyDocument: JSON.stringify([...response.data][0]),
          };
          const { data, errors } = await updateEntity({ ...apiData });
          if (data) {
            // toast.success('Policy document has been signed successfully!', {
            //   duration: TOAST_TIME,
            // });
          }
          setLoading(false);
          setSuccess(true);
        })
        .catch((err) => {
          setLoading(false);
          console.log('Error in uploading the file:', err);
          toast.error('Policy document could not be signed!', {
            duration: TOAST_TIME,
          });
        });
    } catch (err) {
      setLoading(false);
      console.log('Error signing the policy document:', err);
      toast.error(
        'Signature not added or Policy document could not be signed!',
        {
          duration: TOAST_TIME,
        }
      );
    }
  };

  const handleOpenSignModal = () => {
    setOpenSignModal(true);
  };

  const handleCloseSignModal = () => {
    setOpenSignModal(false);
    setLoading(false);
    setSuccess(false);
  };

  return (
    <>
      <CustomContainer>
        <ClientsPanelHeader />
        <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
          <div className='flex justify-between'>
            <div className='flex justify-start items-center gap-1 '>
              <div className='bg-[#FEE4E2] border-[8px] border-[#FEF3F2] w-[48px] h-[48px] shrink-0 flex justify-center items-center rounded-full'>
                <CustomIcon icon={esign} />
              </div>
              <h1 className='text-[26px] font-semibold text-foundation-brown font-inter'>
                E-Sign the Policy
              </h1>
            </div>
            <CustomButton
              className='bg-foundation-brown rounded-[4px] text-white max-w-[80px] w-full h-[36px] '
              text='Sign'
              onClick={handleOpenSignModal}
            />
          </div>

          <div>
            <div className='items-center grid grid-cols-1 gap-[16px] mb-[22px] sm:mb-[16x]'>
              <div className='py-4 bg-pure-white w-full rounded-[12px] flex flex-col items-center gap-1 justify-center'>
                {document ? (
                  <div
                    className='pdf-preview'
                    ref={pdfContainerRef}
                    style={{
                      // height: '400px',
                      width: '816px',
                      overflowY: 'auto',
                      position: 'relative',
                      userSelect: 'none', // Disable text selection on pdf
                    }}
                    onMouseDown={(event) => {
                      if (signature) {
                        handleMouseDown(event);
                      }
                    }}
                  >
                    <Document
                      file={document?.location}
                      onLoadSuccess={onDocumentLoadSuccess}
                      options={options}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <div
                          key={`page_${index + 1}`}
                          style={{
                            position: 'relative',
                          }}
                        >
                          <Page
                            pageNumber={index + 1}
                            renderTextLayer={true}
                            renderMode='svg'
                            width={816}
                          />
                          {signature && signaturePosition && (
                            <div
                              style={{
                                position: 'absolute',
                                left: signaturePosition.x,
                                top: signaturePosition.y,
                                cursor: 'move',
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px dashed black',
                                zIndex: 5,
                              }}
                              onMouseDown={handleMouseDown}
                            >
                              <img
                                src={signature}
                                alt='Signature'
                                style={{
                                  width: '200px',
                                }}
                              />
                              <div
                                onClick={removeSignature}
                                style={{
                                  position: 'absolute',
                                  top: '-10px',
                                  right: '-10px',
                                  cursor: 'pointer',
                                  //   border: '1px solid red',
                                  borderRadius: '50%',
                                  width: '20px',
                                  height: '20px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  color: 'white',
                                  fontSize: '14px',
                                  zIndex: 10,
                                }}
                              >
                                <CustomIcon
                                  icon={cross}
                                  className='h-[12px] w-[12px] cursor-pointer'
                                />
                              </div>
                            </div>
                          )}
                          <p className='text-center'>
                            Page {index + 1} of {numPages}
                          </p>
                        </div>
                      ))}
                    </Document>
                  </div>
                ) : (
                  <CustomText>No document available for preview.</CustomText>
                )}

                <div className='h-8'></div>
                <div className='h-[44px] flex justify-center gap-[12px] w-full max-w-[476px] mt-[8px] '>
                  <CustomButton
                    type='button'
                    onClick={() => navigate(-1)}
                    className='bg-none rounded-[8px] border-[1px] border-foundation-brown text-foundation-brown max-w-[160px] w-full h-[48px]'
                    variant='outline'
                    text='Cancel'
                  />
                  <CustomButton
                    className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
                    text='Save'
                    onClick={handleESign}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomContainer>
      {(openSignModal || loading || success) && (
        <SignPopup
          success={success}
          loading={loading}
          signatureRef={signatureRef}
          saveSignature={saveSignature}
          clearSignature={clearSignature}
          handleOpenSignModal={handleOpenSignModal}
          handleCloseSignModal={handleCloseSignModal}
        />
      )}
    </>

    /*
    <CustomModal>
      {success ? (
        <div className='max-w-[600px] py-4 bg-pure-white w-full rounded-[12px] flex flex-col items-center gap-1 justify-center'>
          <div className='max-w-[600px] py-4 bg-pure-white w-full rounded-[12px] flex flex-col items-center gap-4 justify-center'>
            <div className='bg-green-500 rounded-full p-4 success-tick'>
              <CustomIcon
                icon={tickIcon}
                className='h-[48px] w-[48px] text-green-500'
              />
            </div>
            <div className='text-[24px] font-inter leading-[32px] font-[600] text-green-600'>
              Document Signed Successfully!
            </div>
          </div>
          <div className=' h-[44px] flex justify-center gap-[12px] w-full max-w-[476px] mt-[8px] '>
            <CustomButton
              type='button'
              onClick={handleClose}
              className='bg-none rounded-[8px] border-[1px] border-foundation-brown text-foundation-brown max-w-[160px] w-full h-[48px]'
              variant='outline'
              text='Close'
            />
          </div>
        </div>
      ) : loading ? (
        <LoaderIcon />
      ) : (
        <div className='max-w-[600px] py-4 bg-pure-white w-full rounded-[12px] flex flex-col items-center gap-1 justify-center'>
          <div className='max-w-[554px] w-full h-[48px] gap-1 flex justify-between'>
            <div className='flex justify-start items-center gap-1 '>
              <div className='bg-[#FEE4E2] border-[8px] border-[#FEF3F2] w-[48px] h-[48px] shrink-0 flex justify-center items-center rounded-full'>
                <CustomIcon icon={esign} />
              </div>
              <CustomHeading className='text-[18px] font-inter leading-[28px] font-[600] whitespace-nowrap'>
                E-Sign the Document
              </CustomHeading>
            </div>
            <div className='flex items-center'>
              <div
                onClick={handleClose}
                className=' cursor-pointer h-[24px] w-full'
              >
                <CustomIcon icon={cross} className='h-[12px] w-[12px]' />
              </div>
            </div>
          </div>

          {document ? (
            <div
              className='pdf-preview'
              ref={pdfContainerRef}
              style={{
                height: '400px',
                overflowY: 'auto',
                position: 'relative',
                userSelect: 'none', // Disable text selection on pdf
              }}
              onMouseDown={(event) => {
                if (signature) {
                  handleMouseDown(event);
                }
              }}
            >
              <Document
                file={document?.location}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <div
                    key={`page_${index + 1}`}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <Page
                      pageNumber={index + 1}
                      renderTextLayer={true}
                      renderMode='svg'
                      width={568}
                    />
                    {signature && signaturePosition && (
                      <div
                        style={{
                          position: 'absolute',
                          left: signaturePosition.x,
                          top: signaturePosition.y,
                          cursor: 'move',
                          display: 'flex',
                          alignItems: 'center',
                          border: '1px dashed black',
                          zIndex: 5,
                        }}
                        onMouseDown={handleMouseDown}
                      >
                        <img
                          src={signature}
                          alt='Signature'
                          style={{
                            width: '200px',
                          }}
                        />
                        <div
                          onClick={removeSignature}
                          style={{
                            position: 'absolute',
                            top: '-10px',
                            right: '-10px',
                            cursor: 'pointer',
                            //   border: '1px solid red',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontSize: '14px',
                            zIndex: 10,
                          }}
                        >
                          <CustomIcon
                            icon={cross}
                            className='h-[12px] w-[12px] cursor-pointer'
                          />
                        </div>
                      </div>
                      // <img
                      //   src={signature}
                      //   alt='Signature'
                      //   style={{
                      //     position: 'absolute',
                      //     left: signaturePosition.x + 200,
                      //     top: signaturePosition.y + 240,
                      //     width: '200px',
                      //     cursor: 'move',
                      //   }}
                      //   onMouseDown={handleMouseDown}
                      // />
                    )}
                    <p className='text-center'>
                      Page {index + 1} of {numPages}
                    </p>
                  </div>
                ))}
              </Document>
            </div>
          ) : (
            <CustomText>No document available for preview.</CustomText>
          )}

          <div className='bg-blue-400 border-1 rounded'>
            <ReactSignatureCanvas
              ref={signatureRef}
              penColor='black'
              canvasProps={{
                width: 300,
                height: 150,
                className: 'signature-canvas',
              }}
            />
            <div className='flex justify-center gap-2'>
              <button
                onClick={clearSignature}
                className='text-black-400 border-red-400 border-1 rounded'
              >
                Clear
              </button>
              <button
                onClick={saveSignature}
                className='text-white border-blue-400 border-1 rounded'
              >
                Save
              </button>
            </div>
          </div>
          <div className=' h-[44px] flex justify-center gap-[12px] w-full max-w-[476px] mt-[8px] '>
            <CustomButton
              type='button'
              onClick={handleClose}
              className='bg-none rounded-[8px] border-[1px] border-foundation-brown text-foundation-brown max-w-[160px] w-full h-[48px]'
              variant='outline'
              text='Cancel'
            />
            <CustomButton
              className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
              text='Sign'
              onClick={handleESign}
            />
          </div>
        </div>
      )}
    </CustomModal>
    */
  );
};

export default ESignPolicy;
