import * as yup from "yup";

export const editShipmentSchema = yup.object().shape({
  declaredValue: yup
    .number()
    .required("Declared Value is required")
    .typeError("Declared Value must be a number")
    .positive("Declared Value must be a positive number"),
  merchandiseType: yup.string().required("Merchandise Type is required"),
  pickupDate: yup
    .date()
    .required("Pickup date is required"),
    // .min(new Date(), "Pickup date must be a future date"),
  deliveryDate: yup
    .date()
    .required("Delivery date is required"),
    // .min(new Date(), "Delivery date must be a future date"),
  carrier: yup.string().required("Please select carrier"),
});
