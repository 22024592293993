import React from 'react';

// todo.. what is purpose of these props ?
const CustomHeading = ({
  // will receive element in 'as' prop for example as=p,as=h1,as=h1
  as: Element = 'div', // if no 'as' prop passed in this componenet than h1 element will used as default

  // will receive className props  in which tailwind css classes will be passed
  className = 'text-foundation-black font-roboto text-small-heading md:text-medium-heading  font-[600] text-center ', // if no className props are passed than these classes will aplied as default

  // text content will be received as children example "something etc"
  children,
}) =>
  // Render the dynamic text  with dynamic styles and element based on props

  {
    return (
      <Element className={className}>
        {/* dynamic text will be resecived as children */}
        {children}
      </Element>
    );
  };
export default CustomHeading;

// Example usage:

// <CustomHeading
// as="h1"
// className='text-pure-black font-roboto font-semibold text-xl md:text-2xl lg:text-3xl' >
// Hello World
// </CustomHeading>
