import { PATHS } from '../../constants/paths';

export const footerLinkList = [
  // { name: 'Charity', link: PATHS.CHARITY },
  { name: 'Rebate Program', link: PATHS.REBATE_PROGRAMME },
  // { name: 'No Claim Reward Program', link: PATHS.NO_CLAIM_REWARD_PROGRAMME },
  { name: 'Extended Warranty', link: PATHS.EXTENDED_WARRANTY },
  { name: 'Terms & Conditions', link: PATHS.TERMS_AND_CONDITION },
  { name: 'Refund Policy', link: PATHS.REFUND_POLICY },
  { name: 'Fine Arts', link: PATHS.FINE_ART_TERMS },
  // {
  //   name: 'Terms and Conditions-Furniture',
  //   link: PATHS.FURNITURE_TERMS_AND_CONDITION,
  // },
];
