import { CustomText } from '../../../../common/custom';
import { useReportShipmentStepper } from './useShipmentsStepper';

const ReportShipmentsStepper = ({ steps, currentStep }) => {
  const { newSteps, stepDesign } = useReportShipmentStepper(currentStep, steps);

  const displayStep = newSteps.map((step, index) => (
    <div
      key={index}
      className=' flex items-center flex-col '
    >
      <div className='relative flex flex-col items-center'>
        <div
          className={`rounded-full transition duration-500 ease-in-out h-[24px] w-[24px] flex justify-center items-center ${
            step.highlighted && step.selected
              ? stepDesign.completed
              : step.highlighted && step.selected && !step.completed
              ? stepDesign.inProgress
              : stepDesign.notReached
          }`}
        >
          {step.completed ? (
            <span className='bg-foundation-brown w-full h-full rounded-full flex justify-center items-center text-sm text-white'>
              &#10003;
            </span>
          ) : (
            <span className='rounded-full bg-white h-[8px] w-[8px]'></span>
          )}
        </div>
        <CustomText
          className={`absolute  top-[-36px] left-[30px] mt-[36px] text-small-para font-[600]  w-[129px] flex justify-start ${
            step.selected ? 'text-foundation-brown' : 'text-para-black'
          }`}
        >
          {step.description}
        </CustomText>
      </div>
      {index !== newSteps.length - 1 && (
        <div
          // className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
          className={`flex border-2 h-[105px] transition duration-500 ease-in-out ${
            step.completed ? 'border-foundation-brown' : ''
          }`}
        ></div>
      )}
    </div>
  ));

  return (
    <div className=' p-4 hidden  lg:flex flex-col justify-between items-start max-w-[282px] w-full  h-[309px] '>
      {displayStep}
    </div>
  );
};

export default ReportShipmentsStepper;
