import React from 'react';

import CustomTable from '../../../../common/custom/CustomTable/custom-table.component';
import ViewAttachmentsModal from '../../../../common/custom/Modals/ViewAttachmentsModal/modal';
import { SHIPMENTS } from '../../../../constants/columns';
import DeleteModal from '../../../modals/delete-modal';
import ShipmentsActionMenu from '../shipmentsActionMenu';
import { useAllShipmentsList } from './useAllShimentsList';

const AllShipmentsList = ({ allShipmentsData }) => {
  const {
    open,
    handleClose,
    attachments,
    showDeleteModal,
    cancelShipmentHandler,
    closeDeleteModal,
    shipmentActionClickedId,
    toggleActionMenu,
    shipmentActionClickedData,
    openDeleteModal,
    handleActionClick,
    actionMenuRef,
    handleViewAttachments,
    cancelShipmentLoading,
  } = useAllShipmentsList(allShipmentsData);
  return (
    <>
      {showDeleteModal ? (
        <DeleteModal
          modalHeading='Delete Shipment?'
          modalPara='Are you sure you want to delete this Shipment? This action cannot be undone.'
          deleteHandler={() => cancelShipmentHandler(shipmentActionClickedId)}
          closeDeleteModal={closeDeleteModal}
          deleteLoading={cancelShipmentLoading}
        />
      ) : null}

      {open && (
        <ViewAttachmentsModal
          onClose={handleClose}
          title='View Attachments'
          files={attachments}
        />
      )}

      <CustomTable
        header={SHIPMENTS}
        rows={allShipmentsData}
        {...{ handleActionClick, toggleActionMenu }}
        actionMenuRef={actionMenuRef}
        menu={
          <ShipmentsActionMenu
            data={shipmentActionClickedData}
            openDeleteModal={openDeleteModal}
            handleViewAttachments={handleViewAttachments}
            // openViewClaimModal={openViewClaimModal}
          />
        }
      />
    </>
  );
};

export default AllShipmentsList;
