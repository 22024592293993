import { useDispatch, useSelector } from 'react-redux';
import { setStepAction } from '../../../store';
import SignUpStepOne from '../component/signupStepOne';
import SignUpStepTwo from '../component/signupStepTwo';
import SignUpFinalStep from '../component/signupFinalStep';

export const useSignup = () => {
  const { signUp } = useSelector((state) => state.forms);
  const dispatch = useDispatch();

  // increase step
  const increaseStepHandler = () => {
    const step = signUp.currentStep + 1;

    dispatch(setStepAction(step));
  };
  // decrease step
  const decreaseStepHandler = () => {
    const step = signUp.currentStep - 1;
    dispatch(setStepAction(step));
  };

  // display steps

  const displaySteps = (currentStep) => {
    switch (currentStep) {
      case 1:
        return <SignUpStepOne increaseStepHandler={increaseStepHandler} />;
      case 2:
        return (
          <SignUpStepTwo
            increaseStepHandler={increaseStepHandler}
            decreaseStepHandler={decreaseStepHandler}
          />
        );
      case 3:
        return <SignUpFinalStep decreaseStepHandler={decreaseStepHandler} />;
      default:
    }
  };

  //

  return {
    currentStep: signUp.currentStep,
    increaseStepHandler,
    decreaseStepHandler,
    displaySteps,
  };
};
