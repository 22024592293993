import React from 'react';
import { Link } from 'react-router-dom';
import { ceastaLogoWhite } from '../../../../assets';
import CustomContainer from '../../../../common/custom/CustomContainer';
import { CustomIcon } from '../../../../common/customAssets';
import { PATHS } from '../../../../constants/paths';
import MenuList from '../MenuList';
import User from '../User';
import Location from '../location/Location';
import { useDesktopNavbar } from './useDesktopNavbar';

const DesktopNavbar = () => {
  const { logout } = useDesktopNavbar();
  return (
    <nav className='bg-foundation-brown min-h-[80px] lg:flex items-center hidden fixed top-0 w-full z-10'>
      {/* The 'z-10' class ensures the navbar stays on top of other elements */}
      <CustomContainer>
        <CustomContainer className='flex justify-between items-center gap-1  '>
          <Link to={PATHS.HOME}>
            <CustomIcon
              icon={ceastaLogoWhite}
              className='w-[276px] h-[43.23px] '
            />
          </Link>

          <MenuList />

          <div className='flex items-center max-w-[318px] justify-end w-full gap-[20px]'>
            {/* <Location /> */}
            <User logout={logout} />
          </div>
        </CustomContainer>
      </CustomContainer>
    </nav>
  );
};

export default DesktopNavbar;

// STCIKY NAVBAR END
