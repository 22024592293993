import React from 'react';
import CustomContainer from '../../../../common/custom/CustomContainer';
import { CustomText } from '../../../../common/custom';

const ClaimListItem = ({ data }) => {
  return (
    <CustomContainer className='bg-purple-400 border-r border-b border-solid border-gray-[#EAECF0] h-[72px] max-w-[159px] w-full flex flex-col justify-center items-center '>
      <CustomText className='max-w-[120px] w-full  bg-slate-300'>
        {data.recipient.name}
      </CustomText>
      <CustomText className='max-w-[130px] w-full  bg-slate-300 truncate '>
        {data.recipient.email}
      </CustomText>
    </CustomContainer>
  );
};

export default ClaimListItem;
