import React from 'react';
import { Link } from 'react-router-dom';
import { deleteIcon, editIcon } from '../../../../assets';
import { CustomButton, CustomText } from '../../../../common/custom';
import CustomContainer from '../../../../common/custom/CustomContainer';
import { CustomIcon } from '../../../../common/customAssets';
import useNavigateHook from '../../../../hook/use-navigate.hook';
import { PATHS } from '../../../../constants/paths';

const StaffListActionMenu = ({ openDeleteModal, data, upper }) => {
  const { navigate } = useNavigateHook();
  return (
    <>
      <div
        className={`w-[186px]  min-h-[50px] z-10 shadow-md bg-foundational-white  absolute ${
          upper ? 'top-[-65px]' : 'top-[40px]'
        }  right-[50px] rounded-[8px] flex flex-col items-center justify-center border-[1px] border-solid border-[#EAECF0] `}
      >
        {/* <div
          className="max-w-[172px] w-full h-[44px] flex items-center px-[10px]"
          // onClick={() => navigate(`${PATHS.STAFF_ADD_STAFF_MEMBER}/${data.id}`)}
        >
          <CustomIcon icon={editIcon} />
          <CustomText className="font-inter font-[500]">Edit</CustomText>
        </div> */}
        <div
          onClick={openDeleteModal}
          className='cursor-pointer max-w-[172px] w-full h-[44px] flex items-center px-[10px] bg-hover'
        >
          <CustomButton
            type='button'
            icon={deleteIcon}
            text='Delete'
            className='font-inter font-[500] flex gap-2'
          />
        </div>
      </div>
    </>
  );
};

export default StaffListActionMenu;
