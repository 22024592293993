import { gql } from '@apollo/client';
import client from '../apollo.';

export const onboardingMutation = async (signUpData) => {
  const { data, errors } = await client.mutate({
    errorPolicy: 'all',
    mutation: gql`
      mutation CreateApplication(
        $createApplicationInput: CreateApplicationInput!
      ) {
        createApplication(createApplicationInput: $createApplicationInput) {
          address
          businessName
          businessTIN
          currentInsurancePolicy
          emailAddress
          industryType
          phoneNumber
          principalName
          since
          website
          createdAt
        }
      }
    `,
    variables: {
      createApplicationInput: {
        address: signUpData.streetAddress,
        businessName: signUpData.nameOfBusiness,
        currentInsurancePolicy: signUpData.currentInsurancePolicy,
        industryType: signUpData.industry,
        emailAddress: signUpData.emailAddress,
        phoneNumber: signUpData.phoneNumber,
        principalName: signUpData.nameOfPrincipal,
        since: signUpData.businessSince,
        website: signUpData.website,
        businessTIN: signUpData.tin,
        salesPersonID: parseInt(signUpData.referedBy),
      },
    },
  });

  return { data, errors };
};
