import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import useOutsideClick from '../../../../hook/useOutsideClick';
import { SAFE_STRINGIFY } from '../../../../helpers/helpers';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../../constants/paths';

function useAllClaims(isLoading, currentTab) {
  const navigate = useNavigate();
  const actionMenuRef = useRef(null);
  const [claim, setClaim] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewClaimModal, setShowViewClaimModal] = useState(false);
  const [claimActionClickedId, setClaimActionClickedId] = useState(null);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);
  const [claimActionClickedData, setClaimActionClickedData] = useState(null);

  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));

  useEffect(() => {
    isLoading ? toast.loading('Loading...') : toast.dismiss();
  }, []);

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const openViewClaimModal = (data) => {
    setClaim(data);
    // setShowViewClaimModal(true);
    navigate(
      `${
        currentTab == 'claims' ? PATHS.CLAIM_DETAIL : PATHS.MANUAL_CLAIM_DETAIL
      }/${data.id}`
    );
  };

  const closeViewClaimModal = () => setShowViewClaimModal(false);

  const cancelClaimHandler = async (claimId) => {
    return { data: {} };
  };

  const handleActionClick = (e, data) => {
    e.stopPropagation();
    setClaimActionClickedId({ data });
    setClaimActionClickedData(data);

    toggleActionMenu ? setToggleActionMenu(null) : setToggleActionMenu(data.id);
    data && localStorage.setItem('claim', SAFE_STRINGIFY(data));
  };

  return {
    claim,
    actionMenuRef,
    showDeleteModal,
    showViewClaimModal,
    closeDeleteModal,
    closeViewClaimModal,
    toggleActionMenu,
    openDeleteModal,
    openViewClaimModal,
    cancelClaimHandler,
    handleActionClick,
    claimActionClickedId,
    claimActionClickedData,
  };
}

export default useAllClaims;
