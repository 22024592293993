import { gql } from '@apollo/client';
import client from '../apollo.';

export const getIndustriesQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query allIndustries {
        allIndustries {
          id
          name
        }
      }
    `,
  });

  return { data, errors };
};

export const getCarriersQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query allCarriers {
        allCarriers {
          id
          name
          address
          createdAt
        }
      }
    `,
  });

  return { data, errors };
};

export const getShipmentTypesQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query allShipmentTypes {
        allShipmentTypes {
          id
          name
        }
      }
    `,
  });

  return { data, errors };
};
