import { useEffect } from "react";
import { getMyLocationsAddressesQuery } from "../../api";
import useApi from "../../hook/useApi";
import { setAddressAction, storeAllAddressesAction } from "../../store";
import { useDispatch, useSelector } from "react-redux";

export const useNavbar = () => {
  const dispatch = useDispatch();
  const { addresses } = useSelector((state) => state);
  const [getMyLocationsAddressesCall] = useApi(
    getMyLocationsAddressesQuery,
    storeAllAddressesAction
  );

  const user = JSON.parse(localStorage.getItem("login-user"));

  useEffect(() => {
    getMyLocationsAddressesCall(user && user.locationId);
    if (!addresses.selectedAddress) {
      dispatch(setAddressAction(addresses.addresses[0]));
    }
  }, []);

  return { data: addresses.addresses };
};
