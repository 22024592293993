import { useDispatch, useSelector } from "react-redux";
import { setReportClaimFormStepAction } from "../../../store";

export const useCreateClaim = () => {
  const dispatch = useDispatch();
  const { claimFile } = useSelector((state) => state.forms);

  const increaseStepHandler = () => {
    const step = claimFile.currentStep + 1;
    dispatch(setReportClaimFormStepAction(step));
  };

  const decreaseStepHandler = () => {
    const step = claimFile.currentStep - 1;
    dispatch(setReportClaimFormStepAction(step));
  };

  return {
    currentStep: claimFile ? claimFile.currentStep : 1,
    increaseStepHandler,
    decreaseStepHandler,
  };
};
