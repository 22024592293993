import React from 'react';
import { download, uploadFileIcon } from '../../../../../assets';
import CircularILoader from '../../../../../common/custom/circularLoader';
import FilePreview from '../../../../../common/custom/file-preview/file-preview';
import FileUpload from '../../../../../common/custom/file-upload/file-upload.component';
import { DOWNLOAD_FILE, FILE_TYPE } from '../../../../../helpers/helpers';
import useUploadDocument from './use-upload-signed-release';
import { ValidationErrorText } from '../../../../../common/custom';
import { CustomIcon } from '../../../../../common/customAssets';

const UploadDocument = ({
  files,
  handleUpdateClaim,
  releaseFile,
  setReleaseFile,
  fileError,
  setFileError,
}) => {
  const { handleFileUpload, handleFileCancel, isLoading } = useUploadDocument(
    setReleaseFile,
    setFileError
  );

  const customHtml = `
    <div class="flex flex-col h-[126px] cursor-pointer items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]">
      <div class="flex flex-col items-center">
      <img src=${uploadFileIcon} alt="upload" class="w-[20px] h-[20px]" />
      <div class="flex flex-col gap-1 mt-3 items-center">
       <div class="flex gap-1">
       <div class="text-[#B2B7BF] text-sm not-italic font-semibold leading-5">Click to upload</div>
       <div class="text-[#475467] text-sm not-italic font-normal leading-5">or drag and drop</div>
       </div>
        <div class="text-[#475467] text-sm not-italic font-normal leading-5">SVG, PNG, JPG, JPEG or PDF</div>
      </div>
      </div>
    </div> 
  `;

  return (
    <div className='stepper-cards mb-10'>
      <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
        Upload Signed Release Claim
      </h1>
      {files ? (
        files?.map((e, index) => (
          <div key={index} className='flex justify-between items-center pt-1'>
            <div className='flex justify-start items-center'>
              <div className='w-8'></div>
              <div className='text-sm text-[#7D7D7D]'>{e?.originalname}</div>
            </div>
            <div onClick={() => DOWNLOAD_FILE(e?.location)}>
              <CustomIcon icon={download} className='w-6 h-6 cursor-pointer' />
            </div>
          </div>
        ))
      ) : (
        <div className='grid gap-4'>
          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#344054] text-sm not-italic font-medium leading-5'>
              Upload Signed Release Claim, such as a document or image with a
              maximum file size of 20MB.*
            </div>
            {releaseFile && isLoading ? (
              <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
                <CircularILoader />{' '}
              </div>
            ) : releaseFile ? (
              <FilePreview file={releaseFile} handleCancel={handleFileCancel} />
            ) : (
              <FileUpload
                handleChange={handleFileUpload}
                fileTypes={FILE_TYPE}
                customHtml={customHtml}
              />
            )}
            {fileError && (
              <div className='mt-1 mb-1'>
                <ValidationErrorText>{fileError}</ValidationErrorText>
              </div>
            )}
          </div>

          <div className='flex justify-end mt-[4px]'>
            <div className='flex gap-[4px]'>
              <button
                className='flex text-white text-base not-italic font-medium leading-6 w-40 bg-[#77553d] justify-center items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg'
                onClick={handleUpdateClaim}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadDocument;
