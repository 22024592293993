export const storeAllAddresses = (state, action) => {
  state.addresses = action?.payload?.locationAddresses;
};

export const setAddress = (state, action) => {
  state.selectedAddress = action.payload;
};

// reeset address slice
export const resetAddressSlice = (state, action) => {
  state.addresses = [];
  state.selectedAddress = null;
};
