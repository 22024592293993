import React from 'react';
import Claims from '../../features/claims/claims';

const ClaimsPage = () => {
  return (
    <div>
      {/* {m ? <DeleteModal /> : null} */}

      <Claims />
    </div>
  );
};

export default ClaimsPage;
