import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../../constants/paths';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../../constants/toastNotifications';
import { persistor, resetAddressSliceAction } from '../../../../store';
import { useDispatch } from 'react-redux';

export const useDesktopNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('token');

    toast.dismiss();
    toast.success('Logout successful', { duration: TOAST_TIME });

    navigate(PATHS.DEFAULT_LOGIN);
    dispatch(resetAddressSliceAction());

    setTimeout(() => {
      persistor.purge();
      persistor.purge();
    }, 200);
  };

  return { logout };
};
