import React from 'react';
import { Link } from 'react-router-dom';
import { chevronDown, marker, plusBrown } from '../../../../assets';
import { CustomButton, CustomText } from '../../../../common/custom';
import CustomContainer from '../../../../common/custom/CustomContainer';
import { CustomIcon } from '../../../../common/customAssets';
import { PATHS } from '../../../../constants/paths';
import { useLocationHook } from './useLocationHook';

const Location = () => {
  const {
    locations,
    selectedLocation,
    setLocation,
    toggleLocationDropDown,
    handleDropdown,
    dropDownRef,
  } = useLocationHook();
  return (
    <CustomContainer className=' w-full max-w-[266px] min-h-[36px] relative  '>
      <div
        ref={dropDownRef}
        onClick={handleDropdown}
        className='cursor-pointer flex bg-foundational-white rounded-[8px] p-[8px] justify-between items-center gap-[8px]  min-h-[36px] '
      >
        <CustomIcon icon={marker} />
        <CustomContainer className='max-w-[210px] w-full '>
          <CustomText className='text-normal'>
            {selectedLocation && selectedLocation.nickName}
          </CustomText>
        </CustomContainer>
        <CustomIcon
          className={`${toggleLocationDropDown ? 'rotate-180' : ''}`}
          icon={chevronDown}
        />
      </div>
      {toggleLocationDropDown ? (
        <div className='absolute bg-foundational-white  w-full mt-1 px-[10px] shadow-xl rounded-[6px]  py-[12px]'>
          <div className='  max-h-[100px] overflow-y-scroll scrollbar-thin  '>
            {locations?.map((location) => {
              return (
                <div
                  onClick={() => {
                    setLocation(location);
                  }}
                  className='cursor-pointer text-[16px] font-inter font-[500] my-3   '
                >
                  {location.nickName}
                </div>
              );
            })}
          </div>

          <Link to={PATHS.ADD_SHIP_FROM_ADDRESS}>
            <CustomButton
              icon={plusBrown}
              text='Add Ship From Address'
              className='flex items-center  text-foundation-brown mt-[12px] items-center gap-[2px] w-full  '
            />
          </Link>
        </div>
      ) : null}

      {/* chevdown icon here */}
    </CustomContainer>
  );
};

export default Location;
