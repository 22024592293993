export const storeAllClaim = (state, action) => {
  state.allClaim = action.payload.allClaimFromLocation;
};

export const addClaim = (state, action) => {
  state.allClaim.push(action.payload.createClaim);
};

export const cancelClaim = (state, action) => {
  state.allClaim = state.allClaim.filter(
    (claim) => claim.id !== action.payload.id
  );
};
