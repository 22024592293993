import { RESET_ALL_PERSISTED_STATE } from '../../../constants/actions_type';

export const storeAllShipments = (state, action) => {
  state.allShipments = action.payload.allShipmentFromLocation;
};

// export const addShipment = (state, action) => {
//   state.allShipments.push(action.payload.createShipment);
// };

export const cancelShipment = (state, action) => {
  state.allShipments = state.allShipments.filter(
    (shipment) => shipment.id !== action.payload.id
  );
};

export const resetAllStoredSHipments = (state, action) => {
  if ((action.type = RESET_ALL_PERSISTED_STATE)) {
    state.addShipment = [];
  }
};
