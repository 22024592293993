import { useEffect, useRef, useState } from 'react';

export const useReportShipmentStepper = (currentStep, steps) => {
  const [newSteps, setNewSteps] = useState([]);
  const stepRef = useRef();

  const stepDesign = {
    inProgress: 'bg-foundation-brown border-[#C7B9AF]',
    completed: 'bg-foundation-brown border-4 border-[#C7B9AF]',
    notReached: 'bg-[#F2F4F7]',
  };

  const updateStep = (stepNumber, steps) => {
    const ourNewSteps = [...steps];
    let count = 0;
    while (count < ourNewSteps.length) {
      // current step
      if (count === stepNumber) {
        ourNewSteps[count] = {
          ...ourNewSteps[count],
          highlighted: true,
          selected: true,
          completed: false,
        };
        count++;
      } else if (count < stepNumber) {
        ourNewSteps[count] = {
          ...ourNewSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      } else {
        ourNewSteps[count] = {
          ...ourNewSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }
    return ourNewSteps;
  };
  useEffect(() => {
    const stepState = steps.map((step, index) => {
      return Object.assign(
        {},
        {
          description: step,
          completed: false,
          highlighted: index === 0 ? true : false,
          selected: index === 0 ? true : false,
        }
      );
    });
    stepRef.current = stepState;
    const current = updateStep(currentStep - 1, stepRef.current);
    setNewSteps(current);
  }, [currentStep, steps]);

  return { newSteps, stepDesign };
};
