import { gql } from "@apollo/client";
import client from "../apollo.";

export const createSetupIntentMutation = async (customerID) => {
  // customerID its should be a string
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation CreateSetupIntent($customerId: String!) {
        createSetupIntent(customerID: $customerId) {
          id
          customer
          client_secret
          status
          usage
          created
        }
      }
    `,
    variables: {
      customerId: customerID,
    },
  });
  return { data, errors };
};

export const getMyLocationsAddressesQuery = async (locationId) => {
  const { data } = await client.query({
    query: gql`
      query LocationAddresses($locationId: Float!) {
        locationAddresses(locationId: $locationId) {
          createdAt
          nickName
          locationId
          id
          address
        }
      }
    `,
    variables: {
      locationId: locationId,
    },
  });
  return data;
};
