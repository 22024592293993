import * as echarts from 'echarts';
import { useEffect, useState } from 'react';
import { getDashboardStatisticsQuery } from '../../api/services/dashboard.service';
import moment from 'moment';
import useApi from '../../hook/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetReportShipmentFormAction } from '../../store';
import { PATHS } from '../../constants/paths';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../constants/toastNotifications';

const monthlyFilter = [
  { label: 'All', value: 'all' },
  { label: 'Jan', value: '01' },
  { label: 'Feb', value: '02' },
  { label: 'Mar', value: '03' },
  { label: 'Apr', value: '04' },
  { label: 'May', value: '05' },
  { label: 'Jun', value: '06' },
  { label: 'Jul', value: '07' },
  { label: 'Aug', value: '08' },
  { label: 'Sep', value: '09' },
  { label: 'Oct', value: '10' },
  { label: 'Nov', value: '11' },
  { label: 'Dec', value: '12' },
];

export const useDashboard = () => {
  const { payments } = useSelector((state) => state);
  const user = JSON.parse(localStorage.getItem('login-user'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reportShipmentHandler = () => {
    dispatch(resetReportShipmentFormAction());

    // if (
    //   user?.entity?.isManualPayment === true ||
    //   (Array.isArray(payments?.payments) && payments?.payments.length > 0)
    // ) {
    navigate(PATHS.TEST_REPORT_SHIPMENT);
    // } else {
    //   toast.dismiss();
    //   toast.error('Please add a payment method', { duration: TOAST_TIME });
    // }
  };

  function generateYearsArray() {
    const startYear = 2023;
    const endYear = 2030;
    const yearsArray = [{ label: 'All', value: 'all' }];
    for (let i = startYear; i <= endYear; i++) {
      yearsArray.push({ label: i.toString(), value: i.toString() });
    }
    return yearsArray;
  }

  const yearsFilterArray = generateYearsArray();

  const [overviewTab, setOverviewTab] = useState('shipment');
  const [selectedMonth, setSelectedMonth] = useState(`all`);
  const [selectedYear, setSelectedYear] = useState(`all`);
  const initialStartDate = `${yearsFilterArray[1].value}-01-01`;
  const initialEndDate = `${yearsFilterArray[yearsFilterArray.length - 1].value
    }-12-31`;
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const [getdata, loading, error, data] = useApi(getDashboardStatisticsQuery);

  useEffect(() => {
    getdata({
      endDate: endDate,
      startDate: startDate,
    });
  }, [endDate, startDate]);

  const updateDateRange = (month, year) => {
    if (month === 'all' && year === 'all') {
      setStartDate(`${yearsFilterArray[1].value}-01-01`);
      setEndDate(
        `${yearsFilterArray[yearsFilterArray.length - 1].value}-12-31`
      );
    } else if (month === 'all') {
      setStartDate(`${year}-01-01`);
      setEndDate(`${year}-12-31`);
    } else if (year === 'all') {
      setStartDate(`${yearsFilterArray[1].value}-${month}-01`);
      setEndDate(
        `${yearsFilterArray[yearsFilterArray.length - 1].value
        }-${month}-${moment(
          `${month}-${yearsFilterArray[yearsFilterArray.length - 1].value}`,
          'MM-YYYY'
        ).daysInMonth()}`
      );
    } else {
      setStartDate(`${year}-${month}-01`);
      setEndDate(
        `${year}-${month}-${moment(
          `${month}-${year}`,
          'MM-YYYY'
        ).daysInMonth()}`
      );
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    updateDateRange(selectedMonth, event.target.value);
  };
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    updateDateRange(event.target.value, selectedYear);
  };
  const handleOverviewTab = (value) => {
    setOverviewTab(value);
  };

  function getMonthlyCounts(data) {
    const monthlyCounts = Array(12).fill(0);
    data?.forEach((entry) => {
      const month = new Date(entry?.month).getMonth();
      monthlyCounts[month] += entry?.count ? entry?.count : entry?.totalPremium;
    });

    return monthlyCounts;
  }

  const overviewChartTabs = {
    payment: getMonthlyCounts([]),
    manualClaims: getMonthlyCounts(
      data?.dashboardStatistics?.monthlyManualClaimsCount
    ),
    shipmentClaims: getMonthlyCounts(
      data?.dashboardStatistics?.monthlyShipmentClaimsCount
    ),
    billing: getMonthlyCounts(data?.dashboardStatistics?.monthlyBilling),
    shipment: getMonthlyCounts(
      data?.dashboardStatistics?.monthlyShipmentsCount
    ),
  };

  const overviewChartOptions = {
    height: '70%',
    width: '95%',
    tooltip: {
      trigger: 'axis',
      show: true,
      axisPointer: {
        show: false,
        type: 'cross',
        label: {
          backgroundColor: '#DAEFFF',
          color: '#000000',
          fontWeight: '550',
        },
      },
    },
    legend: {
      show: false,
      data: [`${overviewTab}`],
    },
    toolbox: {
      show: false,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      top: '7%',
      containLabel: true,
    },
    xAxis: [
      {
        show: true,
        type: 'category',
        boundaryGap: false,
        data: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        axisLabel: {
          show: false,
        },
        splitNumber: 4,
      },
    ],
    series: [
      {
        name: overviewTab,
        type: 'line',
        stack: 'Total',
        data: overviewChartTabs[overviewTab],
        color: ['#8AD3A9'],
        gradientColor: ['#EFF9F3'],
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#8AD3A9',
            },
            {
              offset: 1,
              color: '#EFF9F3',
            },
          ]),
        },
        step: false,
        smooth: true,
        showSymbol: false,
      },
    ],
  };

  const ShipmentChartOptions = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
      borderColor: 'transparent',
      formatter: function (params) {
        const { seriesName, value, color, name: month } = params;
        return `
          <div>
            <div><strong>${month}</strong></div>
            <div>
              <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>
              ${seriesName}: ${value}
            </div>
          </div>`;
      },
    },
    legend: {
      show: false,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        axisLabel: {
          show: false,
        },
        splitNumber: 3,
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Entities',
        type: 'bar',
        stack: 'entity',
        emphasis: {
          focus: 'series',
        },
        barWidth: '40%',
        data: getMonthlyCounts(data?.dashboardStatistics?.monthlyEntitiesCount),
        color: ['#F7BA1E'],
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
        },
      },
    ],
  };

  return {
    data: data?.dashboardStatistics,
    overviewTab,
    overviewChartOptions,
    ShipmentChartOptions,
    handleOverviewTab,
    selectedMonth,
    reportShipmentHandler,
    monthlyFilter,
    selectedYear,
    yearsFilterArray,
    handleMonthChange,
    handleYearChange,
    loading,
  };
};
