import { Tooltip } from '@material-tailwind/react';
import React from 'react';
import {
  attachement,
  claimFile,
  conclude,
  deleteIcon,
  editIcon,
  viewIcon,
} from '../../../../assets';
import { CustomText } from '../../../../common/custom';
import { CustomIcon } from '../../../../common/customAssets';
import { PATHS } from '../../../../constants/paths';
import useNavigateHook from '../../../../hook/use-navigate.hook';
import useShipmentsActionMenu from './useShipmentsActionMenu.hook';

const ShipmentsActionMenu = ({
  openDeleteModal,
  handleViewAttachments,
  data,
  upper,
}) => {
  const { handleClaimFIle } = useShipmentsActionMenu();
  const { navigate } = useNavigateHook();
  return (
    <>
      <div
        className={`w-[190px] z-10 shadow-md bg-foundational-white  absolute ${
          upper ? 'top-[-95px]' : 'top-[40px]'
        }  right-[50px] rounded-[8px] flex flex-col items-center justify-center  gap-[2px] py-[4px] border-[1px] border-solid border-[#EAECF0] `}
      >
        <div
          className='cursor-pointer flex items-center w-full min-h-[40px] px-[8px] bg-hover rounded-[6px]'
          onClick={() => navigate(`${PATHS.EDIT_SHIPMENT}/${data.id}`)}
        >
          <Tooltip className='mt-[-10px] z-50' content='Edit'>
            <div>
              {' '}
              <CustomIcon icon={editIcon} />
            </div>
          </Tooltip>

          <CustomText className='font-inter font-[500] ml-[8px] '>
            Edit
          </CustomText>
        </div>

        {!data.isDelivered && (
          <div
            className='cursor-pointer flex items-center w-full min-h-[40px] px-[8px] bg-hover rounded-[6px]'
            onClick={() => navigate(`${PATHS.CONCLUDE_TRANSIT}/${data.id}`)}
          >
            <Tooltip className='mt-[-10px] z-50' content='Conclude'>
              <div>
                <CustomIcon icon={conclude} />
              </div>
            </Tooltip>
            <CustomText className='font-inter font-[500] ml-[8px]'>
              Conclude
            </CustomText>
          </div>
        )}

        <div
          className='cursor-pointer flex items-center w-full min-h-[40px] px-[8px] bg-hover rounded-[6px]'
          onClick={openDeleteModal}
        >
          <Tooltip className='mt-[-10px] z-50' content='Delete'>
            <div>
              {' '}
              <CustomIcon icon={deleteIcon} />
            </div>
          </Tooltip>
          <CustomText className='font-inter font-[500] ml-[8px]'>
            Delete
          </CustomText>
        </div>

        {(data?.documents ?? '[]').length === 0 &&
        (data?.deliveryDocuments ?? '[]').length === 0 ? (
          ''
        ) : (
          <div
            className='cursor-pointer flex items-center w-full min-h-[40px] px-[8px] bg-hover rounded-[6px]'
            onClick={() => handleViewAttachments(data)}
          >
            <Tooltip className='mt-[-10px] z-50' content='Attachements'>
              <div>
                <CustomIcon icon={attachement} />
              </div>
            </Tooltip>
            <CustomText className='font-inter font-[500] ml-[8px]'>
              View Attachments
            </CustomText>
          </div>
        )}

        {data.isDelivered && !data.claim ? (
          <div
            className='cursor-pointer flex items-center  w-full min-h-[40px] px-[8px] bg-hover rounded-[6px]'
            onClick={handleClaimFIle}
          >
            <Tooltip className='mt-[-10px]' content='Attachements'>
              <CustomIcon icon={claimFile} />
            </Tooltip>
            <CustomText className='font-inter font-[500] ml-[8px]'>
              File Claim
            </CustomText>
          </div>
        ) : null}
        <div
          className='cursor-pointer flex items-center w-full min-h-[40px] px-[8px] bg-hover rounded-[6px]'
          onClick={() => navigate(`${PATHS.VIEW_SHIPMENT_DETAILS}/${data.id}`)}
        >
          <Tooltip className='mt-[-10px] z-50' content='detail'>
            <>
              {' '}
              <CustomIcon icon={viewIcon} />
            </>
          </Tooltip>

          <CustomText className='font-inter font-[500] ml-[8px] '>
            View Details
          </CustomText>
        </div>
      </div>
    </>
  );
};

export default ShipmentsActionMenu;
