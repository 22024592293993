import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { HANDLE_FILE_SIZE_CHECK } from "../../../../helpers/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../../../constants/paths";
import useApi from "../../../../hook/useApi";
import { deliverShipmentMutation } from "../../../../api/services/shipments.service";

function useConcludeTransit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [transitFile, setTransitFile] = useState("");

  const [concludeTransit, concludeTransitLoading, error, data] = useApi(
    deliverShipmentMutation
  );

  const handleUploadTransit = (file) => {
    const document = HANDLE_FILE_SIZE_CHECK(20, file);
    setIsLoading(true);
    toast.loading("loading");
    if (document) {
      setTransitFile(file);
      const formData = new FormData();
      formData.append("upload", file);

      axios
        .post("https://api.ceasta.com/fileUpload", formData)
        .then((response) => {
          setTransitFile([...response.data][0]);
          toast.dismiss();
          setIsLoading(false);
        })
        .catch((error) => {
          toast.dismiss();
          setIsLoading(false);
        });
    }
  };

  const handleUploadTransitCancel = () => {
    setTransitFile("");
  };

  const handleCancel = () => {
    navigate(PATHS.SHIPMENTS);
  };

  const handleConclude = async (e) => {
    toast.dismiss();
    setFileError(true);
    e.preventDefault();
    if (transitFile) {
      toast.loading("Concluding...");
      const { data } = await concludeTransit({
        shipmentId: Number(id),
        documents: JSON.stringify(transitFile),
      });
      if (data) {
        toast.dismiss();
        navigate(PATHS.SHIPMENTS);
        toast.success("Successful");
      } else {
        toast.dismiss();
        toast.error("Something went wrong");
      }
    }
  };

  return {
    fileError,
    transitFile,
    isLoading,
    handleCancel,
    handleConclude,
    handleUploadTransit,
    handleUploadTransitCancel,
    concludeTransitLoading,
  };
}

export default useConcludeTransit;
