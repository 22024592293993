import { gql } from '@apollo/client';
import client from '../apollo.';

export const findAllSalesMemberQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query findAllSalesMember {
        findAllSalesMember {
          id
          firstName
          lastName
        }
      }
    `,
  });
  return { data, errors };
};

export const forgetUserIdMutation = async (forgetUserIdData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation ForgetClientUserId($email: String!, $password: String!) {
        forgetClientUserId(input: { email: $email, password: $password }) {
          message
          statusCode
          success
        }
      }
    `,
    variables: {
      email: forgetUserIdData.email,
      password: forgetUserIdData.password,
    },
  });
  return { data, errors };
};
