import React from "react";
import { ceastaLogo } from "../../../assets";
import CustomHeading from "../../../common/custom/CustomHeading";
import { CustomIcon } from "../../../common/customAssets";
import { Link } from "react-router-dom";
import { PATHS } from "../../../constants/paths";

const SignUpHeader = ({ heading = "Create an account" }) => {
  return (
    <div className="">
      <div className="mb-4 flex justify-center">
        <Link to={PATHS.DEFAULT_HOME}>
          <CustomIcon icon={ceastaLogo} className="w-[226px] h-[43px]" />
        </Link>
      </div>

      <CustomHeading as="h1">{heading}</CustomHeading>
    </div>
  );
};

export default SignUpHeader;
