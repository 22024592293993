import { useEffect, useState } from 'react';
import { createSetupIntentMutation } from '../../../api';
import useApi from '../../../hook/useApi';

export const useSavecard = () => {
  const [createSetupIntent] = useApi(createSetupIntentMutation);

  const [error, setError] = useState(false);

  const [paymentDescriptionModal, setPaymentDescriptionModal] = useState(true);
  const [setupIntent, setSetupIntent] = useState(null);

  //
  const user = JSON.parse(localStorage.getItem('login-user'));

  const fetchSetupIntent = async () => {
    var customerID = user.location.stripeCustomerID;
    const { data, errors } = await createSetupIntent(customerID);

    if (data != null) {
      setSetupIntent(data.createSetupIntent);
    }
  };

  useEffect(() => {
    fetchSetupIntent();
  }, []);

  //

  const closePaymentDescriptionModal = () => {
    setPaymentDescriptionModal(false);
  };
  const openPaymentDescriptionModal = () => {
    setPaymentDescriptionModal(true);
  };

  return {
    paymentDescriptionModal,
    closePaymentDescriptionModal,
    openPaymentDescriptionModal,
    setupIntent,
    error,
    setError,
  };
};
