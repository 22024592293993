import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useProfile = () => {
  const user = JSON.parse(localStorage.getItem('login-user'));

  const navigate = useNavigate();
  const [openSignModal, setOpenSignModal] = useState(false);

  const handleSignModal = () => setOpenSignModal(!openSignModal);

  return {
    user,
    navigate,
    openSignModal,
    handleSignModal,
  };
};
