import React from 'react';
import { ClientsPanelHeader } from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import CustomTable from '../../../common/custom/CustomTable/custom-table.component';
import { PAYMENT_METHODS } from '../../../constants/columns';
import { PATHS } from '../../../constants/paths';
import { PaymentMethodListHeader } from '../components';
import { usePaymentMethodList } from './usePaymentMethodList';
import PaymentActionMenu from './paymentLocationActionMenu';
import { DeleteModal } from '../../modals';

const PaymentMethodList = () => {
  const {
    paymentMethods,
    handleActionClick,
    paymentActionClickedData,
    toggleActionMenu,
    toggleCardActionMenu,
    openDeleteModal,
    setOpenDeleteModal,
    actionMenuRef,
    handleDeleteCard,
  } = usePaymentMethodList();

  return (
    <>
      {openDeleteModal && (
        <DeleteModal
          modalHeading='Delete Payment Method?'
          modalPara='Are you sure you want to delete this payment method? This action cannot be undone.'
          deleteHandler={() => handleDeleteCard()}
          closeDeleteModal={() => setOpenDeleteModal(false)}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          modalHeading='Delete Payment Method?'
          modalPara='Are you sure you want to delete this payment method? This action cannot be undone.'
          deleteHandler={() => handleDeleteCard()}
          closeDeleteModal={() => setOpenDeleteModal(false)}
          page='payment'
        />
      )}
      <CustomContainer>
        <ClientsPanelHeader hideReportButton/>
        <div className='border-[1px] border-[#EAECF0] border-[color:var(--Gray-200,#EAECF0)] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)] rounded-xl border-solid'>
          <PaymentMethodListHeader
            heading='Payment Methods'
            butonText='Add Payment Method'
            link={PATHS.PAYMENT_ADD_PAYMENT_METHOD_CARD}
          />

          <CustomTable
            header={PAYMENT_METHODS}
            rows={paymentMethods}
            handleActionClick={handleActionClick}
            actionMenuRef={actionMenuRef}
            toggleActionMenu={toggleActionMenu}
            menu={
              <PaymentActionMenu
                handleClickDelete={() => setOpenDeleteModal(true)}
                data={paymentActionClickedData}
              />
            }
          />
        </div>
      </CustomContainer>
    </>
  );
};
export default PaymentMethodList;
