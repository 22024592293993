import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getMyLocationsAddressesQuery } from '../../../../api';
import { setAddressAction, storeAllAddressesAction } from '../../../../store';
import useApi from '../../../../hook/useApi';
import toast from 'react-hot-toast';
import useOutsideClick from '../../../../hook/useOutsideClick';
import { TOAST_TIME } from '../../../../constants/toastNotifications';
export const useLocationHook = () => {
  const { addresses } = useSelector((state) => state);

  const dropDownRef = useRef(null);

  const dispatch = useDispatch();
  const [getMyLocationsAddressesCall] = useApi(
    getMyLocationsAddressesQuery,
    storeAllAddressesAction
  );
  const [toggleLocationDropDown, setToggleLocationDropDown] = useState(false);

  useOutsideClick(dropDownRef, () => setToggleLocationDropDown(false));
  const handleDropdown = () => {
    if (toggleLocationDropDown) setToggleLocationDropDown(false);
    else setToggleLocationDropDown(true);
  };
  useEffect(() => {
    if (localStorage.getItem('login-user')) {
      const user = JSON.parse(localStorage.getItem('login-user'));
      user && getMyLocationsAddressesCall(user.locationId);
    }
  }, []);

  if (!addresses.selectedAddress) {
    dispatch(setAddressAction(addresses.addresses[0]));
  }
  const setLocation = (location) => {
    dispatch(setAddressAction(location));
    toast.dismiss();
    toast.success(`${location.nickName} selected successfully`, {
      duration: TOAST_TIME,
    });
  };
  return {
    locations: addresses.addresses,
    selectedLocation: addresses.selectedAddress,
    setLocation,
    toggleLocationDropDown,
    handleDropdown,
    dropDownRef,
  };
};
