import React from 'react';
import { ceastaLogo, formPageImg } from '../../../assets'; // import formPageImg from from assets to use as default image

import { CustomIcon } from '../../../common/customAssets';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../constants/paths';

const FormImageLayout = ({
  // bgImg prop for passing background Image in this component
  bgImg = formPageImg, // iif no image is passed than this will be used as default Note(this image is the one which is being used in all components
  // of all auth flow components so it will automatically be used as figma design image)

  // children prop for rendering auth flow forms in this layout
  children,
}) => {
  const rightSectionStyle = {
    // backgroundImage: 'url("../../../assets/img/form-page-img.png")',

    backgroundImage: `url(${bgImg})`,
    width: '720px',
  };
  return (
    <div className='flex min-h-[100vh] '>
      <div className='sm:flex-1 w-full p-1 flex items-center justify-center '>
        {/* different auth compoenet wrapper starts */}
        <div className='w-[359px] min-h-[300px]   rounded-md'>
          <div className='flex justify-center items-center mb-[28px]'>
            {/* <Link to={PATHS.DEFAULT_HOME}> */}
            <CustomIcon
              icon={ceastaLogo}
              className='w-[226px] h-[43px]  '
            />
            {/* </Link> */}
          </div>

          {children}
        </div>
        {/* different auth compoenet wrapper ends */}
      </div>
      <div
        className='hidden lg:block flex-1 bg-cover bg-center'
        style={rightSectionStyle}
      ></div>
    </div>
  );
};

// Example

//example if you pass image that will render otherwise default wiill render
// <FormImageLayout bgImg={bgImg}>
//  <Form/>
// </FormImageLayout>

export default FormImageLayout;
