import React from 'react';
import { ReportsShipments } from '../../features/shipments';

const ReportShipmentsPage = () => {
  return (
    <>
      <ReportsShipments />
    </>
  );
};

export default ReportShipmentsPage;
