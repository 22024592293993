import * as yup from 'yup';

const addStaffMemberSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  phoneNumber: yup.string().required('Phone number is required'),
  // .matches(
  //   /^\+?\d+$/,
  //   'Invalid Phone Number. Only digits and optional + are allowed'
  // ),
  emailAddress: yup
    .string()
    .email('Invalid Email Address')
    .required('Email Address is required'),
});

export default addStaffMemberSchema;
