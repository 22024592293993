import { createSlice } from '@reduxjs/toolkit';
import {
  // addShipment,
  cancelShipment,
  storeAllShipments,
} from './shipmentsAction';

const initialState = {
  allShipments: [],
};

const formsSlice = createSlice({
  name: 'allShipments',
  initialState,
  reducers: {
    storeAllShipmentsAction: storeAllShipments,
    // addShipmentAction: addShipment,
    cancelShipmentAction: cancelShipment,
    resetAllSHipmentsAction: (state, action) => {},
  },
});

export const shipmentsReducer = formsSlice.reducer;
export const {
  storeAllShipmentsAction,
  addShipmentAction,
  cancelShipmentAction,
} = formsSlice.actions;
