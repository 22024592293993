import { ceasataStamp } from '../../assets';
export default function RebateProgramme() {
  return (
    <div className='mx-auto max-w-[1200px]  pt-[50px] px-8'>
      <h1 className='text-[32px] font-bold'>Rebate Program</h1>
      <p className='mt-4 text-[16px]'>
        As a valued partner of Ceasta Assurance, we are pleased to offer you a
        rebate program as a token of our appreciation for your trust and
        continued business. With this program, you can earn back 20% of the
        total sum paid premiums for the eligible month.
      </p>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Eligibility:</h2>
        <ul className='mt-4 list-disc pl-6'>
          <li>
            All merchants and delivery companies engaged in transportation
            services are eligible to participate.
          </li>
        </ul>

        
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Eligibility Period:
        </h2>
        <ul className='mt-4 list-disc pl-6'>
          <li>
            The eligibility period is monthly, starting on the 1st and ending on
            the last day of each month.
          </li>
        </ul>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Rebate Calculation:
        </h2>
        <ul className='mt-4 list-disc pl-6'>
          <li>
            The rebate amount is calculated by applying a 20% rate to the total
            sum of paid premiums for the eligible month.
          </li>
        </ul>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Rebate Disbursement:
        </h2>
        <ul className='mt-4 list-disc pl-6'>
          <li>
            Rebates are disbursed as a check on the 15th of the following month.
          </li>
        </ul>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Program Renewal:
        </h2>
        <ul className='mt-4 list-disc pl-6'>
          <li>
            The program may be renewed or modified based on evaluation results
            and stakeholder feedback.
          </li>
          <li>
            Changes to the program will be communicated to participating
            companies.
          </li>
        </ul>
      </div>
      <div className='flex justify-end w-[80%]'>
        <img
          src={`${ceasataStamp}`}
          alt='Stamp'
          width={150}
          className='transform -rotate-45 opacity-40'
        />
      </div>
    </div>
  );
}
