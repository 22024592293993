import React from 'react';
import { ceasataStamp } from '../../assets';

const FurnitureTermsAndCondition = () => {
  return (
    <div className='mx-auto max-w-[1200px]  pt-[50px] px-8'>
      <h1 className='text-[32px] font-bold'>Terms and Conditions: Furniture</h1>

      <div class='mt-8'>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>1. Insurable:</h2>
          <p>
            Ceasta Assurance Inc. (CAI) provides transit insurance coverage forn
            new Furniture and Fine Art.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>2. Insure Limit:</h2>
          <p>
            Maximum coverage up to $75,000. If exceeding the maximum limit of
            $75,000, please call for approval.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>3. Coverage:</h2>
          <p>
            This insurance policy covers the shipping of the insured furniture
            from the point of pick-up confirmation timestamped to the
            destination against damage, theft, or loss during transit.{' '}
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            4. Packaging of Furniture:
          </h2>
          <p>
            The furniture must be securely packed or encased. After it’s been
            packed or encased, provide a clear image of the package with an
            attached shipping label containing the name and address of the
            destination to CAI.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            5. Insured Value:
          </h2>
          <p>
            The insured value of the furniture will be determined by the
            declared value.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>6. Premium:</h2>
          <p>
            The premium for this insurance policy will be calculated as a
            percentage of the insured value of the shipment and will be charged
            to the merchant upon the conclusion of the transit phase. If the
            shipment is to be returned, the paid premium is non-refundable. Our
            policy does not cover the return of furniture
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            7. Pick-Up Confirmation:
          </h2>
          <p>
            Wait for confirmation from CAI before allowing the pickup of your
            furniture. Without this confirmation, no coverage will be provided.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            8. Deadline to File a Claim:
          </h2>
          <p>
            The insured must submit a claim to CAI within 72 hours after the
            scheduled arrival date, upon determination of damage, theft, or
            loss. Submit all necessary information, documentation, and evidence
            to support the claim. If the furniture has been damaged, CAI will
            contact the shipping company to provide a damage report and
            necessary documentation, in order to process the claim.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            9. Required Document(s) to File a Claim:
          </h2>
          <p>
            Provide documentation that verifies the purchase of the furniture
            from the showroom or furniture store, such as a purchase receipt.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            10. Covered Furniture:
          </h2>
          <p>
            CAI will only honor coverage for shipments that are picked up and
            with the confirmation timestamped, up to the declared value.
            Furniture without Proof of Purchase, the appraised market value will
            apply.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            11. Damaged Furniture:
          </h2>
          <p>
            If the furniture has been damaged while in transit, our Contracted
            Professional Repairer (*Have IT set a click to take to Vulta’s
            company) will provide the scope of repair work.{' '}
          </p>
          <ol>
            <li className='mt-2'>
              <h3>11.1. If Repairable:</h3>
              <ul>
                <li>
                  CAI will make the arrangements to transport furniture to the
                  repair shop.
                </li>
                <li>
                  Detailed images will be sent to the client after repair has
                  been completed. CAI will make arrangements to transport the
                  furniture back to the customer.
                </li>
                <li>All expenses will be paid by CAI.</li>
              </ul>
            </li>
            <li className='mt-2'>
              <h3>
                11.2. If NOT Repairable (theft or loss): CAI will pay the claim
                based on declared value. Proof of Purchase is required. Refer to
                Terms & Conditions number 9.
              </h3>
            </li>
          </ol>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            12. Lost/Stolen Furniture:
          </h2>
          <p>
            If the furniture is lost or stolen during transit, CAI will conduct
            tracing with the transportation company. After 10 working days, if
            the furniture cannot be found, CAI will start the claim process.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            13. Notification of Damage, Theft, or Loss:
          </h2>
          <p>
            The insured must notify CAI of any damage, theft, or loss within 72
            hours, after the scheduled delivery date.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>14. Inspection:</h2>
          <p>
            The insurance company reserves the right to inspect the furniture
            and the packaging at the point of origin or the final destination to
            verify the damage.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>15. Exclusions:</h2>
          <p>
            This Policy does not cover damage caused by war, riots, strikes,
            confiscation by authorities, and any other perils excluded by the
            insurance company.
          </p>
          <p>
            15.2: Policy does not cover household goods including but not
            limited to household moves.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>16. Subrogation:</h2>
          <p>
            The insurance company reserves the right to recover the paid amount
            from any responsible party or carrier.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            17. Jurisdiction:
          </h2>
          <p>
            This insurance policy is governed by the laws of the jurisdiction
            where the shipment originated, and any dispute arising from this
            insurance policy shall be subject to the exclusive jurisdiction of
            the courts of that jurisdiction.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>18. Termination:</h2>
          <p>
            The insurance policy may be terminated by the policyholder or the
            insurance company for various reasons, including non-payment of
            premiums or fraudulent claims.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            19. Indemnification:
          </h2>
          <p>
            The insurance company shall indemnify and hold the policyholder
            harmless from any claims, damages, or liabilities arising from the
            loss or damage of the item during transit or shipping.
          </p>
        </div>
      </div>

      <div className='flex justify-end w-[80%]'>
        <img
          src={`${ceasataStamp}`}
          alt='Stamp'
          width={150}
          className='transform -rotate-45 opacity-40'
        />
      </div>
    </div>
  );
};
export default FurnitureTermsAndCondition;
