import React from 'react';
import { ForgetPassword } from '../../features/auth';

const ForgetPasswordPage = () => {
  return (
    <div>
      <ForgetPassword />
    </div>
  );
};

export default ForgetPasswordPage;
