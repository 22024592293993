import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
  cancelShipmentMutation,
  getAllShipmentsFromLocationQuery,
} from '../../../../api';
import { TOAST_TIME } from '../../../../constants/toastNotifications';
import useApi from '../../../../hook/useApi';
import useOutsideClick from '../../../../hook/useOutsideClick';
import {
  cancelShipmentAction,
  storeAllShipmentsAction,
} from '../../../../store';
import { PARSIFY } from '../../../../helpers/helpers';

export const useAllShipmentsList = () => {
  const actionMenuRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [shipmentActionClickedId, setShipmentActionClickedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);
  const [shipmentActionClickedData, setShipmentActionClickedData] =
    useState(null);

  // Click outside hook for closing action menu
  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));

  const user = JSON.parse(localStorage.getItem('login-user'));

  const [cancelShipmentCall, cancelShipmentLoading] = useApi(
    cancelShipmentMutation,
    cancelShipmentAction
  );

  const [getAllShipmentsFromLocation] = useApi(
    getAllShipmentsFromLocationQuery,
    storeAllShipmentsAction
  );

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const cancelShipmentHandler = async (shipmentId) => {
    toast.dismiss();
    toast.loading('Deleting shipment...');
    const { data, errors } = await cancelShipmentCall(shipmentId);
    if (data) {
      const user = JSON.parse(localStorage.getItem('login-user'));
      getAllShipmentsFromLocation(user.locationId);
      toast.dismiss();
      toast.success('Shipment deleted successfully', { duration: TOAST_TIME });
    }
    if (errors) {
      toast.dismiss();
      toast.error('error in canceling shipment', { duration: TOAST_TIME });
    }

    return { data, errors };
  };

  const handleActionClick = (e, data) => {
    e.stopPropagation();
    setShipmentActionClickedId(parseInt(data?.id));
    setShipmentActionClickedData(data);

    toggleActionMenu ? setToggleActionMenu(null) : setToggleActionMenu(data.id);
    data && localStorage.setItem('shipment', JSON.stringify(data));
  };

  const handleViewAttachments = (data) => {
    setOpen(true);
    setAttachments(
      [
        // ...attachments,
        data?.documents ? JSON.parse(data.documents): [],
        data?.deliveryDocuments ? JSON.parse(data.deliveryDocuments) : [],
      ].flat()
    );
  };

  const handleClose = () => {
    setOpen(!open);
  };
  return {
    open,
    user,
    options,
    showDeleteModal,
    cancelShipmentHandler,
    closeDeleteModal,
    shipmentActionClickedId,
    actionMenuRef,
    toggleActionMenu,
    shipmentActionClickedData,
    openDeleteModal,
    handleActionClick,
    handleViewAttachments,
    handleClose,
    attachments,
    cancelShipmentLoading,
  };
};
