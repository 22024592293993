import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMyLocationsAddressesQuery,
  removeAddressMutation,
} from '../../../api';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import useApi from '../../../hook/useApi';
import useOutsideClick from '../../../hook/useOutsideClick';
import { setAddressAction, storeAllAddressesAction } from '../../../store';
import { FORMATE_ADDRESS } from '../../../helpers/helpers';

export const useShipFromList = () => {
  const actionMenuRef = useRef(null);
  const dispatch = useDispatch();
  const [toggleActionMenu, setToggleActionMenu] = useState(false);
  const [shipFromActionClickedId, setShipFromActionClickedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [shipmFromActionClickedData, setShipFromActionClickedData] =
    useState(null);

  const { addresses } = useSelector((state) => state);

  const [removeAddressCall, removeAddressLoading] = useApi(
    removeAddressMutation
  );

  const [getMyLocationsAddressesCall] = useApi(
    getMyLocationsAddressesQuery,
    storeAllAddressesAction
  );

  const user = JSON.parse(localStorage.getItem('login-user'));

  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const handleActionClick = (e, address) => {
    e.stopPropagation();
    setShipFromActionClickedId(parseInt(address.id));
    setShipFromActionClickedData(address);
    if (toggleActionMenu) {
      setToggleActionMenu(null);
    } else {
      setToggleActionMenu(address.id);
    }
  };

  const modifiedData = addresses.addresses?.map((address) => {
    return {
      ...address,
      nickName: address.nickName ? address.nickName : 'NA',
      address: FORMATE_ADDRESS(address.address),
      isSelected: address?.id == addresses?.selectedAddress?.id,
    };
  });

  // delelte address
  const deleteAddressHandler = async (addressId) => {
    toast.dismiss();
    toast.loading('Removing address...');
    const { data, errors } = await removeAddressCall(parseInt(addressId));
    if (data) {
      toast.dismiss();
      toast.success('Address removed', { duration: TOAST_TIME });
      getMyLocationsAddressesCall(user.locationId);
      if (addresses && addresses.length) {
        dispatch(setAddressAction(addresses.addresses[0]));
      }
    }
    if (errors) {
      toast.dismiss();
      toast.error('Failed to remove address', { duration: TOAST_TIME });
    }
    return { data, errors };
  };

  return {
    data: modifiedData,
    actionMenuRef,
    showDeleteModal,
    shipFromActionClickedId,
    closeDeleteModal,
    deleteAddressHandler,
    handleActionClick,
    toggleActionMenu,
    shipmFromActionClickedData,
    openDeleteModal,
    removeAddressLoading,
  };
};
