import React from 'react';
import { SignUpStepper } from '../../features/auth';

const SignUpStepperPage = () => {
  return (
    <>
      <SignUpStepper />
    </>
  );
};

export default SignUpStepperPage;
