import React from 'react';
import { DOWNLOAD_FILE } from '../../../../helpers/helpers';
import { CustomIcon } from '../../../../common/customAssets';
import { download } from '../../../../assets';

function Attachments({ invoice, receipt, attachment }) {
  return (
    <div className='flex flex-col justify-center items-start gap-8 border shadow-[0px_1px_9px_0px_rgba(0,0,0,0.11)] p-10 rounded-xl border-solid border-[#EAECF0]'>
      <div className='text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]'>
        Claim Attachments
      </div>
      <div className='flex flex-col w-full'>
        <div>
          <div className='text-[#344054] text-base not-italic font-semibold leading-[normal]'>
            Invoices:{' '}
            {invoice?.length === 0 && (
              <span className='font-normal text-sm'>(No files found)</span>
            )}
          </div>
          {invoice?.map((e, j) => (
            <div className='flex justify-between items-center pt-1'>
              <div className='flex justify-start items-center'>
                <div className='w-8'></div>
                <div className='text-sm text-[#7D7D7D]'>
                  {`${j + 1}. ${e?.originalname}`}
                </div>
              </div>
              <div onClick={() => DOWNLOAD_FILE(e?.location)}>
                <CustomIcon
                  icon={download}
                  className='w-6 h-6 cursor-pointer'
                />
              </div>
            </div>
          ))}
          {/* {invoice?.map((el, i) =>
            el?.length > 0 ? (
              el?.map((e, j) => (
                <div className='flex justify-between items-center pt-1'>
                  <div className='flex justify-start items-center'>
                    <div className='w-8'></div>
                    <div className='text-sm text-[#7D7D7D]'>
                      {`${j + 1}. ${
                        e[0]?.originalname
                          ? e[0]?.originalname
                          : e?.originalname
                      }`}
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      DOWNLOAD_FILE(
                        e[0]?.location ? e[0]?.location : e?.location
                      )
                    }
                  >
                    <CustomIcon
                      icon={download}
                      className='w-6 h-6 cursor-pointer'
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className='flex justify-between items-center pt-1'>
                <div className='flex justify-start items-center'>
                  <div className='w-8'></div>
                  <div className='text-sm text-[#7D7D7D]'>
                    {`${i + 1}. ${
                      el[0]?.originalname
                        ? el[0]?.originalname
                        : el?.originalname
                    }`}
                  </div>
                </div>
                <div
                  onClick={() =>
                    DOWNLOAD_FILE(
                      el[0]?.location ? el[0]?.location : el?.location
                    )
                  }
                >
                  <CustomIcon
                    icon={download}
                    className='w-6 h-6 cursor-pointer'
                  />
                </div>
              </div>
            )
          )} */}
        </div>
        <div className='text-[#344054] text-base not-italic font-semibold leading-[normal]'>
          Receipts:{' '}
          {receipt?.length === 0 && (
            <span className='font-normal text-sm'>(No files found)</span>
          )}
        </div>
        {receipt?.map((e, j) => (
          <div className='flex justify-between items-center pt-1'>
            <div className='flex justify-start items-center'>
              <div className='w-8'></div>
              <div className='text-sm text-[#7D7D7D]'>
                {`${j + 1}. ${e?.originalname}`}
              </div>
            </div>
            <div onClick={() => DOWNLOAD_FILE(e?.location)}>
              <CustomIcon icon={download} className='w-6 h-6 cursor-pointer' />
            </div>
          </div>
        ))}
        {/* {receipt?.map((el, i) =>
          el?.length > 0 ? (
            el?.map((e, j) => (
              <div className='flex justify-between items-center pt-1'>
                <div className='flex justify-start items-center'>
                  <div className='w-8'></div>
                  <div className='text-sm text-[#7D7D7D]'>
                    {`${j + 1}. ${
                      e[0]?.originalname ? e[0]?.originalname : e?.originalname
                    }`}
                  </div>
                </div>
                <div
                  onClick={() =>
                    DOWNLOAD_FILE(e[0]?.location ? e[0]?.location : e?.location)
                  }
                >
                  <CustomIcon
                    icon={download}
                    className='w-6 h-6 cursor-pointer'
                  />
                </div>
              </div>
            ))
          ) : (
            <div className='flex justify-between items-center pt-1'>
              <div className='flex justify-start items-center'>
                <div className='w-8'></div>
                <div className='text-sm text-[#7D7D7D]'>
                  {`${i + 1}. ${
                    el[0]?.originalname ? el[0]?.originalname : el?.originalname
                  }`}
                </div>
              </div>
              <div
                onClick={() =>
                  DOWNLOAD_FILE(
                    el[0]?.location ? el[0]?.location : el?.location
                  )
                }
              >
                <CustomIcon
                  icon={download}
                  className='w-6 h-6 cursor-pointer'
                />
              </div>
            </div>
          )
        )} */}
        <div className='text-[#344054] text-base not-italic font-semibold leading-[normal]'>
          Damage Proofs:{' '}
          {attachment?.length === 0 && (
            <span className='font-normal text-sm'>(No files found)</span>
          )}
        </div>

        {attachment?.map((e, j) => (
          <div className='flex justify-between items-center pt-1'>
            <div className='flex justify-start items-center'>
              <div className='w-8'></div>
              <div className='text-sm text-[#7D7D7D]'>
                {`${j + 1}. ${e?.originalname}`}
              </div>
            </div>
            <div onClick={() => DOWNLOAD_FILE(e?.location)}>
              <CustomIcon icon={download} className='w-6 h-6 cursor-pointer' />
            </div>
          </div>
        ))}
        {/* {attachment?.map((el, i) =>
          el?.length > 0 ? (
            el?.map((e, j) => (
              <div className='flex justify-between items-center pt-1'>
                <div className='flex justify-start items-center'>
                  <div className='w-8'></div>
                  <div className='text-sm text-[#7D7D7D]'>
                    {`${j + 1}. ${
                      e[0]?.originalname ? e[0]?.originalname : e?.originalname
                    }`}
                  </div>
                </div>
                <div
                  onClick={() =>
                    DOWNLOAD_FILE(e[0]?.location ? e[0]?.location : e?.location)
                  }
                >
                  <CustomIcon
                    icon={download}
                    className='w-6 h-6 cursor-pointer'
                  />
                </div>
              </div>
            ))
          ) : (
            <div className='flex justify-between items-center pt-1'>
              <div className='flex justify-start items-center'>
                <div className='w-8'></div>
                <div className='text-sm text-[#7D7D7D]'>
                  {`${i + 1}. ${
                    el[0]?.originalname ? el[0]?.originalname : el?.originalname
                  }`}
                </div>
              </div>
              <div
                onClick={() =>
                  DOWNLOAD_FILE(
                    el[0]?.location ? el[0]?.location : el?.location
                  )
                }
              >
                <CustomIcon
                  icon={download}
                  className='w-6 h-6 cursor-pointer'
                />
              </div>
            </div>
          )
        )} */}
      </div>
    </div>
  );
}

export default Attachments;
