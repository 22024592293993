import { useEffect, useRef, useState } from 'react';
import {
  getAllMembersQuery,
  removeEntityMember,
} from '../../../api/services/entityMembers.service';
import useApi from '../../../hook/useApi';
import { storeAllStaffAction } from '../../../store';
import { useSelector } from 'react-redux';
import useOutsideClick from '../../../hook/useOutsideClick';
import { CAPITALIZE_FIRST_LETTER } from '../../../helpers/helpers';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';

export const useStaffList = () => {
  const actionMenuRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [staffActionClickedData, setStaffActionClickedData] = useState(null);
  const [staffActionClickedId, setStaffActionClickedId] = useState(null);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);

  const { staff } = useSelector((state) => state);

  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));

  const [getAllMembersCall] = useApi(getAllMembersQuery, storeAllStaffAction);

  const [deleteStaffCall, deleteStaffLoading] = useApi(removeEntityMember);

  const user = JSON.parse(localStorage.getItem('login-user'));

  useEffect(() => {
    getAllMembersCall(user.entityId);
  }, []);

  const handleActionClick = (e, data) => {
    e.stopPropagation();
    setStaffActionClickedId(parseInt(data?.id));
    setStaffActionClickedData(data);

    toggleActionMenu ? setToggleActionMenu(null) : setToggleActionMenu(data.id);
    data && localStorage.setItem('shipment', JSON.stringify(data));
  };

  const modifiedData = staff?.staffList?.map((staff) => {
    return {
      ...staff,
      id: staff.id,
      name: staff.firstName + ' ' + staff.lastName,
      email: staff.emailAddress,
      role: CAPITALIZE_FIRST_LETTER(staff.role),
    };
  });

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const handleDeleteStaff = () => {
    return { data: {} };
  };

  //

  const deleteStaffHandler = async (staffId) => {
    toast.dismiss();
    toast.loading('Deleting staff...');
    const { data, errors } = await deleteStaffCall(staffId);

    if (data) {
      const user = JSON.parse(localStorage.getItem('login-user'));
      await getAllMembersCall(user.entityId);
      toast.dismiss();
      toast.success('Staff deleted successfully', { duration: TOAST_TIME });
    }
    if (errors) {
      toast.dismiss();
      toast.error('error in deleting staff', { duration: TOAST_TIME });
    }

    return { data, errors };
  };

  //

  return {
    data: modifiedData,
    toggleActionMenu,
    handleActionClick,
    actionMenuRef,
    staffActionClickedId,
    staffActionClickedData,
    openDeleteModal,
    showDeleteModal,
    closeDeleteModal,
    handleDeleteStaff,
    deleteStaffHandler,
    deleteStaffLoading,
  };
};
