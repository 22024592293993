import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { describeSchema } from '../../../../shipments/schema/describeClaimSchema';
import { useHookFormSubmission } from '../../../../../hook/useHookFormSubmission';
import { updateReportClaimFormAction } from '../../../../../store';

export const useDescribeClaim = (increaseStepHandler, toBeClaimed) => {
  const { step2 } = useSelector((state) => state.forms.claimFile.formData);
  const dispatch = useDispatch();

  const { handleSubmit, register, errors, watch, setValue } =
    useHookFormSubmission(describeSchema);

  const { claimType } = watch();

  useEffect(() => {
    setValue('claimType', step2 && step2.claimType);
    setValue('description', step2 && step2.description);
  }, []);

  const onSubmit = (values) => {
    increaseStepHandler();
    dispatch(updateReportClaimFormAction({ step: 2, values }));
  };

  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    claimType,
    toBeClaimed,
  };
};
