import React from 'react';
import {
  ReportShipmentStepOne,
  ReportShipmentStepThree,
  ReportShipmentStepTwo,
  ReportShipmentsStepper,
} from '../components';
import CustomContainer from '../../../common/custom/CustomContainer';
import { ClientsPanelHeader } from '../../../common/custom';
import { useReportShipments } from './use-report-shipments';
import Stepper from '../../auth/component/stepper';

const ReportsShipments = () => {
  const { currentStep, increaseStepHandler, decreaseStepHandler, isTrial } =
    useReportShipments();
  const steps = ['Ship To', 'Details', 'Select Mover'];
  const displaySteps = (currentStep) => {
    switch (currentStep) {
      case 1:
        return (
          <ReportShipmentStepOne
            increaseStepHandler={increaseStepHandler}
            isTrial={isTrial}
          />
        );
      case 2:
        return (
          <ReportShipmentStepTwo
            increaseStepHandler={increaseStepHandler}
            decreaseStepHandler={decreaseStepHandler}
            isTrial={isTrial}
          />
        );
      case 3:
        return (
          <ReportShipmentStepThree decreaseStepHandler={decreaseStepHandler} />
        );
      default:
    }
  };

  return (
    <CustomContainer>
      <ClientsPanelHeader hideReportButton />
      <div className='flex flex-col lg:flex-row justify-between'>
        <ReportShipmentsStepper steps={steps} currentStep={currentStep} />

        <div className='lg:hidden max-w-[304px] md:max-w-[400px]  ml-auto mr-auto w-full  mb-[55px] mt-[-12px]'>
          <Stepper steps={steps} currentStep={currentStep} />
        </div>

        {displaySteps(currentStep)}
      </div>
    </CustomContainer>
  );
};

export default ReportsShipments;
