import { LoaderIcon } from 'react-hot-toast';
import { cross, esign, tickIcon } from '../../assets';
import { CustomButton, CustomModal } from '../../common/custom';
import { CustomIcon } from '../../common/customAssets';
import ReactSignatureCanvas from 'react-signature-canvas';

const SignPopup = ({
  success,
  loading,
  signatureRef,
  saveSignature,
  clearSignature,
  handleCloseSignModal,
}) => {
  return (
    <CustomModal>
      <div className='max-w-[600px] py-4 bg-pure-white w-full rounded-[12px] flex flex-col items-center gap-1 justify-center'>
        {success ? (
          <>
            <div className='max-w-[600px] py-4 bg-pure-white w-full rounded-[12px] flex flex-col items-center gap-4 justify-center'>
              <div className='bg-green-500 rounded-full p-4 success-tick'>
                <CustomIcon
                  icon={tickIcon}
                  className='h-[48px] w-[48px] text-green-500'
                />
              </div>
              <div className='text-[24px] font-inter leading-[32px] font-[600] text-green-600'>
                Document Signed Successfully!
              </div>
            </div>
            <div className=' h-[44px] flex justify-center gap-[12px] w-full max-w-[476px] mt-[8px] '>
              <CustomButton
                type='button'
                onClick={handleCloseSignModal}
                className='bg-none rounded-[8px] border-[1px] border-foundation-brown text-foundation-brown max-w-[160px] w-full h-[48px]'
                variant='outline'
                text='Close'
              />
            </div>
          </>
        ) : loading ? (
          <LoaderIcon className='p-24' />
        ) : (
          <>
            <div className='flex justify-between w-full px-2'>
              <div className='flex justify-start items-center gap-1'>
                <div className='bg-[#FEE4E2] border-[8px] border-[#FEF3F2] w-[48px] h-[48px] shrink-0 flex justify-center items-center rounded-full'>
                  <CustomIcon icon={esign} />
                </div>
                <h1 className='text-[18px] font-semibold text-foundation-brown font-inter'>
                  Write Your Signature
                </h1>
              </div>
              <div
                onClick={handleCloseSignModal}
                className='cursor-pointer h-[24px] flex items-center'
              >
                <CustomIcon icon={cross} className='h-[12px] w-[12px]' />
              </div>
            </div>

            <div className='bg-blue-400 border-1 rounded'>
              <ReactSignatureCanvas
                ref={signatureRef}
                penColor='black'
                canvasProps={{
                  width: 588,
                  height: 200,
                  className: 'signature-canvas',
                }}
              />
            </div>
            <div className='flex justify-center gap-2 mt-2'>
              <CustomButton
                type='button'
                onClick={clearSignature}
                className='bg-none rounded-[4px] border-[1px] border-foundation-brown text-foundation-brown min-w-[120px] w-full h-[36px]'
                variant='outline'
                text='Clear'
              />

              <CustomButton
                className='bg-foundation-brown rounded-[4px] text-white min-w-[120px] w-full h-[36px]'
                text='Sign'
                onClick={saveSignature}
              />
            </div>
          </>
        )}
      </div>
    </CustomModal>
  );
};

export default SignPopup;
