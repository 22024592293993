import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateShipmentMutation } from '../../../api';
import { PATHS } from '../../../constants/paths';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { DATE_FORMATE_CONVERSION } from '../../../helpers/helpers';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import { editShipmentSchema } from '../schema/editShipmentSchema';

export const useViewShipmentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [toAddress, setToAddress] = useState(null);
  const [declaredValue, setDeclaredValue] = useState('');
  const [customerDetailModalOpen, setCustomerDetailModal] = useState(false);

  const { shipments } = useSelector((state) => state);

  const [update, updateLoading] = useApi(updateShipmentMutation);

  const { addresses } = useSelector((state) => state);

  const user = JSON.parse(localStorage.getItem('login-user'));
  const rate = user && user.entity && user.entity.rate;

  const { handleSubmit, register, errors, trigger, setValue } =
    useHookFormSubmission(editShipmentSchema);

  const findEditSHipment = shipments?.allShipments.find(
    (shipment) => shipment.id === id
  );

  useEffect(() => {
    if (findEditSHipment) {
      if (findEditSHipment.carrierId) {
        setValue('carrier', findEditSHipment.carrierId);
      }
      if (findEditSHipment.pickUpDate) {
        setValue('pickupDate', findEditSHipment.pickUpDate?.split('T')[0]);
      }
      if (findEditSHipment.deliveryDate) {
        setValue('deliveryDate', findEditSHipment.deliveryDate?.split('T')[0]);
      }
      if (findEditSHipment.totalValue) {
        setValue('declaredValue', declaredValue || findEditSHipment.totalValue);
        setDeclaredValue(declaredValue || findEditSHipment.totalValue);
      }
      if (findEditSHipment.shipmentType) {
        setValue('merchandiseType', findEditSHipment.shipmentType.id);
      }
    }
  }, [findEditSHipment]);

  const closeCustomerDetailModal = () => setCustomerDetailModal(false);
  const openCustomerDetailModal = () => setCustomerDetailModal(true);

  const getUpdateCustomerDetailData = (updatedCustomerDetail) => {
    setToAddress(updatedCustomerDetail);
  };

  const parseAndCleanObject = (jsonString) => {
    try {
      const parsedObject = JSON.parse(jsonString);

      // Replace undefined values with an empty string
      const cleanObject = Object.entries(parsedObject).reduce((acc, [key, value]) => {
        acc[key] = (value === undefined || value === null) ? "" : value;
        return acc;
      }, {});

      return cleanObject;
    } catch (error) {
      console.error("Invalid JSON string:", error);
      return null;
    }
  };

  const streetPlaceholder = () => {
    if (toAddress) {
      return toAddress;
    } else {
      return parseAndCleanObject(findEditSHipment.to);
    }
  }

  const handleDeclaredValue = (value) => {
    setDeclaredValue(value);
  };

  const onSubmit = async (values) => {
    toast.dismiss();
    toast.loading('updating');
    values.deliveryDate = DATE_FORMATE_CONVERSION(values.deliveryDate);
    values.pickUpDate = DATE_FORMATE_CONVERSION(values.pickupDate);

    const modifiedToAddress = {
      name: toAddress.destinationName,
      city: toAddress.city,
      state: toAddress.state,
      zipCode: toAddress.zipCode,
      unitNo: toAddress.unitNumber,
      reference: toAddress.referenceNumber,
      country: toAddress.country,
    };
    const { data, errors } = await update({
      shipmentId: parseInt(findEditSHipment.id),
      ...values,
      // to: toAddress ? JSON.stringify(toAddress) : findEditSHipment.to,
      to: toAddress ? JSON.stringify(modifiedToAddress) : findEditSHipment.to,
      fromLocationId: parseInt(addresses.selectedAddress.id),
    });
    if (data) {
      navigate(PATHS.SHIPMENTS);
      toast.dismiss();
      toast.success('Successfully updated', { duration: TOAST_TIME });
    }
    if (errors) {
      toast.dismiss();
      toast.error('Failed to update', { duration: TOAST_TIME });
    }
  };

  return {
    rate,
    data: findEditSHipment,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    closeCustomerDetailModal,
    openCustomerDetailModal,
    getUpdateCustomerDetailData,
    streetPlaceholder,
    customerDetailModalOpen,
    declaredValue,
    handleDeclaredValue,
    navigate,
    trigger,
    updateLoading,
  };
};
