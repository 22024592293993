import { useSelector } from "react-redux";
import addShipFromAddressSchema from "../schema/add-ship-from-address.schema";
import { useHookFormSubmission } from "../../../hook/useHookFormSubmission";
import { useEffect } from "react";

export const useEditShipmentFromAddress = (id) => {
  const { handleSubmit, register, errors, trigger, setValue } =
    useHookFormSubmission(addShipFromAddressSchema);
  const { addresses } = useSelector((state) => state);
  const findAddress = addresses?.addresses?.find((obj) => obj.id == id);

  useEffect(() => {
    setValue("locationName", findAddress?.nickName ? findAddress.nickName : "");
  }, [addresses]);

  return { handleSubmit, register, errors, trigger, setValue };
};
