import React from 'react';
import { Login } from '../../features/auth';

const LoginPage = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default LoginPage;
