import { configureStore } from '@reduxjs/toolkit';

import { persistStore } from 'redux-persist';
import { rootReducer } from './slices';
import persistConfig from './persist.config';

// ****** AUth Actions**********//

// ***********************

// *********ui actions***********//
import { toggleMobileNavBarAction } from './slices/ui-slice';

// *********forms actions*******************************************************************************//
import {
  resetSignUpAction,
  setStepAction,
  updateFormDataAction,
  updateReportShipmentFormAction,
  setReportShipmentFormStepAction,
  resetReportShipmentFormAction,
  updateReportClaimFormAction,
  setReportClaimFormStepAction,
  resetReportClaimFormAction,
} from './slices/formsSlice';

// ******************************//

// ***********shipments actions*************//

import {
  storeAllShipmentsAction,
  // addShipmentAction,
  cancelShipmentAction,
} from './slices/shipmentsSlice';

// ***************************************** //

// ***********Claim actions*************//

import {
  storeAllClaimAction,
  addClaimAction,
  cancelClaimAction,
} from './slices/claimSlices';

// ***************************************** //

//***************addresses actions****************************/
import {
  storeAllAddressesAction,
  setAddressAction,
  resetAddressSliceAction,
} from './slices/addressesSlice';

// **************************************************************

//**************staff actions */

import { storeAllStaffAction } from './slices/staffSlice';
import { storeLoginUserAction } from './slices/loginUserSlice';

//**************************** */

// *****************payments actions**************************/

// **********************

export const store = configureStore({
  reducer: persistConfig(rootReducer),
});

export {
  toggleMobileNavBarAction,
  resetSignUpAction,
  setStepAction,
  updateFormDataAction,
  updateReportShipmentFormAction,
  setReportShipmentFormStepAction,
  resetReportShipmentFormAction,
  storeAllShipmentsAction,
  // addShipmentAction,
  cancelShipmentAction,
  storeAllAddressesAction,
  setAddressAction,
  storeAllStaffAction,
  storeAllClaimAction,
  addClaimAction,
  cancelClaimAction,
  updateReportClaimFormAction,
  setReportClaimFormStepAction,
  resetReportClaimFormAction,
  resetAddressSliceAction,
  storeLoginUserAction,
};

export const persistor = persistStore(store);
