import { createSlice } from '@reduxjs/toolkit';
import { toggleMobileNavBar } from './uiActions';

const uiSlice = createSlice({
  name: 'ui',
  initialState: { models: {}, mobileNavbarOpen: false },
  reducers: {
    toggleMobileNavBarAction: toggleMobileNavBar,
  },
});

export const uiSliceReducer = uiSlice.reducer;
export const { toggleMobileNavBarAction } = uiSlice.actions;
