import React, { useState } from 'react';
import { PaymentMethodDescriptonMethodModal } from '../../modals';
import CustomContainer from '../../../common/custom/CustomContainer';
import { ClientsPanelHeader, CustomText } from '../../../common/custom';
import { useSavecard } from './useSaveCard';
import StripeCardDetailsForm from '../components/stripe-form';
import { CustomIcon } from '../../../common/customAssets';
import { alertCircleBlueIcon } from '../../../assets';

const SaveCard = () => {
  const {
    paymentDescriptionModal,
    closePaymentDescriptionModal,
    openPaymentDescriptionModal,
    setupIntent,
    error,
    setError,
  } = useSavecard();

  return (
    <>
      {paymentDescriptionModal ? (
        <PaymentMethodDescriptonMethodModal
          closePaymentDescriptionModal={closePaymentDescriptionModal}
        />
      ) : null}
      <CustomContainer>
        <ClientsPanelHeader />
        <div className='flex flex-col sm:flex-row justify-between gap-3'>
          <div className='order-1 sm:order-none'>
            {error && (
              <CustomText className='mb-[12px] text-[#ff0000] font-[500] text-[14px] font-inter leading-[16.94px]'>
                {error}
              </CustomText>
            )}
            <CustomText className='mb-[12px] text-[#B86F00] font-[500] text-[14px] font-inter leading-[16.94px]'>
              *Additional 3% on premium will be charged on Credit Card payments
            </CustomText>
            <CustomText className='text-foundation-brown font-inter font-[600] text-[22px] leading-[26.63px]'>
              Add Payment Method
            </CustomText>
          </div>
          <div>
            <CustomIcon icon={alertCircleBlueIcon} />
          </div>
        </div>
        {/* <h1>Add Payment Method</h1> */}
        {setupIntent ? (
          <StripeCardDetailsForm
            setupIntent={setupIntent}
            setError={setError}
          />
        ) : null}
      </CustomContainer>
    </>
  );
};

export default SaveCard;
