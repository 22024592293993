import React from 'react';

import {
  CustomButton,
  CustomDropdown,
  CustomInput,
  CustomModal,
} from '../../../common/custom';
import AutoCompleteComponent from '../../components/auto-complete';
import { useCustomerDetails } from './useCustomerDetails';
const CustomersDetailModal = ({
  data,
  closeCustomerDetailModal,
  getUpdateCustomerDetailData,
}) => {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    statePlceholder,
    trigger,
    fieldsFromGoogle,
    handleGoogleAutoComplete,
  } = useCustomerDetails(
    data,
    getUpdateCustomerDetailData,
    closeCustomerDetailModal
  );

  const selecetedState = [
    { id: 'AL', state: 'Alabama' },
    { id: 'AK', state: 'Alaska' },
    { id: 'AZ', state: 'Arizona' },
    { id: 'AR', state: 'Arkansas' },
    { id: 'CA', state: 'California' },
    { id: 'CO', state: 'Colorado' },
    { id: 'CT', state: 'Connecticut' },
    { id: 'DE', state: 'Delaware' },
    { id: 'DC', state: 'District Of Columbia' },
    { id: 'FL', state: 'Florida' },
    { id: 'GA', state: 'Georgia' },
    { id: 'HI', state: 'Hawaii' },
    { id: 'ID', state: 'Idaho' },
    { id: 'IL', state: 'Illinois' },
    { id: 'IN', state: 'Indiana' },
    { id: 'IA', state: 'Iowa' },
    { id: 'KS', state: 'Kansas' },
    { id: 'KY', state: 'Kentucky' },
    { id: 'LA', state: 'Louisiana' },
    { id: 'ME', state: 'Maine' },
    { id: 'MD', state: 'Maryland' },
    { id: 'MA', state: 'Massachusetts' },
    { id: 'MI', state: 'Michigan' },
    { id: 'MN', state: 'Minnesota' },
    { id: 'MS', state: 'Mississippi' },
    { id: 'MO', state: 'Missouri' },
    { id: 'MT', state: 'Montana' },
    { id: 'NE', state: 'Nebraska' },
    { id: 'NV', state: 'Nevada' },
    { id: 'NH', state: 'New Hampshire' },
    { id: 'NJ', state: 'New Jersey' },
    { id: 'NM', state: 'New Mexico' },
    { id: 'NY', state: 'New York' },
    { id: 'NC', state: 'North Carolina' },
    { id: 'ND', state: 'North Dakota' },
    { id: 'OH', state: 'Ohio' },
    { id: 'OK', state: 'Oklahoma' },
    { id: 'OR', state: 'Oregon' },
    { id: 'PA', state: 'Pennsylvania' },
    { id: 'RI', state: 'Rhode Island' },
    { id: 'SC', state: 'South Carolina' },
    { id: 'SD', state: 'South Dakota' },
    { id: 'TN', state: 'Tennessee' },
    { id: 'TX', state: 'Texas' },
    { id: 'UT', state: 'Utah' },
    { id: 'VT', state: 'Vermont' },
    { id: 'VA', state: 'Virginia' },
    { id: 'WA', state: 'Washington' },
    { id: 'WV', state: 'West Virginia' },
    { id: 'WI', state: 'Wisconsin' },
    { id: 'WY', state: 'Wyoming' },
  ].find((state) => state.id === statePlceholder());

  return (
    <CustomModal>
      <div className='p-10 max-w-[996px] rounded-xl w-full mx-auto bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
        <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
          Update Customer Details
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid grid-cols-2 gap-[16px] mb-[16x]'>
            <div>
              <CustomInput
                placeholder='Destination(e.g. Recipient or storage facility)*'
                label='Destination'
                name='destinationName'
                register={register}
                errors={errors}
                trigger={trigger}
                setValue={setValue}
              />
            </div>
            <div>
              <CustomInput
                placeholder='Enter reference#'
                label='Reference'
                register={register}
                name='referenceNumber'
                errors={errors}
                trigger={trigger}
                setValue={setValue}
              />
            </div>
          </div>
          <div className='my-4'>
            <AutoCompleteComponent
              setValue={setValue}
              trigger={trigger}
              errors={errors}
              register={register}
              label='Address'
              name='streetAddress'
              address={fieldsFromGoogle}
              onChange={handleGoogleAutoComplete}
            />
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
            <div>
              <CustomInput
                register={register}
                name='unitNumber'
                placeholder='Enter unit number'
                label='Unit Number'
                errors={errors}
                trigger={trigger}
                setValue={setValue}
              />
            </div>
            <div>
              <CustomInput
                name='city'
                register={register}
                placeholder='Enter your city'
                required
                label='City'
                errors={errors}
                trigger={trigger}
                setValue={setValue}
              />
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div>
              <CustomInput
                name='zipCode'
                placeholder='Enter your zipcode'
                label='Zip Code'
                register={register}
                errors={errors}
                trigger={trigger}
                setValue={setValue}
              />
            </div>

            <div className=''>
              <CustomDropdown
                label='State'
                // placeholder={statePlceholder()}
                placeholder={selecetedState.state}
                options={[
                  { id: 'AL', state: 'Alabama' },
                  { id: 'AK', state: 'Alaska' },
                  { id: 'AZ', state: 'Arizona' },
                  { id: 'AR', state: 'Arkansas' },
                  { id: 'CA', state: 'California' },
                  { id: 'CO', state: 'Colorado' },
                  { id: 'CT', state: 'Connecticut' },
                  { id: 'DE', state: 'Delaware' },
                  { id: 'DC', state: 'District Of Columbia' },
                  { id: 'FL', state: 'Florida' },
                  { id: 'GA', state: 'Georgia' },
                  { id: 'HI', state: 'Hawaii' },
                  { id: 'ID', state: 'Idaho' },
                  { id: 'IL', state: 'Illinois' },
                  { id: 'IN', state: 'Indiana' },
                  { id: 'IA', state: 'Iowa' },
                  { id: 'KS', state: 'Kansas' },
                  { id: 'KY', state: 'Kentucky' },
                  { id: 'LA', state: 'Louisiana' },
                  { id: 'ME', state: 'Maine' },
                  { id: 'MD', state: 'Maryland' },
                  { id: 'MA', state: 'Massachusetts' },
                  { id: 'MI', state: 'Michigan' },
                  { id: 'MN', state: 'Minnesota' },
                  { id: 'MS', state: 'Mississippi' },
                  { id: 'MO', state: 'Missouri' },
                  { id: 'MT', state: 'Montana' },
                  { id: 'NE', state: 'Nebraska' },
                  { id: 'NV', state: 'Nevada' },
                  { id: 'NH', state: 'New Hampshire' },
                  { id: 'NJ', state: 'New Jersey' },
                  { id: 'NM', state: 'New Mexico' },
                  { id: 'NY', state: 'New York' },
                  { id: 'NC', state: 'North Carolina' },
                  { id: 'ND', state: 'North Dakota' },
                  { id: 'OH', state: 'Ohio' },
                  { id: 'OK', state: 'Oklahoma' },
                  { id: 'OR', state: 'Oregon' },
                  { id: 'PA', state: 'Pennsylvania' },
                  { id: 'RI', state: 'Rhode Island' },
                  { id: 'SC', state: 'South Carolina' },
                  { id: 'SD', state: 'South Dakota' },
                  { id: 'TN', state: 'Tennessee' },
                  { id: 'TX', state: 'Texas' },
                  { id: 'UT', state: 'Utah' },
                  { id: 'VT', state: 'Vermont' },
                  { id: 'VA', state: 'Virginia' },
                  { id: 'WA', state: 'Washington' },
                  { id: 'WV', state: 'West Virginia' },
                  { id: 'WI', state: 'Wisconsin' },
                  { id: 'WY', state: 'Wyoming' },
                ]}
                name='state'
                setValue={setValue}
                errors={errors}
              />
            </div>
          </div>
          <div className=' flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
            <CustomButton
              type='button'
              onClick={closeCustomerDetailModal}
              className='bg-none rounded-[8px] border-[1px] border-foundation-brown  text-foundation-brown max-w-[160px] w-full h-[48px]'
              variant='outline'
              text='Cancel'
            />

            <CustomButton
              className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
              text='Update'
            />
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default CustomersDetailModal;
