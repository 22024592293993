import { useDispatch, useSelector } from 'react-redux';
import { setReportShipmentFormStepAction } from '../../../store';
import useApi from '../../../hook/useApi';
import { checkTrialShipmentQuery } from '../../../api';
import { useEffect, useState } from 'react';

export const useReportShipments = () => {
  const dispatch = useDispatch();
  const { reportShipment } = useSelector((state) => state.forms);
  const [isTrial, setIsTrial] = useState(false);

  const [getAll] = useApi(checkTrialShipmentQuery);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('login-user'));
    if (user?.entityId) {
      const getData = async () => {
        const { data, errors } = await getAll(user?.entityId);
        if (data) setIsTrial(data?.checkTrialQuery?.isFirst);
      };
      getData();
    }
  }, []);

  const increaseStepHandler = () => {
    const step = reportShipment.currentStep + 1;
    dispatch(setReportShipmentFormStepAction(step));
  };

  const decreaseStepHandler = () => {
    const step = reportShipment.currentStep - 1;
    dispatch(setReportShipmentFormStepAction(step));
  };

  return {
    currentStep: reportShipment.currentStep,
    increaseStepHandler,
    decreaseStepHandler,
    isTrial,
  };
};
