import React from 'react';
import { actionButton, cross, tickIcon } from '../../../assets';
import { CustomIcon } from '../../customAssets';
import CustomText from '../CustomText';
import CustomPagination from '../custom-pagination';
import useCustomTable from './use-custom-table.hook';
import { Tooltip } from '@material-tailwind/react';

function CustomTable({
  header,
  rows,
  handleActionClick,
  toggleActionMenu,
  menu,
  actionMenuRef,
}) {
  const {
    currentItems,
    renderPaginationNumbers,
    handlePrevPage,
    handleNextPage,
    disableNext,
    disablePrevious,
  } = useCustomTable(rows);

  const SelectedGif = (
    <svg width='80' height='28' xmlns='http://www.w3.org/2000/svg'>
      <ellipse cx='36' cy='16' rx='36' ry='8' fill='green' />

      <text
        x='48'
        y='16'
        dominant-baseline='middle'
        text-anchor='middle'
        font-family='Arial'
        font-size='10'
        fill='white'
      >
        <tspan x='36' dy='0'>
          Selected
        </tspan>
        <animate
          attributeName='opacity'
          values='0;1;0'
          dur='1.5s'
          repeatCount='indefinite'
        />{' '}
      </text>
    </svg>
  );

  const clonedMenu = menu && React.cloneElement(menu, { upper: true });
  const wideCols = ['nickName', 'address', 'from', 'email', 'recipient'];
  return (
    <div>
      <div className='primary-scroll relative overflow-x-auto max-h-[600px]   '>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
          <thead className='bg-[#F1EEEC] h-[44px] text-xs text-gray-700 uppercase dark:text-gray-400'>
            <tr>
              {header?.map((column) => {
                return (
                  <th
                    scope='col'
                    className={`text-xs not-italic font-bold leading-[normal] normal-case h-[44px] px-6 py-3 items-center p-6 text-foundation-brown font-inter text-small-para ${
                      column.name === 'action' && 'w-[87px]'
                    } ${
                      wideCols.includes(column.name) ? 'w-[500px]' : 'w-[100px]'
                    } `}
                  >
                    {column.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems?.map((row, index) => {
                return (
                  <tr className='h-[72px]'>
                    {header?.map((column) => {
                      return (
                        <th
                          scope='row'
                          className='border-solid border-[#EAECF0]border-[#EAECF0] border-r border-b w-fit text-sm not-italic font-medium leading-[normal] text-[#475467] px-6 py-4 whitespace-nowrap '
                        >
                          {column.name == 'nickName' &&
                            row['isSelected'] == true && (
                              <span>{SelectedGif}</span>
                            )}
                          {column.name === 'status' ||
                          column.name === 'premium' ? (
                            <span
                              className={`text-[#77553D] py-1 px-2 rounded-full truncate w-[100px]                                 ${
                                row[column.name] === 'Delivered'
                                  ? 'bg-[#F1DDDD]'
                                  : 'bg-[#F1EEEC]'
                              }`}
                            >
                              {row[column.name] === 'UNPAID' ? (
                                <Tooltip
                                  className='mt-[-10px] z-50'
                                  content='Premium amount will be available after 24 hours from shipment reporting time'
                                >
                                  {row[column.name]}
                                </Tooltip>
                              ) : (
                                row[column.name]
                              )}
                            </span>
                          ) : column.name === 'trialed' ||
                            column.name === 'partial' ? (
                            <div className='w-full flex justify-center gap-2'>
                              {row[column.name] === true ? (
                                <CustomIcon
                                  icon={tickIcon}
                                  className='h-[16px] w-[16px] flex justify-center items-center bg-[#77553d] rounded rounded-full p-1'
                                />
                              ) : (
                                <CustomIcon
                                  icon={cross}
                                  className='h-[16px] w-[16px] flex justify-center items-center p-[3px]'
                                />
                              )}
                            </div>
                          ) : column.name === 'confirmation' ? (
                            <span className='bg-[#E6FFDE] text-[#228400] py-1 px-2 rounded-full truncate w-[100px]'>
                              {row[column.name]}
                            </span>
                          ) : column.name == 'from' ? (
                            row[column.name].length <= 2 ? (
                              <div className=''>
                                <span>{row[column.name][0]}</span>
                                <br />
                                <div className='text-xs font-normal'>
                                  {row[column.name][1]}
                                </div>
                              </div>
                            ) : (
                              <span>{row[column.name]}</span>
                            )
                          ) : (
                            <div
                              className={`truncate w-[${
                                wideCols.includes(column.name)
                                  ? '300px'
                                  : '100px'
                              }`}
                            >
                              {row[column.name]}
                            </div>
                          )}
                          {column.name === 'action' && (
                            <CustomText className='w-full  max-w-[87px] flex justify-center relative'>
                              <div
                                ref={actionMenuRef}
                                className='cursor-pointer  p-2'
                                onClick={(e) => handleActionClick(e, row)}
                              >
                                <CustomIcon icon={actionButton} />
                              </div>

                              {row.id === toggleActionMenu
                                ? React.cloneElement(menu, {
                                    upper:
                                      index === currentItems.length - 1 ||
                                      index === currentItems.length - 2,
                                  })
                                : null}
                            </CustomText>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {rows && rows.length ? (
        <div className='pt-[12px] pb-[16px] px-[24px] flex justify-center sm:justify-end max-w-[1185px]'>
          <CustomPagination
            renderPaginationNumbers={renderPaginationNumbers}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            disableNext={disableNext}
            disablePrevious={disablePrevious}
          />
        </div>
      ) : null}
    </div>
  );
}

export default CustomTable;
