import client from "./apollo.";
import {
  fetchCurrentUserQuery,
  forgetPasswordMutation,
  loginUser,
  resetPasswordMutation,
} from "./services/auth.service.js";
import { getAllClaimsQuery } from "./services/claim.service.js";
import { onboardingMutation } from "./services/onboarding.service.js";
import {
  allShipmentFromEntityQuery,
  checkTrialShipmentQuery,
  cancelShipmentMutation,
  getAllShipmentsFromLocationQuery,
  getShipmentQuery,
  reportShipmentMutation,
  updateShipmentMutation,
} from "./services/shipments.service.js";
import {
  findAllSalesMemberQuery,
  forgetUserIdMutation,
} from "./services/users.service.js";

import {
  getMyLocationsAddressesQuery,
  addAddressMutation,
  removeAddressMutation,
} from "./services/address.service.js";
import {
  addMemberMutation,
  getAllMembersQuery,
} from "./services/entityMembers.service.js";
import { createSetupIntentMutation } from "./services/payment.service.js";
import { getListPaymentMethodsQuery } from "./services/paymentMethods.service.js";
import { updateDefaultLocation } from "./services/location.service.js";
import { getDashboardStatisticsQuery } from "./services/dashboard.service.js";
import { removeEntityMember } from "./services/entityMembers.service.js";

export {
  client,
  loginUser,
  onboardingMutation,
  findAllSalesMemberQuery,
  forgetPasswordMutation,
  resetPasswordMutation,
  getAllClaimsQuery,
  getAllShipmentsFromLocationQuery,
  reportShipmentMutation,
  cancelShipmentMutation,
  getShipmentQuery,
  getMyLocationsAddressesQuery,
  addAddressMutation,
  removeAddressMutation,
  addMemberMutation,
  createSetupIntentMutation,
  updateShipmentMutation,
  getListPaymentMethodsQuery,
  updateDefaultLocation,
  fetchCurrentUserQuery,
  removeEntityMember,
  allShipmentFromEntityQuery,
  checkTrialShipmentQuery,
  forgetUserIdMutation,
  getDashboardStatisticsQuery,
};
