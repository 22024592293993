import React from 'react';
import {
  CustomDropdown,
  CustomInput,
  ValidationErrorText,
} from '../../../../../common/custom';
import { PATHS } from '../../../../../constants/paths';
import { useRecipientDetails } from './use-recipient-detail.hook';
import CustomDropdown2 from '../../../../../common/custom/custom-drop-down/index2';
import TextArea from '../../../../../common/custom/customTextArea';

const RecipientDetails = ({ increaseStepHandler }) => {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    contact,
    navigate,
    trigger,
    claimableShipments,
    setSelectedShipment,
  } = useRecipientDetails(increaseStepHandler);

  return (
    <div className='stepper-cards'>
      <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
        Enter Recipient Detail
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid gap-4'>
          <div>
            <CustomDropdown2
              placeholder='Select Shipment'
              label='Select Shipment to create claim'
              setValue={(e) =>
                setSelectedShipment(claimableShipments?.find((i) => i?.id == e))
              }
              options={claimableShipments}
              name='selectedShipment'
            />
          </div>

          <div>
            <CustomInput
              register={register}
              name='location'
              type='text'
              label='Location Name'
              errors={errors}
              trigger={trigger}
              setValue={setValue}
            />
          </div>
          <div>
            <CustomInput
              register={register}
              name='claimAmount'
              placeholder='Claim Amount'
              type='number'
              label='Claim Amount'
            />
            {errors.claimAmount ? (
              <ValidationErrorText>
                {errors.claimAmount.message}
              </ValidationErrorText>
            ) : null}
          </div>
          <div>
            <CustomInput
              register={register}
              name='phoneNumber'
              placeholder="Recipient's Phone Number"
              label="Recipient's Phone Number"
              errors={errors}
              trigger={trigger}
              setValue={setValue}
            />
          </div>
          <div className=''>
            <CustomDropdown
              placeholder='Choose best way to contact'
              name='contact'
              label='Best Way to contact the Recipient'
              value={contact}
              options={[
                { id: 'Email', state: 'Email' },
                { id: 'Phone', state: 'Phone' },
              ]}
              setValue={setValue}
              errors={errors}
            />
          </div>
          <div>
            <CustomInput
              register={register}
              name='filedByName'
              type='text'
              label='Filed By Name'
              errors={errors}
              trigger={trigger}
              setValue={setValue}
            />
          </div>
          <div>
            <TextArea
              label='Part to be Claimed / Claim Description'
              name='partToBeClaimed'
              register={register}
              errors={errors}
              placeholder='Part to be Claimed / Claim Description'
              type='text'
              labelClassName='text-[#344054] text-sm not-italic font-medium leading-5'
            />
          </div>
          <div className='flex justify-end mt-[32px]'>
            <div className='flex gap-[32px]'>
              <button
                className='flex border border-solid text-[#77553D] text-base not-italic font-medium leading-6 border-[#77553D] w-40 justify-center bg-[ #fff] items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg border-[#77553D]'
                onClick={() => navigate(PATHS.CLAIMS)}
              >
                Cancel
              </button>
              <button
                className='flex text-white text-base not-italic font-medium leading-6 w-40 bg-[#77553d] justify-center items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg'
                type='submit'
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RecipientDetails;
