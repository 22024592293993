import React from 'react';
import { useActiveShipments } from './useActiveShipments';
import CustomContainer from '../../common/custom/CustomContainer';
import {
  ClientsPanelHeader,
  CustomEmptyDataComponent,
  CustomHeading,
} from '../../common/custom';
import CustomTable from '../../common/custom/CustomTable/custom-table.component';
import { ACTIVE_SHIPMENTS, BILLINGS } from '../../constants/columns';

const ActiveShipments = () => {
  const { data } = useActiveShipments();

  if (data?.length === 0) {
    return (
      <CustomEmptyDataComponent heading='There are no active shipments yet.'>
        <ClientsPanelHeader />
      </CustomEmptyDataComponent>
    );
  }
  return (
    <CustomContainer>
      <ClientsPanelHeader />
      <div className=' border-2 border-[#EAECF0] rounded-[7px]'>
        <CustomContainer className='px-6 min-h-[75px] flex justify-center items-center '>
          <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600]   max-w-[1152px] w-full h-full'>
            Active Shipments
          </CustomHeading>
        </CustomContainer>
        <div className=' overflow-x-auto'>
          <CustomTable
            header={ACTIVE_SHIPMENTS}
            rows={data}
            // table='billing'
          />
        </div>
      </div>
    </CustomContainer>
  );
};

export default ActiveShipments;
