import React from 'react';
import { ViewShipmentDetail } from '../../features/shipments';

const ViewShipmentDetailPage = () => {
  return (
    <>
      <ViewShipmentDetail />
    </>
  );
};

export default ViewShipmentDetailPage;
