import React from "react";
import { nextIcon } from "../../../assets";
import { CustomIcon } from "../../customAssets";
import CustomText from "../CustomText";

const CustomPagination = ({
  handlePrevPage,
  renderPaginationNumbers,
  handleNextPage,
  disableNext,
  disablePrevious,
}) => {
  return (
    <div className="flex items-center border-2 border-[#D0D5DD] rounded-[7px]  ">
      <div
        className="cursor-pointer border-r-2 flex justify-center items-center w-[119px] h-[40px]"
        onClick={handlePrevPage}
      >
        <CustomIcon
          className="rotate-180 max-w-[20px] h-[20px] mr-[8px]"
          icon={nextIcon}
        />
        <CustomText
          className={`font-inter font-[600] ${
            disablePrevious ? "text-[gray]" : "text-[#344054]"
          } text-small-para sm:text-medium-para`}
        >
          Previous
        </CustomText>
      </div>

      <div className="cursor-pointer flex ">{renderPaginationNumbers()}</div>

      <div
        className="cursor-pointer flex justify-center items-center w-[92px] h-[40px]"
        onClick={handleNextPage}
      >
        <CustomIcon
          className="max-w-[20px] h-[20px] mr-[8px] "
          icon={nextIcon}
        />
        <CustomText
          className={`font-inter font-[600] ${
            disableNext ? "text-[gray]" : "text-[#344054]"
          } text-small-para sm:text-medium-para`}
        >
          Next
        </CustomText>
      </div>
    </div>
  );
};

export default CustomPagination;
