import React from "react";
import { noClaims } from "../../../../assets";
import { CustomHeading } from "../../../../common/custom";
import CustomContainer from "../../../../common/custom/CustomContainer";
import { CustomIcon } from "../../../../common/customAssets";

const NoClaimsComponent = ({ children }) => {
  return (
    <div className=" p-1">
      <CustomContainer>
        {children}
        <CustomContainer className="mt-[32px]  min-h-[620px] flex justify-center items-center bg-foundational-white ">
          <CustomContainer className="max-w-[342px] min-h-[355px]  w-full flex flex-col items-center">
            <CustomHeading className="text-small-heading sm:text-medium-heading md:text-large-heading font-roboto font-[500] text-foundation-black ">
              There are no claim yet.
            </CustomHeading>
            <CustomIcon icon={noClaims} className="mt-[40px] " />
          </CustomContainer>
        </CustomContainer>
      </CustomContainer>
    </div>
  );
};

export default NoClaimsComponent;
