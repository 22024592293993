import { useEffect, useState } from 'react';
import { useHookFormSubmission } from '../../../../hook/useHookFormSubmission';
import { signUpStepTwoSchema } from '../../schema/sign-up-step-two';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormDataAction } from '../../../../store';
import {
  FORMAT_PHONE_NUMBER_INPUT,
  ONLY_DIGITS,
} from '../../../../helpers/helpers';

export const useSignUpStepTwo = (increaseStepHandler) => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState();
  const [fieldsFromGoogle, setFieldsFromGoogle] = useState(null);

  const { signUp } = useSelector((state) => state.forms);

  const { handleSubmit, register, errors, trigger, setValue } =
    useHookFormSubmission(signUpStepTwoSchema);

  useEffect(() => {
    setValue(
      'streetAddress',
      fieldsFromGoogle ||
        (signUp.formData.step2 && signUp.formData.step2?.streetAddress)
    );
  }, [fieldsFromGoogle]);

  useEffect(() => {
    setValue(
      'phoneNumber',
      signUp.formData.step2?.phoneNumber
        ? signUp.formData.step2.phoneNumber
        : phoneNumber
    );
    setValue(
      'emailAddress',
      signUp.formData.step2?.emailAddress
        ? signUp.formData.step2.emailAddress
        : ''
    );
    setValue(
      'website',
      signUp.formData.step2?.website ? signUp.formData.step2.website : ''
    );
  }, []);

  const onSubmit = (values) => {
    increaseStepHandler();
    dispatch(updateFormDataAction({ step: 2, values }));
  };

  const handlePhoneNumberChange = (value) => {
    // Remove any non-digit characters
    const cleanedNumber = ONLY_DIGITS(value);

    // Ensure the input adheres strictly to the (XXX) XXX-XXXX pattern
    if (cleanedNumber.length <= 10) {
      // Update the state
      setPhoneNumber(FORMAT_PHONE_NUMBER_INPUT(cleanedNumber));
    }
  };

  const handleGoogleAutoComplete = (address) => {
    setFieldsFromGoogle(address);
  };

  return {
    phoneNumber,
    handlePhoneNumberChange,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setFieldsFromGoogle,
    trigger,
    setValue,
    fieldsFromGoogle,
    handleGoogleAutoComplete,
  };
};
