import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getClaimDetailsQuery,
  updateClaimMutation,
} from '../../../api/services/claim.service';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { PATHS } from '../../../constants/paths';

function UseClaimDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateClaim] = useApi(updateClaimMutation);

  const { setValue } = useHookFormSubmission();

  const [singleClaimDetails, data] = useApi(getClaimDetailsQuery);

  const [claimType, setClaimType] = useState('');
  const [claimValue, setClaimValue] = useState(0);
  const [claimData, setClaimData] = useState(null);
  const [releaseFile, setReleaseFile] = useState(null);
  const [fileError, setFileError] = useState(false);

  useEffect(() => {
    claimDetail();
  }, [id]);

  const claimDetail = async () => {
    const singleClaim = await singleClaimDetails(Number(id));
    setClaimData(singleClaim?.data?.claim);
    setClaimType(
      singleClaim && singleClaim.data.claim && singleClaim.data.claim.status
    );
    setClaimValue(
      singleClaim && singleClaim.data.claim && singleClaim.data.claim.claimValue
    );
    setValue(
      'claimType',
      claimType ||
        (singleClaim && singleClaim.data.claim && singleClaim.data.claim.status)
    );
    setValue(
      'internalNote',
      singleClaim &&
        singleClaim.data.claim &&
        singleClaim.data.claim.internalNote
    );
  };

  const options = [
    { id: 'Claim Received', state: 'Claim Received' },
    { id: 'Under Investigation', state: 'Under Investigation' },
    { id: 'Claim Assessed', state: 'Claim Assessed' },
    { id: 'Pending Documentation', state: 'Pending Documentation' },
    { id: 'Claim Approved', state: 'Claim Approved' },
    { id: 'Claim Denied', state: 'Claim Denied' },
    { id: 'Settlement Offered', state: 'Settlement Offered' },
    { id: 'Claim Closed', state: 'Claim Closed' },
  ];

  const handleUpdateClaim = async (values) => {
    // if (!releaseFile) {
    //   setFileError('File is required');
    //   return;
    // }
    toast.loading('Please wait! Updating the claim...', {
      duration: TOAST_TIME,
    });
    const { data, errors } = await updateClaim({
      claimId: Number(id),
      releasefile: releaseFile
        ? JSON.stringify([releaseFile])
        : claimData?.signedReleaseClaim,
      claimValue: claimValue ? claimValue : claimData?.claimValue,
      bestWayToContact: claimData?.bestWayToContact,
      customerEmail: claimData?.customerEmail,
      customerPhone: claimData?.customerPhone,
      customerNote: claimData?.customerNote,
      description: claimData?.description,
      receipts: claimData?.receipts,
      invoice: claimData?.invoice,
      attachments: claimData?.attachments,
    });
    if (data) {
      toast.success('The claim updated successfully!', {
        duration: TOAST_TIME,
      });
      navigate(PATHS.CLAIMS);
    } else if (errors) {
      toast.error('Something went wrong', { duration: TOAST_TIME });
    }
  };

  const onCancel = () => navigate(PATHS.CLAIMS);

  return {
    claim: data && data.claim,
    claimType,
    options,
    setClaimType,
    handleUpdateClaim,
    claimData,
    onCancel,
    releaseFile,
    setReleaseFile,
    fileError,
    setFileError,
    claimValue,
    setClaimValue,
  };
}

export default UseClaimDetails;
