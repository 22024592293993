import React from "react";
import {
  ClientsPanelHeader,
  CustomDropdown,
  CustomInput,
} from "../../../common/custom";
import CustomContainer from "../../../common/custom/CustomContainer";
import useEditClaim from "./use-edit-claim.component";

const EditClaim = () => {
  const { handleCancel } = useEditClaim();

  return (
    <CustomContainer>
      <ClientsPanelHeader />
      <div
        className="container mx-auto p-4 sm:p-6 lg:p-8 bg-[#FFF] rounded-lg shadow-md"
        style={{
          maxWidth: "996px",
          boxShadow: "rgba(0, 0, 0, 0.11) 0px 1px 9px 0px",
        }}
      >
        <h1 className="text-[#77553D] text-[26px] not-italic font-semibold leading-[normal] sm:text-2xl mb-8 sm:mb-8">
          Edit Claim
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
          <div className="flex flex-col">
            <CustomInput
              label="Recipient"
              className="mb-4 cursor-pointer flex h-12 w-full px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 rounded-md border-[1px] border-[#E7ECF4] bg-[#F8FAFB] shadow-xs"
              id="from"
              placeholder="Enter recipient"
              readOnly={true}
            />
            <div className="flex flex-col mb-4 gap-[6px]">
              <CustomDropdown
                placeholder="Select Type"
                label="Carrier"
                options={[
                  { id: "email", state: "Email" },
                  { id: "phone", state: "Phone" },
                ]}
                name="contact"
              />
            </div>
            <CustomInput
              label="Value"
              className="mb-4 flex h-12 w-full px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 rounded-md border-[1px] border-[#E7ECF4] bg-[#F8FAFB] shadow-xs"
              id="value"
              placeholder="Enter value"
            />
          </div>
          <div className="flex flex-col">
            <CustomInput
              label="From"
              className="mb-4 flex h-12 w-full px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 rounded-md border-[1px] border-[#E7ECF4] bg-[#F8FAFB] shadow-xs"
              id="carrier"
              placeholder="Enter location"
            />
            <div className="flex flex-col mb-4 gap-[6px]">
              <CustomDropdown
                placeholder="Select Status"
                label="Claim Status"
                options={[
                  { id: "email", state: "Email" },
                  { id: "phone", state: "Phone" },
                ]}
                name="contact"
              />
            </div>
            <CustomInput
              label="Filed On"
              className="mb-4 flex h-12 w-full px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 rounded-md border-[1px] border-[#E7ECF4] bg-[#F8FAFB] shadow-xs"
              id="failedOn"
              placeholder="Failed on"
            />
          </div>
        </div>
        <CustomInput
          label="Shipment Type"
          className="flex h-12 w-full px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 rounded-md border-[1px] border-[#E7ECF4] bg-[#F8FAFB] shadow-xs"
          id="shipment"
          placeholder="Enter shipment type"
        />
        <div className="flex justify-end mt-[32px]">
          <div className="flex gap-[32px]">
            <button
              className="flex border border-solid text-[#77553D] text-base not-italic font-medium leading-6 border-[#77553D] w-40 justify-center bg-[ #fff] items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg border-[#77553D]"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="flex text-white text-base not-italic font-medium leading-6 w-40 bg-[#77553d] justify-center items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg"
              type="submit"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </CustomContainer>
  );
};

export default EditClaim;
