import { gql } from '@apollo/client';
import client from '../apollo.';

export const addMemberMutation = async (addMembersData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation CreateEntityMember(
        $createEntityMemberInput: EntityMemberCreateInput!
      ) {
        createEntityMember(createEntityMemberInput: $createEntityMemberInput) {
          id
          firstName
        }
      }
    `,
    variables: {
      createEntityMemberInput: {
        emailAddress: addMembersData.emailAddress,
        additionalNote: '',
        firstName: addMembersData.firstName,
        lastName: addMembersData.lastName,
        password: addMembersData.password,
        phoneNumber: addMembersData.phoneNumber,
        userId: addMembersData.userId,
        entity: {
          connect: {
            id: addMembersData.entityId,
          },
        },
        location: {
          connect: {
            id: addMembersData.locationId,
          },
        },
      },
    },
  });
  return { data, errors };
};

export const getAllMembersQuery = async (entityId) => {
  const { data, errors } = await client.query({
    query: gql`
      query AllEntityMember($allEntityMemberId: Int!) {
        AllEntityMember(id: $allEntityMemberId) {
          emailAddress
          createdAt
          firstName
          role
          id
          phoneNumber
          lastName
        }
      }
    `,
    variables: {
      allEntityMemberId: entityId,
    },
  });
  return { data, errors };
};

export const acceptTermEntityMemberMutation = async (id) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $updateEntityMemberInput: EntityMemberUpdateInput!
        $updateEntityMemberUniqueInput: EntityMemberWhereUniqueInput!
      ) {
        updateEntityMember(
          updateEntityMemberInput: $updateEntityMemberInput
          updateEntityMemberUniqueInput: $updateEntityMemberUniqueInput
        ) {
          id
        }
      }
    `,
    variables: {
      updateEntityMemberInput: {
        termsAccepted: {
          set: true,
        },
      },
      updateEntityMemberUniqueInput: {
        id: id,
      },
    },
  });
  return data;
};

export const removeEntityMember = async (entityMemberId) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation RemoveEntityMember($id: Int!) {
        removeEntityMember(id: $id) {
          additionalNote
          createdAt
          emailAddress
          entityId
          firstName
          id
          lastModified
          lastName
          locationId
          password
          phoneNumber
          resetToken
          role
          termsAccepted
          userId
        }
      }
    `,
    variables: { id: parseInt(entityMemberId) },
  });
  return { data, errors };
};

export const updateEntityMutation = async (updateData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation UpdateEntity(
        $entityWhereUniqueInput: EntityWhereUniqueInput!
        $updateEntityInput: EntityUpdateInput!
      ) {
        updateEntity(
          entityWhereUniqueInput: $entityWhereUniqueInput
          updateEntityInput: $updateEntityInput
        ) {
          id
        }
      }
    `,
    variables: {
      entityWhereUniqueInput: {
        id: updateData.id,
      },
      updateEntityInput: {
        signedPolicyDocument: {
          set: updateData.signedPolicyDocument,
        },
      },
    },
  });
  return { data, errors };
};
