import { useEffect } from 'react';
import {
  getAllShipmentsFromLocationQuery,
  getListPaymentMethodsQuery,
} from '../../../api';
import useApi from '../../../hook/useApi';

import { useSelector } from 'react-redux';
import { storeAllShipmentsAction } from '../../../store';
import { storeAllPaymentsAction } from '../../../store/slices/payments';
import {
  cleanAddressString,
  FORMATE_AMOUNT,
  FORMATE_DATE,
  PARSIFY,
  PREMIUM,
} from '../../../helpers/helpers';

export const useShipments = () => {
  const { shipments } = useSelector((state) => state);
  const [getAllShipmentsFromLocation] = useApi(
    getAllShipmentsFromLocationQuery,
    storeAllShipmentsAction
  );
  const [getListPaymentMethods] = useApi(
    getListPaymentMethodsQuery,
    storeAllPaymentsAction
  );
  const user = JSON.parse(localStorage.getItem('login-user'));

  useEffect(() => {
    user && getAllShipmentsFromLocation(user.locationId);
    fetchPaymentMethodsList();
  }, []);

  const fetchPaymentMethodsList = async () => {
    const { data, errors } = await getListPaymentMethods(
      user.location && user.location.stripeCustomerID
    );
  };



  const modifiedShipmentsData = shipments?.allShipments?.map((shipment) => {
    // console.log('shipment.location.entity:', shipment.location.entity);
    // console.log('country:', PARSIFY(shipment?.to)?.country);
    const parsedFromAddress = PARSIFY(shipment.from.address);
    let addressString = parsedFromAddress?.street +
      ' ' +
      parsedFromAddress?.city +
      ' ' +
      parsedFromAddress?.state +
      ' ' +
      parsedFromAddress?.country +
      ', ' +
      parsedFromAddress?.zipCode;

    const cleanAddress = cleanAddressString(addressString);
    const fromAddress = [
      shipment?.from?.nickName,
      cleanAddress
    ];
    return {
      ...shipment,
      id: shipment.id,
      from: fromAddress,
      recipient: (PARSIFY(shipment.to) && PARSIFY(shipment.to).name) || '---',
      carrier: shipment.carrier && shipment.carrier.name,
      pickup: FORMATE_DATE(shipment?.pickUpDate),
      delivery: FORMATE_DATE(shipment?.deliveryDate),
      value: FORMATE_AMOUNT(shipment.totalValue),
      premium: shipment?.premium ? FORMATE_AMOUNT(shipment.premium) : 'UNPAID',
      confirmation: shipment.confirmationId ? shipment.confirmationId : 'N/A',
      trialed: shipment.subscribers == 'true' ? true : false,
      reference:
        (PARSIFY(shipment.to) && PARSIFY(shipment.to).reference) || '---',
    };
  });

  return {
    data: modifiedShipmentsData,
  };
};
