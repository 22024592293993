import React from 'react';

// custom icon componenet
const CustomIcon = ({
  className = '', // accept custom className prop for custom styling like size or other
  icon, // accept icon as prop
  alt, // accept alt
  clickHandler,
  onLoad,
}) => {
  return (
    <div
      onClick={clickHandler}
      className={className}
    >
      <img
        onLoad={onLoad}
        className='w-[100%] h-[100%]'
        src={icon ? icon : ''}
        alt={alt ? alt : ''}
      />
    </div>
  );
};

export default CustomIcon;
