import React from 'react';
import { CustomIcon } from '../../../../common/customAssets';
import { calendarIcon, infoDarkIcon } from '../../../../assets';
import { FORMATE_DATE } from '../../../../helpers/helpers';

function Details({ claim }) {
  return (
    <div className='flex flex-col justify-center items-start gap-8 border shadow-[0px_1px_9px_0px_rgba(0,0,0,0.11)] p-10 rounded-xl border-solid border-[#EAECF0]'>
      <div className='text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]'>
        Claim Activity
      </div>
      <div className='flex flex-col w-full gap-8'>
        {claim?.claimActivities?.length > 0 ? (
          claim?.claimActivities?.map((activity) => {
            return (
              <div className='flex bg-[#fdfbfd] items-center gap-3 self-stretch shadow-[0px_0px_3px_0px_rgba(0,0,0,0.14)] px-3 py-2 rounded-xl'>
                <div>
                  <CustomIcon
                    icon={infoDarkIcon}
                    alt='info'
                    className='w-[30px] h-[30px]'
                  />
                </div>
                <div className='flex flex-col gap-1'>
                  <div className='text-[#475467] text-lg not-italic font-semibold leading-[normal]'>
                    {activity.subject}
                  </div>
                  <div className='text-[#7D7D7D] text-sm not-italic font-medium leading-[normal]'>
                    {activity.description}
                  </div>
                  <div className='flex gap-3'>
                    <div className='flex gap-1'>
                      <div>
                        <CustomIcon
                          icon={calendarIcon}
                          alt='calender'
                          className='w-4 h-4'
                        />
                      </div>
                      <div className='text-[#7D7D7D] text-sm not-italic font-normal leading-[normal]'>
                        {FORMATE_DATE(
                          activity && activity.createdAt,
                          'dd/mm/yy'
                        ) || '---'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className='text-[#475467] text-lg not-italic font-semibold leading-[normal]'>
            No data found!
          </div>
        )}
      </div>
    </div>
  );
}

export default Details;
