import { createSlice } from "@reduxjs/toolkit";
import { storeAllClaim, addClaim, cancelClaim } from "./claimAction";

const initialState = {
  allClaim: [],
};

const formsSlice = createSlice({
  name: "allClaim",
  initialState,
  reducers: {
    storeAllClaimAction: storeAllClaim,
    addClaimAction: addClaim,
    cancelClaimAction: cancelClaim,
  },
});

export const claimReducer = formsSlice.reducer;
export const { storeAllClaimAction, addClaimAction, cancelClaimAction } =
  formsSlice.actions;
