// const initialState = {
//   signUp: {
//     currentStep: 1,
//     formData: {
//       step1: {
//         nameOfBusiness: '',
//         tin: '',
//         industry: '',
//         nameOfPrincipal: '',
//       },
//       step2: {
//         streetAddress: '',
//         phoneNumber: '',
//         emailAddress: '',
//         website: '',
//       },
//       step3: {
//         lastBusiness: '',
//         referedBy: '',
//         currentInsurancePolicy: '',
//       },
//     },
//   },
// };
export const updateFormData = (state, action) => {
  const { step, values } = action.payload;

  state.signUp.formData[`step${step}`] = values;
};

export const setStep = (state, action) => {
  state.signUp.currentStep = action.payload;
};

export const resetSignUp = (state) => {
  state.signUp.currentStep = 1;
  state.signUp.formData = {};
};

export const updateReportShipmentForm = (state, action) => {
  const { step, values } = action.payload;
  state.reportShipment.formData[`step${step}`] = values;
};

export const setReportShipmentFormStep = (state, action) => {
  state.reportShipment.currentStep = action.payload;
};

export const resetReportShipmentForm = (state) => {
  state.reportShipment.currentStep = 1;
  state.reportShipment.formData = {};
};

export const updateReportClaimForm = (state, action) => {
  const { step, values } = action.payload;
  state.claimFile.formData[`step${step}`] = values;
};

export const setReportClaimFormStep = (state, action) => {
  state.claimFile.currentStep = action.payload;
};

export const resetReportClaimForm = (state) => {
  state.claimFile.currentStep = 1;
  state.claimFile.formData = {};
};
