import React from 'react';
import {
  CustomButton,
  CustomDropdown,
  CustomInput,
  CustomText,
} from '../../../../common/custom';
import { useReportShipmentStepTwo } from './useShipmentStepTwo';
import ReportShipmentCofirmation from '../../../modals/reportShipmentAmoutConfirmation';
import { FILE_TYPE, PREMIUM, REBATE } from '../../../../helpers/helpers';
import CircularILoader from '../../../../common/custom/circularLoader';
import FilePreview from './filePreview/report-file-preview';
import FileUpload from '../../../../common/custom/file-upload/file-upload.component';
import { uploadFileIcon } from '../../../../assets';
import { useLocation } from 'react-router-dom';

const ReportShipmentStepTwo = ({
  increaseStepHandler,
  decreaseStepHandler,
  isTrial,
}) => {
  const { pathname } = useLocation()
  const {
    rate,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    watchedMerchandiseType,
    declaredValueModalClose,
    closeDeclaredValueModal,
    declaredValueModal,
    declaredValue,
    handleDeclaredValue,
    handleUploadAttachmentsCancel,
    handleUploadAttachments,
    trigger,
    attachments,
    isLoading,
    merchandiseTypeOptions,
  } = useReportShipmentStepTwo(increaseStepHandler, isTrial);

  // const merchandiseTypeOptions = [
  //   { id: '1', name: 'New Furniture' },
  //   { id: '2', name: 'Furniture and Accessories' },
  //   { id: '3', name: 'Light Fixtures' },
  //   { id: '4', name: 'Framed Artwork' },
  //   { id: '5', name: 'Sculptures' },
  //   { id: '6', name: 'Stretched Canvases' },
  // ];
  const customHtml = `
  <div class="flex flex-col h-[126px] cursor-pointer items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]">
    <div class="flex flex-col items-center">
    <img src=${uploadFileIcon} alt="upload" class="w-[20px] h-[20px]"/>
    <div class="flex flex-col gap-1 mt-3 items-center">
     <div class="flex gap-1">
     <div class="text-[#B2B7BF] text-sm not-italic font-semibold leading-5">Click to upload</div>
     <div class="text-[#475467] text-sm not-italic font-normal leading-5">or drag and drop</div>
     </div>
      <div class="text-[#475467] text-sm not-italic font-normal leading-5">SVG, PNG, JPG, JPEG, DOC or PDF</div>
    </div>
    </div>
  </div> 
`;
  const merchandiseTypePreValue = merchandiseTypeOptions.find(
    (option) => option.id === watchedMerchandiseType
  );
  return (
    <>
      {declaredValueModal && !declaredValueModalClose ? (
        <ReportShipmentCofirmation
          closeDeclaredValueModal={closeDeclaredValueModal}
          modalHeading='High-Value Item Declaration'
          modalPara='Are there any items in this shipment valued over $50,000?'
        />
      ) : null}

      <div className='stepper-cards container mx-auto p-4 sm:p-6 md:p-8 lg:p-10 bg-white border border-gray-300 rounded-lg shadow-sm w-full sm:w-[450px] md:w-[600px] lg:w-[895px]'>
        <h1 className='text-xl sm:text-2xl font-semibold mb-4 sm:mb-6 text-foundation-brown'>
        Report {pathname.includes('report-test-shipment') ? "Test" : ''} Shipment
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-4'>
            <CustomInput
              name='declaredValue'
              register={register}
              placeholder='Declared value (IN USD)'
              type='number'
              label='Declared Value'
              required
              asterikColor='#E31836'
              errors={errors}
              value={declaredValue}
              trigger={trigger}
              setValue={setValue}
              onChange={(e) => handleDeclaredValue(e.target.value)}
            />
            <p
              className={`text-xs sm:text-sm text-gray-600 ${errors.declaredValue ? 'mt-6' : 'mt-[6px]'
                } `}
            >
              <span className='text-[#E59111] font-inter text-[14px] font-[500] items-center'>
                Premium:{' '}
                <span className='text-[#E59111] text-sm italic font-semibold leading-5'>
                  {isTrial && declaredValue <= 5000
                    ? 0
                    : PREMIUM(
                      isTrial ? declaredValue - 5000 : declaredValue,
                      rate
                    )}
                </span>
              </span>
              <span className='px-1 text-[#2F950B] text-sm italic font-medium leading-5'>
                -
              </span>
              <span className='text-[#2F950B] font-inter text-[14px] font-[500]'>
                Rebate:{' '}
                <span className='text-[#2F950B] text-sm italic font-semibold leading-5'>
                  {isTrial && declaredValue <= 5000
                    ? 0
                    : REBATE(
                      isTrial ? declaredValue - 5000 : declaredValue,
                      rate
                    )}
                  {/* {REBATE(declaredValue, rate)} */}
                </span>
              </span>
            </p>
          </div>
          <div>
            <div className='mb-[6px]'>
              <CustomText>Merchandise Type</CustomText>
            </div>

            <CustomDropdown
              value={merchandiseTypePreValue?.name}
              placeholder='Select merchandise type'
              options={merchandiseTypeOptions}
              setValue={setValue}
              name='merchandiseType'
              errors={errors}
            />
          </div>

          <div className='mt-[20px]'>
            <CustomText>Attachments</CustomText>
          </div>
          <div className='flex justify-center mt-[4px]'>
            <div className='flex gap-[6px] w-full flex-col'>
              {attachments && attachments.length > 0 ? (
                <FilePreview
                  files={attachments}
                  isLoading={isLoading}
                  handleCancel={handleUploadAttachmentsCancel}
                />
              ) : null}

              <FileUpload
                handleChange={handleUploadAttachments}
                fileTypes={FILE_TYPE}
                customHtml={customHtml}
                multiple={true}
              />
            </div>{' '}
          </div>
          <div className='w-full mt-[20px]'>
            <CustomInput
              register={register}
              name='additionalNote'
              placeholder='Shipment / Attachment Notes here...'
              type='text'
              errors={errors}
              label='Shipment / Attachment Notes'
              // asterikColor='#E31836'
              trigger={trigger}
              setValue={setValue}
            />
          </div>
          <div className=' flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
            <CustomButton
              type='button'
              onClick={decreaseStepHandler}
              className='white-bg-btn'
              variant='outline'
              text='Previous'
            />

            <CustomButton className='brown-bg-btn' text='Next' />
          </div>
        </form>
      </div>
    </>
  );
};

export default ReportShipmentStepTwo;
