import React from 'react';
import { noShipments } from '../../../../assets';
import {
  AddPaymentMethodAlertComponent,
  CustomHeading,
} from '../../../../common/custom';
import CustomContainer from '../../../../common/custom/CustomContainer';
import { CustomIcon } from '../../../../common/customAssets';

const NoShipmentsComponent = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('login-user'));
  return (
    <div className=' p-1'>
      <CustomContainer>
        {children}
        <div className=' p-[24px] mt-[32px] bg-foundational-white rounded-[20px]'>
          {user?.entity?.isManualPayment === false && (
            <AddPaymentMethodAlertComponent />
          )}
          <CustomContainer className='  min-h-[620px] flex justify-center items-center  '>
            <CustomContainer className='max-w-[399px] min-h-[355px]  w-full flex flex-col items-center'>
              <CustomHeading className='text-large-heading sm:text-medium-heading md:text-large-heading font-roboto font-[500] text-foundation-black '>
                There are no shipments yet.
              </CustomHeading>
              <CustomIcon icon={noShipments} className='mt-[40px] ' />
            </CustomContainer>
          </CustomContainer>
        </div>
      </CustomContainer>
    </div>
  );
};

export default NoShipmentsComponent;
