import React, { useEffect, useRef } from 'react';
import { CustomText } from '../../../common/custom';

// Define the AddressComponents and GoogleMapAutocompleteProps interfaces here if needed

const GoogleMapAutocomplete = ({
  onChange,
  street,
  setstreet,
  classes,
  isFormatted,
  onPlaceSelect,
  label,
}) => {
  const inputRef = useRef(null);
  let autocomplete;

  useEffect(() => {
    console.log('==>', street);
    if (inputRef.current && window.google) {
      autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ['address'],
          // componentRestrictions: { country: 'us' }, // Limit to US only
        }
      );
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        if (!place.geometry) {
          alert(
            'Invalid address selected. Please choose a valid address from the suggestions.'
          );
          return;
        }
        var newaddress = extractAddressComponents(place);
        const address = place.formatted_address;
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        onPlaceSelect(address || '', latitude, longitude);
        if (isFormatted) {
          onChange(address);
        } else {
          onChange(newaddress);
        }
      });
    }
  }, [onPlaceSelect]);

  function extractAddressComponents(place) {
    const addressComponents = {
      street: '',
      city: '',
      unitNo: '',
      state: '',
      zipCode: '',
      country: '',
    };

    place.address_components?.forEach((component) => {
      if (component.types.includes('street_number')) {
        addressComponents.street = component.long_name;
      } else if (component.types.includes('route')) {
        addressComponents.street += ' ' + component.long_name;
      } else if (component.types.includes('locality')) {
        addressComponents.city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        addressComponents.state = component.short_name;
      } else if (component.types.includes('postal_code')) {
        addressComponents.zipCode = component.long_name;
      }
    });

    return addressComponents;
  }

  return (
    <>
      {label && (
        <CustomText className='mb-[6px] font-inter font-[500] text-[14px] text-[#344054]'>
          {label}
        </CustomText>
      )}
      <input
        ref={inputRef}
        type='text'
        value={street}
        onChange={(event) => (setstreet ? setstreet(event.target.value) : null)}
        placeholder='Street address'
        className='text-[#667085] text-base not-italic font-normal leading-6 flex items-center gap-2 self-stretch border shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-4 py-3 rounded-lg border-solid border-[#E7ECF4] w-[100%] h-[44px] rounded-7 lg:px-[14px] bg-foundation-gray outline-none  '
      />
    </>
  );
};

export default GoogleMapAutocomplete;
