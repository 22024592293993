import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants/paths";
import { useParams } from "react-router-dom";

function useEditClaim() {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleCancel = () => {
    navigate(PATHS.CLAIMS);
  };

  return {
    handleCancel,
  };
}

export default useEditClaim;
