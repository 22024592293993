import * as Yup from 'yup';

export const resetPasswordSchema = Yup.object({
  verificationCode: Yup.string().required('Provide verification code'),
  newPassword: Yup.string().min(8).required('Provide new password'),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword'), null], 'Password must be same'),
});

// { newPassword: '', confirmPassword: '', verificationCode:'' }{ newPassword: '', confirmPassword: '', verificationCode:'' }
