import React from "react";

// custom componeent for display vvalidation error
export const ValidationErrorText = ({
  // custom style props for creating custom styling error text
  className = "h-1 text-red-500 font-[400px] text-sm  mt-1 mb-4", // default styling NOTE (it can be resused without sending classNames, mainly
  // as we have make sure that it is perfect for invalidation text)

  // children  for sending our error text by wrapping in this componenet
  children,
}) =>
  // render custom validation error text with dynamic props and styling
  {
    return <p className={className}>{children}</p>;
  };

export default ValidationErrorText;

// Example of use cases

// example 1
/* <ValidationErrorText>
our validation error text here
</ValidationErrorText> */

// example 2
/* <ValidationErrorText className={className}>
our validation error text here
</ValidationErrorText> */
