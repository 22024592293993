import { gql } from '@apollo/client';
import client from '../apollo.';

export const createSetupIntentMutation = async (customerID) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation CreateSetupIntent($customerId: String!) {
        createSetupIntent(customerID: $customerId) {
          id
          customer
          client_secret
          status
          usage
          created
        }
      }
    `,
    variables: {
      customerId: customerID,
    },
  });
  return data;
};

export const getListPaymentMethodsQuery = async (customerID) => {
  const { data, errors } = await client.query({
    query: gql`
      query listPaymentMethods($customerId: String!) {
        listPaymentMethods(customerID: $customerId) {
          has_more
          data
        }
      }
    `,
    variables: {
      customerId: customerID,
    },
  });
  return { data, errors };
};

export const removePaymentMethodMutation = async (id) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation RemovePaymentMethodMutation($id: String!) {
        removePaymentMethodMutation(id: $id) {
          status
        }
      }
    `,
    variables: {
      id: id,
    },
  });
  return { data, errors };
};
