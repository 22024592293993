import React from 'react';
import CustomTable from '../../../../common/custom/CustomTable/custom-table.component';
import ClaimModal from '../../../../common/custom/Modals/ViewClaimModal/modal';
import { CLAIMS, MANUAL_CLAIMS } from '../../../../constants/columns';
import { DeleteModal } from '../../../modals';
import ClaimsActionMenu from '../claimsActionMenu';
import useAllClaims from './useAllClaims.hook';

const ALlClaimsList = ({ data, isLoading, currentTab }) => {
  const {
    claim,
    actionMenuRef,
    showDeleteModal,
    closeDeleteModal,
    toggleActionMenu,
    openDeleteModal,
    showViewClaimModal,
    openViewClaimModal,
    closeViewClaimModal,
    cancelClaimHandler,
    handleActionClick,
    claimActionClickedId,
    claimActionClickedData,
  } = useAllClaims(isLoading, currentTab);

  return (
    <>
      {showDeleteModal ? (
        <DeleteModal
          modalHeading='Delete Staff Member?'
          modalPara='Are you sure you want to delete this Staff Member? This action cannot be undone.'
          deleteHandler={() => cancelClaimHandler(claimActionClickedId)}
          closeDeleteModal={closeDeleteModal}
        />
      ) : null}

      <ClaimModal
        title='View Claim'
        data={claim}
        show={showViewClaimModal}
        onClose={closeViewClaimModal}
      />

      <CustomTable
        header={currentTab === 'claims' ? CLAIMS : MANUAL_CLAIMS}
        rows={data}
        actionMenuRef={actionMenuRef}
        {...{ handleActionClick, toggleActionMenu }}
        menu={
          <ClaimsActionMenu
            data={claimActionClickedData}
            openDeleteModal={openDeleteModal}
            openViewClaimModal={openViewClaimModal}
          />
        }
      />
    </>
  );
};

export default ALlClaimsList;
