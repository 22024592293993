import React from 'react';
import { CustomButton } from '../../../common/custom';
import { plusGray } from '../../../assets';
import { Link } from 'react-router-dom';

const AddressStaffHeader = ({ heading, butonText, link }) => {
  return (
    <div className='w-full mx-auto'>
      <div className='flex flex-col sm:flex-row  items-start sm:items-center justify-center px-[24px] gap-3 min-h-[88px] py-[20px] md:py-[25px] lg:py-[30px] '>
        <div className='  w-full text-foundation-black font-[600] font-inter text-[16px] sm:text-[22px]'>
          {heading}
        </div>
        <Link
          to={link}
          className=' max-w-[159px] w-full'
        >
          <div>
            {' '}
            <CustomButton
              icon={plusGray}
              className=' bg-foundation-white px-[16px] rounded-[8px] border-[1px] border-[#D0D5DD] h-[40px] flex justify-between items-center text-[14px] font-[500]  gap-[8px]'
              text={butonText}
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AddressStaffHeader;
