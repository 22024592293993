import React from 'react';
import { CSVLink } from 'react-csv';
import { printIcon } from '../../../assets';

const DownloadCSVButton = ({ data, filename }) => {
  const flattenObject = (obj) => {
    const flattened = {};

    const flatten = (path, obj) => {
      if (typeof obj !== 'object' || obj === null) {
        flattened[path.join('.').toUpperCase()] = obj; // Convert headers to uppercase
        return;
      }

      for (const key in obj) {
        flatten([...path, key], obj[key]);
      }
    };

    flatten([], obj);

    return flattened;
  };

  // Flatten the nested objects in the data array
  const flattenedData = data.map(flattenObject);

  // Get the headers from the first object in the flattened data
  const headers = Object.keys(flattenedData[0] || {});

  return (
    <CSVLink
      data={flattenedData}
      headers={headers} // Pass the headers to preserve their order
      filename={filename}
      className='px-[10px] py-[16px] text-[14px] font-bold font-[inter] text-[#101828] flex items-center h-[48px] min-w-[80px] gap-2 text-[#344054] border-2 border-[#D0D5DD] rounded-[7px]'
    >
      <img src={printIcon} className='w-4' /> Print
    </CSVLink>
  );
};

export default DownloadCSVButton;
