import LoginPage from './auth/LoginPage';
import CheckEmailPage from './auth/CheckEmailPage';
import ForgetPasswordPage from './auth/ForgetPasswordPage';
import ResetPasswordPage from './auth/ResetPasswordPage';
import SignUpStepperPage from './auth/SignUpStepperPage';

import ClaimsPage from './claims/ClaimsPage';

import ReportShipmentsPage from './shipments/ReportShipmentsPage';
import ShipmentsPage from './shipments/ShipmentsPage';
import EditShipmentPage from './shipments/EditShipmentPage';
import ViewShipmentDetailPage from './shipments/ViewShipmentDetailPage';
import AddFromShipAddressPage from './ship-from/AddFromShipAddressPage';

import Charity from './charity';

import StaffListPage from './staff/StaffListPage';
import RebateProgramme from './rebate-programme';
import NoClaimRewardProgramme from './no-claim-reward-programme';
import ExtendedWarranty from './extended-warranty';
import BillingsPage from './billings';

import HomePage from './home/HomePage';
import TermsAndCondition from './termsAndCondition';
import RefundPolicy from './refundPolicy';
import ProfilePage from './profile';
import PageNotFound from './PageNotFound';

import FineArtTerms from './fine-art-terms';
import FurnitureTermsAndCondition from './furniture-terms-and-condition';
import DashboardPage from "./dashboard/DashboardPage"

import withAuth from './withAuth';

const ProtectedHomePage = withAuth(HomePage);
const ProtectedBillingPage = withAuth(BillingsPage);
const ProtectedClaimsPage = withAuth(ClaimsPage);
const ProtectedReportShipmentsPage = withAuth(ReportShipmentsPage);
const ProtectedViewShipmentDetailPage = withAuth(ViewShipmentDetailPage);
const ProtectedShipmentsPage = withAuth(ShipmentsPage);
const ProtectedEditShipmentPage = withAuth(EditShipmentPage);
const ProtectedProfilePage = withAuth(ProfilePage);

const ProtectedStaffListPage = withAuth(StaffListPage);
const ProtectedAddFromShipAddressPage = withAuth(AddFromShipAddressPage);

export {
  LoginPage,
  ForgetPasswordPage,
  CheckEmailPage,
  ResetPasswordPage,
  SignUpStepperPage,
  // ClaimsPage,
  ProtectedClaimsPage as ClaimsPage,
  // ShipmentsPage,
  ProtectedShipmentsPage as ShipmentsPage,
  // ReportShipmentsPage,
  ProtectedReportShipmentsPage as ReportShipmentsPage,
  // EditShipmentPage,
  ProtectedEditShipmentPage as EditShipmentPage,
  // ViewShipmentDetailPage,
  ProtectedViewShipmentDetailPage as ViewShipmentDetailPage,
  // AddFromShipAddressPage,
  ProtectedAddFromShipAddressPage as AddFromShipAddressPage,
  // StaffListPage,
  ProtectedStaffListPage as StaffListPage,
  RebateProgramme,
  Charity,
  NoClaimRewardProgramme,
  ExtendedWarranty,
  // BillingsPage,
  ProtectedBillingPage as BillingsPage,
  // HomePage,
  ProtectedHomePage as HomePage,
  TermsAndCondition,
  RefundPolicy,
  // ProfilePage,
  ProtectedProfilePage as ProfilePage,
  PageNotFound,
  FineArtTerms,
  FurnitureTermsAndCondition,
  DashboardPage
};
