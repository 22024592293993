import * as yup from 'yup';

export const reportShipmentStepTwoSchema = yup.object().shape({
  declaredValue: yup
    .number()
    .required('Declared Value is required')
    .typeError('Declared Value is required')
    .positive('Declared Value must be a positive number'),

  merchandiseType: yup.string().required('Merchandise Type is required'),
});

export default reportShipmentStepTwoSchema;
