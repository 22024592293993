import * as yup from 'yup';

export const reportShipmentStepOneSchema = yup.object().shape({
  destination: yup.string().required('Destination is required'),
  streetAddress: yup.string().required('Street Address is required'),
  // city: yup.string().required('City is required'),
  // zipCode: yup
  //   .string()
    // .matches(/^\d{5}$/, "Zip Code must be a valid 5-digit number")
    // .required('Zip Code is required'),
  // state: yup.string().required('State is required'),
});
