import React from 'react';

// custom icon componenet
const CustomImage = ({
  className, // accept custom className prop for custom styling like size or other
  img, // accept img as prop
  alt, // accept alt
}) => {
  return (
    <div className={className}>
      <img
        className='w-full h-full'
        src={img ? img : ''}
        alt={alt ? alt : img}
      />
    </div>
  );
};

export default CustomImage;
