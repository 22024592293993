import * as yup from 'yup';

export const filterBillingSchema = yup.object().shape({
  // entity: yup.string().nullable(),
  status: yup.string().nullable(),
  fromDate: yup.date().nullable().transform(parseDateStringOrNull),
  toDate: yup
    .date()
    .nullable()
    .transform(parseDateStringOrNull)
    .when('fromDate', (fromDate, schema) => {
      return schema.min(
        fromDate ? yup.ref('fromDate') : yup.ref('toDate'),
        'To date must be after from date'
      );
    }),
});

function parseDateStringOrNull(value, originalValue) {
  if (!originalValue) return null; // Return null if value is null or undefined
  const date =
    originalValue instanceof Date ? originalValue : new Date(originalValue);
  return isValidDate(date) ? date : null;
}

function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}
