import React from 'react';
import {
  ClientsPanelHeader,
  CustomButton,
  CustomInput,
} from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import { useAddStaffMember } from './useAddStaffMember';

const AddStaffMember = () => {
  const {
    handleSubmit,
    register,
    onSubmit,
    errors,
    setValue,
    trigger,
    cancelFunctionality,
    phoneNumber,
    handlePhoneNumberChange,
    addMembersLoading,
  } = useAddStaffMember();

  return (
    <CustomContainer>
      <ClientsPanelHeader />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='container mx-auto p-4 sm:p-6 lg:p-8 bg-[#FFF] rounded-lg shadow-md'
        style={{
          maxWidth: '996px',
          boxShadow: 'rgba(0, 0, 0, 0.11) 0px 1px 9px 0px',
        }}
      >
        <h1 className='text-xl sm:text-2xl font-semibold mb-4 sm:mb-6'>
          Add Staff Member
        </h1>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 mb-4'>
          <div>
            <CustomInput
              required
              placeholder='First Name'
              label='First Name'
              register={register}
              name='firstName'
              errors={errors}
              trigger={trigger}
              setValue={setValue}
            />
          </div>
          <div>
            <CustomInput
              required
              placeholder='Last Name'
              label='Last Name'
              register={register}
              name='lastName'
              errors={errors}
              trigger={trigger}
              setValue={setValue}
            />
          </div>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 mb-4'>
          <div>
            <CustomInput
              required
              type='password'
              placeholder='Password'
              label='Password'
              register={register}
              name='password'
              errors={errors}
              trigger={trigger}
              setValue={setValue}
            />
          </div>
          <div>
            <CustomInput
              required
              placeholder='Phone Number'
              label='Phone Number'
              register={register}
              name='phoneNumber'
              errors={errors}
              trigger={trigger}
              setValue={setValue}
            />
          </div>
        </div>
        <div className='w-full  '>
          <CustomInput
            required
            placeholder='Email Address'
            label='Email Address'
            register={register}
            name='emailAddress'
            errors={errors}
            trigger={trigger}
            setValue={setValue}
          />
        </div>

        <div className=' flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
          <CustomButton
            type='button'
            className='white-bg-btn'
            text='Cancel'
            onClick={cancelFunctionality}
          />

          <CustomButton
            className='brown-bg-btn'
            text='Save'
            disabled={addMembersLoading ? true : false}
          />
        </div>
      </form>
    </CustomContainer>
  );
};

export default AddStaffMember;
