import React from 'react';
import GoogleMapAutocomplete from '../google-maps-auto-complete';
// import GoogleMapAutocomplete from "./googleMapAutocomplete";

const AutoCompleteComponent = ({
  onChange,
  street,
  setstreet,
  classes = 'border border-gray-400 rounded-md px-4 text-sm py-2  w-full',
  isFormatted = false,
  label,
}) => {
  const handlePlaceSelect = (address, latitude, longitude) => {};
  return (
    <GoogleMapAutocomplete
      onChange={onChange}
      street={street}
      classes={classes}
      setstreet={setstreet}
      onPlaceSelect={handlePlaceSelect}
      isFormatted={isFormatted}
      label={label}
    />
  );
};

export default AutoCompleteComponent;
