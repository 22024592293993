import * as yup from 'yup';

export const recipientSchema = yup.object().shape({
  filedByName: yup.string().required('Filed By Name is required'),
  phoneNumber: yup.string().required('Phone number is required'),
  // .matches(
  //   /^\+?\d+$/,
  //   'Invalid Phone Number. Only digits and optional + are allowed'
  // ),
});
