import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../../constants/paths";
import { resetReportClaimFormAction } from "../../../../store/slices/formsSlice";

function useShipmentsActionMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClaimFIle = () => {
    dispatch(resetReportClaimFormAction());
    navigate(PATHS.CLAIM_FILE);
  };

  return {
    handleClaimFIle,
  };
}

export default useShipmentsActionMenu;
