import { ceasataStamp } from '../../assets';
export default function NoClaimRewardProgramme() {
  return (
    <div className='mx-auto max-w-[1200px] pt-[50px] px-8'>
      <h1 className='text-4xl font-bold'>No Claim Reward Program</h1>
      <p className='mt-4 text-lg'>
        The No Claim Reward program aims to incentivize and recognize companies
        that consistently deliver goods without filing damage, theft, or loss
        claims, promoting safe and secure transportation.
      </p>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Eligibility:</h2>
        <ul className='mt-4 list-disc pl-5 space-y-2'>
          <li>
            All delivery companies engaged in transportation services are
            eligible.
          </li>
          <li>
            Have no filed claims, successful claim-free deliveries, for the
            eligible month.
          </li>
        </ul>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Eligibility Period:
        </h2>
        <ul className='mt-4 list-disc pl-5 space-y-2'>
          <li>
            The eligibility period is monthly, starting on the 1st and ending on
            the last day of each month.
          </li>
        </ul>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Reward Calculation:
        </h2>
        <ul className='mt-4 list-disc pl-5 space-y-2'>
          <li>
            The reward amount is calculated by applying a 10% rate to the total
            sum of paid premiums for the eligible month.
          </li>
        </ul>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Reward Disbursement:
        </h2>
        <ul className='mt-4 list-disc pl-5 space-y-2'>
          <li>
            Rewards are disbursed as a check on the 15th of the following month.
          </li>
        </ul>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Program Renewal:
        </h2>
        <ul className='mt-4 list-disc pl-5 space-y-2'>
          <li>
            The program may be renewed or modified based on evaluation results
            and stakeholder feedback.
          </li>
          <li>
            Changes to the program will be communicated to participating
            companies.
          </li>
        </ul>
      </div>
      <div className='flex justify-end w-[80%]'>
        <img
          src={`${ceasataStamp}`}
          alt='Stamp'
          width={150}
          className='transform -rotate-45 opacity-40'
        />
      </div>
    </div>
  );
}
