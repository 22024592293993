import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export const CustomScrollToTop = (Component) => {
  const CustomFunction = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return <Component />;
  };
  return CustomFunction;
};
