import { useDispatch, useSelector } from 'react-redux';
import {
  persistor,
  resetAddressSliceAction,
  toggleMobileNavBarAction,
} from '../../../../store';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../../constants/paths';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../../constants/toastNotifications';

export const useMobileNavbar = () => {
  const { ui } = useSelector((state) => state);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const logout = async () => {
    toast.dismiss();
    toast.loading('Logging out...');
    localStorage.removeItem('token');
    navigate(PATHS.DEFAULT_LOGIN);
    //reseting states

    dispatch(resetAddressSliceAction());

    setTimeout(() => {
      persistor.purge();
      persistor.purge();
    }, 200);
    //

    toast.dismiss();
    toast.success('Successfully logged out', { duration: TOAST_TIME });
  };

  const toggleMenu = () => {
    dispatch(toggleMobileNavBarAction());
  };

  return {
    logout,
    toggleMobileNavBarAction,
    mobileNavbarOpen: ui.mobileNavbarOpen,
    toggleMenu,
  };
};
