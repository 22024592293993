import React from 'react';
import { CheckEmail } from '../../features/auth';

const CheckEmailPage = () => {
  return (
    <div>
      <CheckEmail />
    </div>
  );
};

export default CheckEmailPage;
