import { useEffect, useState } from 'react';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import customerDetailsSchema from './schema/customerDetailSchema';

export const useCustomerDetails = (
  data,
  getUpdateCustomerDetailData,
  // closeCustomerDetailModal
) => {
  const [fieldsFromGoogle, setFieldsFromGoogle] = useState(null);

  const { handleSubmit, register, errors, trigger, setValue } =
    useHookFormSubmission(customerDetailsSchema);

  const handleGoogleAutoComplete = (address) => {
    setFieldsFromGoogle(address);
  };

  // const wathchedState = setStateName(selectedState);

  const statePlceholder = () => {
    if (fieldsFromGoogle?.state) {
      return fieldsFromGoogle?.state;
    }
    if (JSON.parse(data.to).state) {
      return JSON.parse(data.to).state;
    }
    return 'Select State';
  };

  useEffect(() => {
    statePlceholder();
    if (fieldsFromGoogle) {
      if (fieldsFromGoogle.state) {
        setValue('state', fieldsFromGoogle.state);
      }
      if (fieldsFromGoogle.unitNo) {
        setValue('unitNumber', fieldsFromGoogle.unitNo);
      }
      if (fieldsFromGoogle.street) {
        setValue('destinationName', fieldsFromGoogle.street);
      }
      if (fieldsFromGoogle.zipCode) {
        setValue('zipCode', fieldsFromGoogle.zipCode);
      }
      if (fieldsFromGoogle.city) {
        setValue('city', fieldsFromGoogle.city);
      }
    }
  }, [fieldsFromGoogle]);

  useEffect(() => {
    let addressTo = data ? JSON.parse(data.to) : '';

    setValue(
      'referenceNumber',
      addressTo?.reference ? addressTo.reference : ''
    );
    setValue('destinationName', addressTo?.name ? addressTo.name : '');
    setValue('unitNumber', addressTo?.unitNo ? addressTo.unitNo : '');
    setValue('zipCode', addressTo?.zipCode ? addressTo.zipCode : '');
    setValue('city', addressTo?.city ? addressTo.city : '');
    setValue('state', addressTo?.state ? addressTo.state : '');
  }, [data]);

  const onSubmit = (values) => {
    getUpdateCustomerDetailData({
      ...values,
      street: fieldsFromGoogle?.street ? fieldsFromGoogle.street : '',
      country: fieldsFromGoogle?.country ? fieldsFromGoogle.country : '',
    });
    // closeCustomerDetailModal();
  };

  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    statePlceholder,
    trigger,
    fieldsFromGoogle,
    handleGoogleAutoComplete,
  };
};
