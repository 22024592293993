import React from 'react';
import CustomIcon from './CustomIcon';
import { tickIcon } from '../../assets';

const CustomCheckCircleIcon = () => {
  return (
    <div className='w-[26px] h-[26px] sm:w-[32px] sm:h-[32px] bg-[#2EB200] flex  justify-center items-center rounded-full w-full'>
      <CustomIcon
        icon={tickIcon}
        className=''
      />
    </div>
  );
};

export default CustomCheckCircleIcon;
