import * as Yup from 'yup';

export const signUpStepTwoSchema = Yup.object({
  streetAddress: Yup.string().required('Street address is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  // .matches(
  //   /^\+?\d+$/,
  //   'Invalid Phone Number. Only digits and optional + are allowed'
  // ),
  emailAddress: Yup.string()
    .required('Email address is required')
    .email('Invalid Email Address'),
});
