import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { uploadFileIcon } from '../../../assets';

function FileUpload({ handleChange, fileTypes, multiple }) {
  const customHtml = `
    <div class="flex flex-col h-[126px] cursor-pointer items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]">
      <div class="flex flex-col items-center">
      <img src=${uploadFileIcon} alt="upload" class="w-[20px] h-[20px]"/>
      <div class="flex flex-col gap-1 mt-3 items-center">
      <div class="flex gap-1">
      <div class="text-[#B2B7BF] text-sm not-italic font-semibold leading-5">Click to upload</div>
      <div class="text-[#475467] text-sm not-italic font-normal leading-5">or drag and drop</div>
      </div>
        <div class="text-[#475467] text-sm not-italic font-normal leading-5">SVG, PNG, JPG, JPEG, DOC or PDF</div>
      </div>
      </div>
    </div> 
  `;

  return (
    <div className='flex flex-col gap-[6px] relative'>
      <FileUploader
        handleChange={handleChange}
        name='file'
        types={fileTypes}
        multiple={multiple}
        children={<div dangerouslySetInnerHTML={{ __html: customHtml }} />}
      />
    </div>
  );
}

export default FileUpload;
