import React from "react";
import CustomContainer from "../../../common/custom/CustomContainer";
import {
  ClientsPanelHeader,
  CustomButton,
  CustomDropdown,
  CustomInput,
  CustomText,
} from "../../../common/custom";
import { useParams } from "react-router-dom";
import { useEditShipmentFromAddress } from "./useEditShipFromAdress";

const EditShipFromAddress = () => {
  const { id } = useParams();
  const { handleSubmit, register, errors, trigger, setValue } =
    useEditShipmentFromAddress(id);
  return (
    <CustomContainer>
      <ClientsPanelHeader />
      <form
        // onSubmit={handleSubmit(onSubmit)}
        className="container mx-auto p-4 sm:p-6 lg:p-8 bg-[#FFF] rounded-lg shadow-md"
        style={{
          maxWidth: "996px",
          boxShadow: "rgba(0, 0, 0, 0.11) 0px 1px 9px 0px",
        }}
      >
        <h1 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6 text-foundation-brown">
          Edit Ship From Address
        </h1>
        <CustomInput
          placeholder="Default Location"
          label="Location Name"
          //   register={register}
          name="locationName"
        />
        <CustomInput
          className="flex h-10 w-full px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 rounded-md border-[1px] border-[#E7ECF4] bg-[#F8FAFB] shadow-xs"
          id="carrier"
          placeholder="Street address"
          label="Street Address"
          //   register={register}
          name="streetAddress"
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
          {/* <div className='flex flex-col sm:flex-row'> */}
          <div>
            <CustomInput
              placeholder="Unit Number"
              label="Unit No#"
              //   register={register}
              name="unitNumber"
            />
          </div>
          <div>
            <CustomInput
              placeholder="City Name"
              label="City"
              //   register={register}
              name="city"
            />
          </div>

          {/* </div> */}
          {/* <div className='flex flex-col'>
        <label
          htmlFor='recipient'
          className='mb-2'
        >
          Recipient
        </label>
        <input
          className='flex h-10 w-full px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 rounded-md border-[1px] border-[#E7ECF4] bg-[#F8FAFB] shadow-xs'
          id='recipient'
          placeholder='Las Vegas-12345'
        />
        <label
          htmlFor='pickup'
          className='mb-2 mt-4'
        >
          Pickup
        </label>
        <input
          className='flex h-10 w-full px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 rounded-md border-[1px] border-[#E7ECF4] bg-[#F8FAFB] shadow-xs'
          id='pickup'
          placeholder='10/26/23'
        />
        <label
          htmlFor='value'
          className='mb-2 mt-4'
        >
          Value
        </label>
        <input
          className='flex h-10 w-full px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 rounded-md border-[1px] border-[#E7ECF4] bg-[#F8FAFB] shadow-xs'
          id='value'
          placeholder='$100.00'
        />
        <label
          htmlFor='confirmationStatus'
          className='mb-2 mt-4'
        >
          Confirmation Status
        </label>
        <button
          type='button'
          role='combobox'
          aria-controls='radix-:r1j:'
          aria-expanded='false'
          aria-autocomplete='none'
          dir='ltr'
          data-state='closed'
          data-placeholder=''
          className='flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50'
          id='confirmationStatus'
        >
          <span style={{ pointerEvents: 'none' }}>Done</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='h-4 w-4 opacity-50'
            aria-hidden='true'
          >
            <path d='m6 9 6 6 6-6'></path>
          </svg>
        </button>
      </div> */}
        </div>

        {/*  */}

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
          {/* <div className='flex flex-col sm:flex-row'> */}
          <div className="mt-[13px]">
            <CustomText>State</CustomText>
            <CustomDropdown
              placeholder="Select State"
              options={[
                { id: "AL", state: "Alabama" },
                { id: "AK", state: "Alaska" },
                { id: "AZ", state: "Arizona" },
                { id: "AR", state: "Arkansas" },
                { id: "CA", state: "California" },
                { id: "CO", state: "Colorado" },
                { id: "CT", state: "Connecticut" },
                { id: "DE", state: "Delaware" },
                { id: "DC", state: "District Of Columbia" },
                { id: "FL", state: "Florida" },
                { id: "GA", state: "Georgia" },
                { id: "HI", state: "Hawaii" },
                { id: "ID", state: "Idaho" },
                { id: "IL", state: "Illinois" },
                { id: "IN", state: "Indiana" },
                { id: "IA", state: "Iowa" },
                { id: "KS", state: "Kansas" },
                { id: "KY", state: "Kentucky" },
                { id: "LA", state: "Louisiana" },
                { id: "ME", state: "Maine" },
                { id: "MD", state: "Maryland" },
                { id: "MA", state: "Massachusetts" },
                { id: "MI", state: "Michigan" },
                { id: "MN", state: "Minnesota" },
                { id: "MS", state: "Mississippi" },
                { id: "MO", state: "Missouri" },
                { id: "MT", state: "Montana" },
                { id: "NE", state: "Nebraska" },
                { id: "NV", state: "Nevada" },
                { id: "NH", state: "New Hampshire" },
                { id: "NJ", state: "New Jersey" },
                { id: "NM", state: "New Mexico" },
                { id: "NY", state: "New York" },
                { id: "NC", state: "North Carolina" },
                { id: "ND", state: "North Dakota" },
                { id: "OH", state: "Ohio" },
                { id: "OK", state: "Oklahoma" },
                { id: "OR", state: "Oregon" },
                { id: "PA", state: "Pennsylvania" },
                { id: "RI", state: "Rhode Island" },
                { id: "SC", state: "South Carolina" },
                { id: "SD", state: "South Dakota" },
                { id: "TN", state: "Tennessee" },
                { id: "TX", state: "Texas" },
                { id: "UT", state: "Utah" },
                { id: "VT", state: "Vermont" },
                { id: "VA", state: "Virginia" },
                { id: "WA", state: "Washington" },
                { id: "WV", state: "West Virginia" },
                { id: "WI", state: "Wisconsin" },
                { id: "WY", state: "Wyoming" },
              ]}
              //   setValue={setValue}
              name="state"
            />
          </div>
          <div>
            <CustomInput
              placeholder="Zip Code"
              label="Zip Code"
              name="zipCode"
              //   register={register}
            />
          </div>
        </div>

        <div className=" flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]">
          <CustomButton type="button" className="white-bg-btn" text="Cancel" />

          <CustomButton className="brown-bg-btn" text="Save" />
        </div>
      </form>
    </CustomContainer>
  );
};

export default EditShipFromAddress;
