import React from 'react';
import { Navigate } from 'react-router-dom';
import { PATHS } from '../../constants/paths';
import { useSelector } from 'react-redux';
const PrivateRoute = ({ children }) => {
  const state = useSelector((state) => state.loginUser);

  const token = JSON.parse(localStorage.getItem('token'));
  let stateToken = state?.token ? JSON.parse(state?.token) : '';

  if (token === stateToken) {
    return children;
  } else {
    return <Navigate to={PATHS.DEFAULT_LOGIN} />;
  }
};

export default PrivateRoute;
