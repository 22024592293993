// Breadcrumbs.js
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { homeIcon } from "../../assets";
import { CustomIcon } from "../customAssets";

const capitalizeFirstLetter = (str) => {
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const isNumeric = (str) => {
  return str && /^\d+$/.test(str);
};

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const shouldRemoveLastSegment =
    pathnames.length > 0 && isNumeric(pathnames[pathnames.length - 1]);
  return (
    <div className="flex items-center bg-foundation-white opacity-90 py-1 px-2">
      <span className="mr-2">
        <Link to="/" className="text-blue-500 flex">
          <CustomIcon icon={homeIcon} />
        </Link>
      </span>
      <span className="mr-1 text-[#D0D5DD]">/</span>

      {pathnames.length === 0 && (
        <span className={`text-gray-600`}>Active Shipments</span>
      )}
      {pathnames.map((name, index) => {
        if (index === pathnames.length - 1 && shouldRemoveLastSegment) {
          return null;
        }
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;

        return (
          <span
            key={name}
            className={`${isLast ? "text-gray-600" : "text-foundation-brown"}`}
          >
            {index > 0 && <span className="ml-1 text-[#D0D5DD]"> / </span>}
            <Link to={routeTo}>{capitalizeFirstLetter(name)}</Link>
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
