import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getManualClaimDetailsQuery,
  updateManualClaimMutation,
} from '../../../api/services/claim.service';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { PATHS } from '../../../constants/paths';

function UseManualClaimDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateClaim] = useApi(updateManualClaimMutation);

  const { setValue } = useHookFormSubmission();

  const [manualClaimDetails, data] = useApi(getManualClaimDetailsQuery);

  const [claimType, setClaimType] = useState('');
  const [claimValue, setClaimValue] = useState(0);
  const [claimData, setClaimData] = useState(null);
  const [releaseFile, setReleaseFile] = useState(null);
  const [fileError, setFileError] = useState(false);

  useEffect(() => {
    claimDetail();
  }, [id]);

  const claimDetail = async () => {
    const manualClaim = await manualClaimDetails(Number(id));

    setClaimData(manualClaim?.data?.manualClaim);
    setClaimType(
      manualClaim &&
        manualClaim?.data?.manualClaim &&
        manualClaim?.data?.manualClaim?.status
    );
    setClaimValue(
      manualClaim && manualClaim.data.claim && manualClaim.data.claim.claimValue
    );
    setValue(
      'claimType',
      claimType ||
        (manualClaim &&
          manualClaim?.data?.manualClaim &&
          manualClaim?.data?.manualClaim?.status)
    );
    setValue(
      'internalNote',
      manualClaim &&
        manualClaim?.data?.manualClaim &&
        manualClaim?.data?.manualClaim?.internalNote
    );
  };

  const options = [
    { id: 'Claim Received', state: 'Claim Received' },
    { id: 'Under Investigation', state: 'Under Investigation' },
    { id: 'Claim Assessed', state: 'Claim Assessed' },
    { id: 'Pending Documentation', state: 'Pending Documentation' },
    { id: 'Claim Approved', state: 'Claim Approved' },
    { id: 'Claim Denied', state: 'Claim Denied' },
    { id: 'Settlement Offered', state: 'Settlement Offered' },
    { id: 'Claim Closed', state: 'Claim Closed' },
    { id: 'Manual Claim Created', state: 'Manual Claim Created' },
  ];

  const handleUpdateClaim = async (values) => {
    // if (!releaseFile) {
    //   setFileError('File is required');
    //   return;
    // }
    toast.loading('Please wait! Updating the claim...', {
      duration: TOAST_TIME,
    });
    const { data, errors } = await updateClaim({
      claimId: Number(id),
      // releasefile: JSON.stringify([releaseFile]),
      releasefile: releaseFile
        ? JSON.stringify([releaseFile])
        : claimData?.signedReleaseClaim,
      claimValue: claimValue ? claimValue : claimData?.claimValue,
      bestWayToContact: claimData?.bestWayToContact,
      customerEmail: claimData?.customerEmail,
      customerPhone: claimData?.customerPhone,
      customerNote: claimData?.customerNote,
      description: claimData?.description,
      receipts: claimData?.receipts,
      invoice: claimData?.invoice,
      attachments: claimData?.attachments,
    });
    if (data) {
      toast.success('The claim updated successfully!', {
        duration: TOAST_TIME,
      });
      navigate(PATHS.CLAIMS);
    } else if (errors) {
      toast.error('Something went wrong', { duration: TOAST_TIME });
    }
  };

  const onCancel = () => navigate(PATHS.CLAIMS);

  return {
    claim: data && data.claim,
    claimType,
    options,
    setClaimType,
    handleUpdateClaim,
    claimData,
    onCancel,
    releaseFile,
    setReleaseFile,
    fileError,
    setFileError,
    claimValue,
    setClaimValue,
  };
}

export default UseManualClaimDetails;
