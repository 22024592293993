import React from 'react';
import { useRecipientDetails } from './use-recipient-detail.hook';
import {
  CustomDropdown,
  CustomInput,
  ValidationErrorText,
} from '../../../../../common/custom';
import TextArea from '../../../../../common/custom/customTextArea';

const RecipientDetails = ({ increaseStepHandler, toBeClaimed }) => {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    contact,
    onCancel,
  } = useRecipientDetails(increaseStepHandler);

  return (
    <div className='stepper-cards'>
      <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
        Enter Recipient Detail
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid gap-4'>
          <div>
            <CustomInput
              register={register}
              name='claimValue'
              placeholder='Claim Value'
              type='number'
              label='Claim Value'
            />
          </div>
          <div>
            <CustomInput
              register={register}
              name='fileNumber'
              placeholder='File Number'
              type='text'
              label='File Number'
            />
          </div>
          <div>
            <CustomInput
              register={register}
              name='phoneNumber'
              placeholder='Recipient’s Phone Number'
              type='text'
              label='Recipient’s Phone Number'
            />
            {errors.phoneNumber ? (
              <ValidationErrorText>
                {errors.phoneNumber.message}
              </ValidationErrorText>
            ) : null}
          </div>
          <div className=''>
            <CustomDropdown
              placeholder='Choose---'
              label='Best Way to contact the Recipient'
              value={contact}
              options={[
                { id: 'Email', state: 'Email' },
                { id: 'Phone', state: 'Phone' },
              ]}
              setValue={setValue}
              name='contact'
            />
            {errors.state ? (
              <ValidationErrorText>{errors.state.message}</ValidationErrorText>
            ) : null}
          </div>

          <div>
            <CustomInput
              register={register}
              name='filedByName'
              type='text'
              label='Filed By Name*'
              errors={errors}
              setValue={setValue}
            />
          </div>
          <div>
            <TextArea
              label='Internal Notes'
              name='internalNote'
              register={register}
              errors={errors}
              placeholder='Internal Notes'
              type='text'
              labelClassName='text-[#344054] text-sm not-italic font-medium leading-5'
            />
          </div>

          <div className='flex justify-end mt-[32px] gap-4'>
            <button
              className='flex border border-solid text-[#77553D] text-base not-italic font-medium leading-6 border-[#77553D] w-40 justify-center bg-[ #fff] items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg border-[#77553D]'
              onClick={onCancel}
            >
              Cancel
            </button>

            <button
              className='flex text-white text-base not-italic font-medium leading-6 w-40 bg-[#77553d] justify-center items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg'
              type='submit'
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RecipientDetails;
