import { gql } from '@apollo/client';
import client from '../apollo.';

export const addAddressMutation = async (addressData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation Mutation($createAddressInput: AddressCreateInput!) {
        createAddress(createAddressInput: $createAddressInput) {
          id
        }
      }
    `,
    variables: {
      createAddressInput: {
        location: {
          connect: {
            id: addressData.locationId,
          },
        },
        address: addressData.address,
        nickName: addressData.nickName,
      },
    },
  });
  return { data, errors };
};

export const removeAddressMutation = async (addressId) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation removeAddress($removeAddressId: Int!) {
        removeAddress(id: $removeAddressId) {
          id
        }
      }
    `,
    variables: {
      removeAddressId: addressId,
    },
  });
  return { data, errors };
};

export const getMyLocationsAddressesQuery = async (locationId) => {
  const { data, errors } = await client.query({
    query: gql`
      query LocationAddresses($locationId: Float!) {
        locationAddresses(locationId: $locationId) {
          createdAt
          nickName
          locationId
          id
          address
        }
      }
    `,
    variables: {
      locationId: locationId,
    },
  });
  return { data, errors };
};

export const fetchCountries = async () => {
  try {
    const response = await fetch(
      'https://countriesnow.space/api/v0.1/countries'
    );
    if (!response.ok) {
      throw new Error('Failed to fetch countries');
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching countries:', error);
    return [];
  }
};

export const fetchStates = async (country) => {
  try {
    if (country != undefined && country != '') {
      const response = await fetch(
        `https://countriesnow.space/api/v0.1/countries/states/q?country=${country}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch states');
      }
      const data = await response.json();
      return data.data?.states || [];
    }
  } catch (error) {
    console.error('Error fetching states:', error);
    return [];
  }
};

export const fetchCities = async (country, state) => {
  try {
    if (
      country != undefined &&
      state != undefined &&
      country != '' &&
      state != ''
    ) {
      const response = await fetch(
        `https://countriesnow.space/api/v0.1/countries/state/cities/q?country=${country}&state=${state}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch cities');
      }
      const data = await response.json();
      let _cities = data.data.map((name) => ({ name }));
      return _cities;
    }
  } catch (error) {
    console.error('Error fetching cities:', error);
    return [];
  }
};
