import React from 'react';
import { CustomButton, CustomDropdown } from '../../../../common/custom';

import CustomDatePicker from '../../../../common/custom/CustomDatePicker';
import { useReportShipmentStepThree } from './useReportShipmentStepThree';
import { useLocation } from 'react-router-dom';

const ReportShipmentStepThree = ({ decreaseStepHandler }) => {
  const { pathname } = useLocation()
  const {
    handleSubmit,
    onSubmit,
    register,
    pickupDate,
    errors,
    setValue,
    reportShipmentCallLoading,
    watchedCarrier,
    carriers,
  } = useReportShipmentStepThree();

  const selectedCarrier =
    // [
    //   { id: 18, name: 'AN Trucking' },
    //   { id: 16, name: 'Bluegrace' },
    //   { id: 17, name: 'CMV Super Trucking' },
    //   { id: 13, name: 'CR Creative' },
    //   { id: 19, name: 'Daylight' },
    //   { id: 7, name: 'Elite Trucking Services' },
    //   { id: 8, name: 'H20 Logistics ' },
    //   { id: 2, name: 'Julio Express' },
    //   { id: 1, name: 'Netbonds' },
    //   { id: 14, name: 'New Penn Mortor Express' },
    //   { id: 5, name: 'Nippon Express' },
    //   { id: 9, name: 'Premier Installations At Linea' },
    //   { id: 3, name: 'Rigos Delivery' },
    //   { id: 21, name: 'ScanGlobal' },
    //   { id: 12, name: 'Total Transportation & Distribution, Inc' },
    //   { id: 10, name: 'Tramo ' },
    //   { id: 20, name: 'West Direct' },
    //   { id: 11, name: 'Wetzel & Son Moving & Storage ' },
    //   { id: 6, name: 'White Glove' },
    //   { id: 15, name: 'YRC Freight' },
    //   { id: 4, name: 'Other' },
    // ]
    carriers.find((carr) => carr.id === watchedCarrier);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='stepper-cards'>
      <h1 className='text-2xl font-semibold mb-6 text-foundation-brown'>
        Report {pathname.includes('report-test-shipment') ? "Test" : ''} Shipment
      </h1>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 mb-4'>
        <div className='flex flex-col rounded-md relative'>
          <CustomDatePicker
            label='Pickup Date'
            name='pickupDate'
            errors={errors}
            register={register}
            min={new Date().toISOString().split('T')[0]}
          />
        </div>

        <div className='flex flex-col rounded-md relative'>
          <CustomDatePicker
            label='Delivery Date'
            name='deliveryDate'
            errors={errors}
            register={register}
            disabled={!pickupDate}
            min={new Date().toISOString().split('T')[0]}
          />
        </div>
      </div>
      <CustomDropdown
        value={selectedCarrier?.name}
        label='Select Carrier'
        placeholder='Select Carrier'
        options={
          carriers
          //   [
          //   { id: 18, name: 'AN Trucking' },
          //   { id: 16, name: 'Bluegrace' },
          //   { id: 17, name: 'CMV Super Trucking' },
          //   { id: 13, name: 'CR Creative' },
          //   { id: 19, name: 'Daylight' },
          //   { id: 7, name: 'Elite Trucking Services' },
          //   { id: 8, name: 'H20 Logistics ' },
          //   { id: 2, name: 'Julio Express' },
          //   { id: 1, name: 'Netbonds' },
          //   { id: 14, name: 'New Penn Mortor Express' },
          //   { id: 5, name: 'Nippon Express' },
          //   { id: 9, name: 'Premier Installations At Linea' },
          //   { id: 3, name: 'Rigos Delivery' },
          //   { id: 21, name: 'ScanGlobal' },
          //   { id: 12, name: 'Total Transportation & Distribution, Inc' },
          //   { id: 10, name: 'Tramo ' },
          //   { id: 20, name: 'West Direct' },
          //   { id: 11, name: 'Wetzel & Son Moving & Storage ' },
          //   { id: 6, name: 'White Glove' },
          //   { id: 15, name: 'YRC Freight' },
          //   { id: 4, name: 'Other' },
          // ]
        }
        setValue={setValue}
        name='carrier'
        errors={errors}
      />

      <div className='flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
        <CustomButton
          type='button'
          onClick={decreaseStepHandler}
          className='white-bg-btn'
          variant='outline'
          text='Previous'
        />

        <CustomButton
          disabled={reportShipmentCallLoading ? true : false}
          className='brown-bg-btn'
          text='Report Shipment'
        />
      </div>
    </form>
  );
};

export default ReportShipmentStepThree;
