import { resetPasswordSchema } from '../schema/reset-password-schema';
import { useNavigate } from 'react-router-dom';

import { PATHS } from '../../../constants/paths';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import useApi from '../../../hook/useApi';
import { resetPasswordMutation } from '../../../api';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';

export const useResetPassword = (email) => {
  const [resetPassword, resetPasswordLoading] = useApi(resetPasswordMutation);

  const navigate = useNavigate();
  const { handleSubmit, register, errors } =
    useHookFormSubmission(resetPasswordSchema);
  const onSubmit = async (values) => {
    toast.dismiss();
    toast.loading('Updating password...');
    const { data, errors } = await resetPassword({ ...values, email });
    if (data.resetPassword) {
      toast.dismiss();
      toast.success('Password updated successfully.', { duration: TOAST_TIME });
      navigate(PATHS.DEFAULT_LOGIN);
    }
    if (!data.resetPassword) {
      toast.dismiss();
      toast.error('Password not reset , check credentials', {
        duration: TOAST_TIME,
      });
    }
  };

  return { handleSubmit, onSubmit, register, errors, resetPasswordLoading };
};
