import { createSlice } from '@reduxjs/toolkit';
import { storeAllStaff } from './staffAction';

const initialState = {
  staffList: [],
};

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    storeAllStaffAction: storeAllStaff,
  },
});

export const staffReducer = staffSlice.reducer;
export const { storeAllStaffAction } = staffSlice.actions;
