import React from 'react';
import { Link } from 'react-router-dom';
import { CustomIcon } from '../../../common/customAssets';
import { ceastaLogo } from '../../../assets';
import { PATHS } from '../../../constants/paths';
import { CustomButton } from '../../../common/custom';

function ThankYouPage() {
  return (
    <div className='flex h-[100vh] w-full justify-center items-center'>
      <div className='flex max-w-[1020px] flex-col items-start shadow-[0px_0px_10px_0px_rgba(0,0,0,0.10)] p-10 rounded-[20px] bg-[#fff]'>
        <div className='flex justify-center items-center mb-[28px]'>
          <Link to={PATHS.DEFAULT_HOME}>
            <CustomIcon
              icon={ceastaLogo}
              className='w-[226px] h-[43px] '
            />
          </Link>
        </div>
        <div className='text-black text-3xl not-italic font-medium leading-[38px]'>
          Thank you for signing up
        </div>
        <div className='mt-4 text-[#878787] text-lg not-italic font-normal leading-[26px]'>
          Thank you for signing up to become a Ceasta Assurance partner. We
          appreciate your decision to join our network of trusted businesses.
          Your partnership is valuable to us, and we look forward to working
          together Our team will be in touch with you shortly to complete the
          onboarding process. In the meantime, please feel free to reach out to
          us at{' '}
          <span className='text-[#878787] text-lg not-italic font-semibold leading-[26px] underline'>
            support@ceasta.com
          </span>
          , We are excited to embark on this partnership with you and look
          forward to serving you soon.
        </div>

        <Link
          to={PATHS.DEFAULT_LOGIN}
          className='mt-8'
        >
          <CustomButton
            className='white-bg-btn hover:bg-foundation-brown hover:text-[white]'
            text='Back'
          />
        </Link>
      </div>
    </div>
  );
}

export default ThankYouPage;
