import React from 'react';
import CustomContainer from '../../../common/custom/CustomContainer';

import SignUpStepperFooter from './SignUpStepperFooter';

const SignUpStepperdWrapper = ({ children }) => {
  return (
    <div className='stepper-cards'>
      <CustomContainer className='flex flex-col items-center '>
        {children}
      </CustomContainer>

      <SignUpStepperFooter />
    </div>
  );
};

export default SignUpStepperdWrapper;
