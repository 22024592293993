import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import ValidationErrorText from '../ValidationErrorText';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: '2px 0',
    borderRadius: '4px',
    border: '1px solid #E7ECF4',
    boxShadow: state.isFocused ? '0 0 0 1px #4A90E2' : 'none',
    '&:hover': {
      border: '1px solid #4A90E2',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#98A2B3',
  }),
};

const CustomDropdown2 = ({
  options,
  setOptions,
  label,
  placeholder,
  name,
  value,
  error,
  setValue,
}) => {
  const [val, setVal] = useState();
  const [isDropdown, setIsDropdown] = useState(value != null);

  const createOption = (label) => ({
    label,
    value: label?.toLowerCase()?.replace(/\W/g, ''),
  });

  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);
    setOptions((prev) => [...prev, newOption]);
    setVal(newOption);
    setValue(newOption?.value);
  };

  useEffect(() => {
    setVal(createOption(value));
  }, [value]);

  return (
    <>
      {label && (
        <label className='text-sm text-[#344054] font-medium mb-2'>
          {label}
        </label>
      )}
      <div className='mt-1'></div>
      {!isDropdown ? (
        <input
          className='text-[#667085] text-base not-italic font-normal leading-6 flex items-center gap-2 self-stretch border shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-4 py-3 rounded-lg border-solid border-[#E7ECF4] w-[100%] h-[44px] rounded-7 lg:px-[14px] bg-foundation-gray outline-none'
          placeholder={value}
          value={value}
          onClick={() => setIsDropdown(true)}
        />
      ) : (
        <CreatableSelect
          isClearable
          options={options}
          styles={customStyles}
          onChange={(v) => {
            setValue(v?.value);
          }}
          onCreateOption={handleCreate}
          value={val}
          defaultValue={{ label: value, value: value }}
        />
      )}
      {!value && error && (
        <div className='mt-1'>
          <ValidationErrorText>{error}</ValidationErrorText>
        </div>
      )}
    </>
  );
};

export default CustomDropdown2;
