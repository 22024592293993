import axios from 'axios';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fileClaimMutation } from '../../../../../api/services/claim.service';
import { HANDLE_FILE_SIZE_CHECK } from '../../../../../helpers/helpers';
import useApi from '../../../../../hook/useApi';
import { PATHS } from '../../../../../constants/paths';
import { TOAST_TIME } from '../../../../../constants/toastNotifications';
import { resetReportClaimFormAction } from '../../../../../store';

function useUploadDocument() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [purchaseFiles, setPurchaseFiles] = useState([]);
  const [pickUpFiles, setPickUpFiles] = useState([]);
  const [damageLossFiles, setDamageLossFiles] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [pickupLoading, setPickUpLoading] = useState(false);
  const [damageLossLoading, setDamageLossLoading] = useState(false);
  const [filesError, setFilesError] = useState(false);

  const recipientDetail = useSelector(
    (state) => state.forms.claimFile.formData.step1
  );

  const describeClaim = useSelector(
    (state) => state.forms.claimFile.formData.step2
  );

  const currentUser = JSON.parse(localStorage.getItem('login-user')); // current logined user

  const [claimFile, loading, error, data] = useApi(fileClaimMutation);

  // const handlePurchaseFile = (file) => {
  //   const document = HANDLE_FILE_SIZE_CHECK(20, file);
  //   setisLoading(true);
  //   toast.loading('loading');
  //   if (document) {
  //     setPurchaseFile(file);
  //     const formData = new FormData();
  //     formData.append('upload', file);

  //     axios
  //       .post('https://api.ceasta.com/fileUpload', formData)
  //       .then((response) => {
  //         setPurchaseFile([...response.data][0]);
  //         setisLoading(false);
  //         toast.dismiss();
  //       })
  //       .catch((error) => {
  //         setisLoading(false);
  //         toast.dismiss();
  //       });
  //   }
  // };

  const handlePurchaseFiles = (files) => {
    try {
      const validFiles = Array.from(files).filter((file) =>
        HANDLE_FILE_SIZE_CHECK(20, file)
      );

      if (validFiles.length === 0) {
        return; // No valid files to upload
      }

      setisLoading(true);
      toast.loading('Files are uploading. Please wait...');

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        formData.append('upload', file);

        return axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => response.data)
          .catch((err) => {
            console.log('Error in uploading the file: ', err);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const successfulUploads = results.filter((result) => result !== null);
          setPurchaseFiles((prevFiles) => [...prevFiles, ...successfulUploads]);
          setisLoading(false);
          toast.dismiss();
        })
        .catch((err) => {
          console.log('Error in uploading files: ', err);
          setisLoading(false);
          toast.dismiss();
        });
    } catch (err) {
      console.log('Error in uploading the files: ', err);
    }
  };

  // const handlePickUpFile = (file) => {
  //   const document = HANDLE_FILE_SIZE_CHECK(20, file);
  //   setPickUpLoading(true);
  //   toast.loading('loading');
  //   if (document) {
  //     setPickUpFile(file);
  //     const formData = new FormData();
  //     formData.append('upload', file);

  //     axios
  //       .post('https://api.ceasta.com/fileUpload', formData)
  //       .then((response) => {
  //         setPickUpFile([...response.data][0]);
  //         setPickUpLoading(false);
  //         toast.dismiss();
  //       })
  //       .catch((error) => {
  //         setPickUpLoading(false);
  //         toast.dismiss();
  //       });
  //   }
  // };
  const handlePickUpFiles = (files) => {
    try {
      const validFiles = Array.from(files).filter((file) =>
        HANDLE_FILE_SIZE_CHECK(20, file)
      );

      if (validFiles.length === 0) {
        return; // No valid files to upload
      }

      setPickUpLoading(true);
      toast.loading('Files are uploading. Please wait...');

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        formData.append('upload', file);

        return axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => response.data)
          .catch((err) => {
            console.log('Error in uploading the file: ', err);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const successfulUploads = results.filter((result) => result !== null);
          setPickUpFiles((prevFiles) => [...prevFiles, ...successfulUploads]);
          setPickUpLoading(false);
          toast.dismiss();
        })
        .catch((err) => {
          console.log('Error in uploading files: ', err);
          setPickUpLoading(false);
          toast.dismiss();
        });
    } catch (err) {
      console.log('Error in uploading the files: ', err);
    }
  };

  // const handleDamageLossFile = (file) => {
  //   const document = HANDLE_FILE_SIZE_CHECK(20, file);
  //   setDamageLossLoading(true);
  //   toast.loading('loading');
  //   if (document) {
  //     setDamageLossFile(file);
  //     const formData = new FormData();
  //     formData.append('upload', file);

  //     axios
  //       .post('https://api.ceasta.com/fileUpload', formData)
  //       .then((response) => {
  //         setDamageLossFile([...response.data][0]);
  //         setDamageLossLoading(false);
  //         toast.dismiss();
  //       })
  //       .catch((error) => {
  //         setDamageLossLoading(false);
  //         toast.dismiss();
  //       });
  //   }
  // };

  const handleDamageLossFiles = (files) => {
    try {
      const validFiles = Array.from(files).filter((file) =>
        HANDLE_FILE_SIZE_CHECK(20, file)
      );

      if (validFiles.length === 0) {
        return; // No valid files to upload
      }

      setDamageLossLoading(true);
      toast.loading('Files are uploading. Please wait...');

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        formData.append('upload', file);

        return axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => response.data)
          .catch((err) => {
            console.log('Error in uploading the file: ', err);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const successfulUploads = results.filter((result) => result !== null);
          setDamageLossFiles((prevFiles) => [
            ...prevFiles,
            ...successfulUploads,
          ]);
          setDamageLossLoading(false);
          toast.dismiss();
        })
        .catch((err) => {
          console.log('Error in uploading files: ', err);
          setDamageLossLoading(false);
          toast.dismiss();
        });
    } catch (err) {
      console.log('Error in uploading the files: ', err);
    }
  };

  // const handlePurchaseCancel = () => {
  //   setPurchaseFile('');
  // };

  // const handlePickUpCancel = () => {
  //   setPickUpFile('');
  // };

  // const handleDamageLossCancel = () => {
  //   setDamageLossFile('');
  // };

  const handlePurchaseCancel = (index) => {
    setPurchaseFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handlePickUpCancel = (index) => {
    setPickUpFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDamageLossCancel = (index) => {
    setDamageLossFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleClaimFIle = async (e) => {
    setFilesError(true);
    e.preventDefault();

    if (purchaseFiles && pickUpFiles && damageLossFiles) {
      const { data } = await claimFile({
        receipts: JSON.stringify([pickUpFiles]),
        invoice: JSON.stringify([purchaseFiles]),
        entityId: Number(currentUser.entityId),
        attachments: JSON.stringify([damageLossFiles]),
        locationId: recipientDetail?.locationId,
        internalNote: recipientDetail?.additionalNote,
        shipmentId: Number(recipientDetail?.shipmentId),
        bestWayToContact: recipientDetail.contact,
        customerEmail: currentUser.emailAddress,
        customerPhone: String(recipientDetail.phoneNumber),
        description: describeClaim.description,
        claimAmount: 0,
        partToBeClaimed: recipientDetail.partToBeClaimed,
        filedByName: recipientDetail.filedByName,
        internalComments: '',
      });
      if (data) {
        navigate(PATHS.CLAIMS);
        setFilesError(false);
        dispatch(resetReportClaimFormAction());
        toast.dismiss();
        toast.success('The Claim filed successfully', { duration: TOAST_TIME });
      } else {
        toast.error('Something went wrong', { duration: TOAST_TIME });
      }
    }
  };

  const onCancel = () => {
    dispatch(resetReportClaimFormAction());
    navigate(-1);
  };

  return {
    purchaseFiles,
    pickUpFiles,
    damageLossFiles,
    handlePurchaseFiles,
    handlePickUpFiles,
    handleDamageLossFiles,
    handlePurchaseCancel,
    handlePickUpCancel,
    handleDamageLossCancel,
    handleClaimFIle,
    isLoading,
    pickupLoading,
    damageLossLoading,
    filesError,
    onCancel,
  };
}

export default useUploadDocument;
