import React, { useEffect, useState } from 'react';
import { CustomInput } from '../../../../common/custom';
import TextArea from '../../../../common/custom/customTextArea';
import CustomDropdown2 from '../../../../common/custom/custom-drop-down/index2';
import {
  extractArrayData,
  FILE_TYPE,
  HANDLE_FILE_SIZE_CHECK,
} from '../../../../helpers/helpers';
import FileUpload from '../../../../common/custom/file-upload/file-upload.component';
import FilePreview from '../../../../common/custom/file-preview/file-preview';
import { TOAST_TIME } from '../../../../constants/toastNotifications';
import toast from 'react-hot-toast';
import { PATHS } from '../../../../constants/paths';
import axios from 'axios';
import CircularILoader from '../../../../common/custom/circularLoader';
import { updateClaimMutation } from '../../../../api/services/claim.service';
import useApi from '../../../../hook/useApi';
import { useNavigate } from 'react-router-dom';

function UpdateClaim({
  claim,
  // claimType,
  // setClaimType,
  options,
  onCancel,
  // claimValue,
  // setClaimValue,
  handleUpdateClaim,
}) {
  const navigate = useNavigate();

  const [updateClaim] = useApi(updateClaimMutation);

  const [bestWayToContact, setBestWayToContact] = useState();
  const [claimValue, setClaimValue] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [customerPhone, setCustomerPhone] = useState();
  const [customerNote, setCustomerNote] = useState();
  const [description, setDescription] = useState();
  const [invoice, setInvoice] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [receipt, setReceipt] = useState([]);
  const [invoiceLoading, setInvoiceLoading] = useState();
  const [attachmentLoading, setAttachmentLoading] = useState();
  const [receiptLoading, setReceiptLoading] = useState();

  useEffect(() => {
    if (claim) {
      setClaimValue(claim.claimValue);
      setBestWayToContact(claim.bestWayToContact);
      setCustomerEmail(claim.customerEmail);
      setCustomerPhone(claim.customerPhone);
      setCustomerNote(claim.customerNote);
      setDescription(claim.description);
      setInvoice(extractArrayData(claim.invoice));
      setAttachment(extractArrayData(claim.attachments));
      setReceipt(extractArrayData(claim.receipts));
    }
  }, [claim]);

  const handleInvoiceUpload = (files) => {
    try {
      const validFiles = Array.from(files).filter((file) =>
        HANDLE_FILE_SIZE_CHECK(20, file)
      );

      if (validFiles.length === 0) {
        return; // No valid files to upload
      }

      setInvoiceLoading(true);
      toast.loading('Files are uploading. Please wait...');

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        formData.append('upload', file);

        return axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => response.data)
          .catch((err) => {
            console.log('Error in uploading the file: ', err);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const successfulUploads = results.filter((result) => result !== null);
          setInvoice((prevFiles) => [...prevFiles, ...successfulUploads]);
          setInvoiceLoading(false);
          toast.dismiss();
        })
        .catch((err) => {
          console.log('Error in uploading files: ', err);
          setInvoiceLoading(false);
          toast.dismiss();
        });
    } catch (err) {
      console.log('Error in uploading the files: ', err);
    }
  };

  const handleAttchmentUpload = (files) => {
    try {
      const validFiles = Array.from(files).filter((file) =>
        HANDLE_FILE_SIZE_CHECK(20, file)
      );

      if (validFiles.length === 0) {
        return; // No valid files to upload
      }

      setAttachmentLoading(true);
      toast.loading('Files are uploading. Please wait...');

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        formData.append('upload', file);

        return axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => response.data)
          .catch((err) => {
            console.log('Error in uploading the file: ', err);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const successfulUploads = results.filter((result) => result !== null);
          setAttachment((prevFiles) => [...prevFiles, ...successfulUploads]);
          setAttachmentLoading(false);
          toast.dismiss();
        })
        .catch((err) => {
          console.log('Error in uploading files: ', err);
          setAttachmentLoading(false);
          toast.dismiss();
        });
    } catch (err) {
      console.log('Error in uploading the files: ', err);
    }
  };

  const handleReceiptUpload = (files) => {
    try {
      const validFiles = Array.from(files).filter((file) =>
        HANDLE_FILE_SIZE_CHECK(20, file)
      );

      if (validFiles.length === 0) {
        return; // No valid files to upload
      }

      setReceiptLoading(true);
      toast.loading('Files are uploading. Please wait...');

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        formData.append('upload', file);

        return axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => response.data)
          .catch((err) => {
            console.log('Error in uploading the file: ', err);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const successfulUploads = results.filter((result) => result !== null);
          setReceipt((prevFiles) => [...prevFiles, ...successfulUploads]);
          setReceiptLoading(false);
          toast.dismiss();
        })
        .catch((err) => {
          console.log('Error in uploading files: ', err);
          setReceiptLoading(false);
          toast.dismiss();
        });
    } catch (err) {
      console.log('Error in uploading the files: ', err);
    }
  };

  const handleInvoiceCancel = (index) => {
    setInvoice((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleAttachmentCancel = (index) => {
    setAttachment((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleReceiptCancel = (index) => {
    setReceipt((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleFileClaim = async (e) => {
    // setFilesError(true);
    e.preventDefault();
    const { data } = await updateClaim({
      claimId: Number(claim?.id),
      claimValue: Number(claimValue),
      bestWayToContact,
      customerEmail,
      customerPhone,
      customerNote,
      description,
      receipts: JSON.stringify(receipt),
      invoice: JSON.stringify(invoice),
      attachments: JSON.stringify(attachment),
      releasefile: claim?.signedReleaseClaim,
    });
    if (data) {
      navigate(PATHS.CLAIMS);
      toast.success('The Claim updated successfully', { duration: TOAST_TIME });
    } else {
      toast.error('Something went wrong', { duration: TOAST_TIME });
    }
  };

  return (
    <form
      className='flex flex-col justify-center gap-8 self-stretch border shadow-[0px_1px_9px_0px_rgba(0,0,0,0.11)] p-10 rounded-xl border-solid border-[#EAECF0]'
      // onSubmit={handleSubmit(handleUpdateClaim)}
    >
      <div className='text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]'>
        Claim Details
      </div>
      <div className='flex flex-col w-full'>
        <div className='mt-4'>
          <CustomInput
            readOnly={claim?.status !== 'Claim Received'}
            placeholder='Claim Value'
            label='Claim Value'
            name='claimValue'
            value={claimValue || (claim && claim.claimValue)}
            onChange={(e) => setClaimValue(e?.target?.value)}
          />
        </div>
        <div className='mt-4'>
          <CustomDropdown2
            placeholder='Best way to contact'
            label='Best Way to contact the Recipient'
            value={bestWayToContact}
            options={[
              { value: 'Email', label: 'Email' },
              { value: 'Phone', label: 'Phone' },
            ]}
            setValue={(val) => setBestWayToContact(val)}
          />
        </div>
        <div className='mt-4'>
          <CustomInput
            readOnly={claim?.status !== 'Claim Received'}
            placeholder='Customer Email'
            label='Customer Email'
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e?.target?.value)}
          />
        </div>
        <div className='mt-4'>
          <CustomInput
            readOnly={claim?.status !== 'Claim Received'}
            placeholder='Customer Phone'
            label='Customer Phone'
            value={customerPhone}
            onChange={(e) => setCustomerPhone(e?.target?.value)}
          />
        </div>
        <TextArea
          readOnly={claim?.status !== 'Claim Received'}
          type='text'
          name='customerNote'
          label='Customer Note'
          labelClassName='mt-6 text-[#344054] text-sm not-italic font-medium leading-5'
          value={customerNote}
          onChange={(e) => setCustomerNote(e.target.value)}
        />
        <TextArea
          readOnly={claim?.status !== 'Claim Received'}
          type='text'
          name='description'
          label='Description'
          labelClassName='mt-6 text-[#344054] text-sm not-italic font-medium leading-5'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className=''>
          <div className='my-2'>
            {claim?.status === 'Claim Received' && (
              <>
                <span className='font-bold'>Invoices:</span>
                {invoice && invoiceLoading ? (
                  <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
                    <CircularILoader />{' '}
                  </div>
                ) : invoice?.length > 0 ? (
                  <>
                    <FilePreview
                      files={invoice}
                      handleCancel={handleInvoiceCancel}
                    />
                  </>
                ) : (
                  <>
                    <FileUpload
                      fileTypes={FILE_TYPE}
                      multiple={true}
                      handleChange={handleInvoiceUpload}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div className=''>
          <div className='my-2'>
            {claim?.status === 'Claim Received' && (
              <>
                <span className='font-bold'>Attachment:</span>
                {attachment && attachmentLoading ? (
                  <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
                    <CircularILoader />{' '}
                  </div>
                ) : attachment?.length > 0 ? (
                  <>
                    <FilePreview
                      files={attachment}
                      handleCancel={handleAttachmentCancel}
                    />
                  </>
                ) : (
                  <>
                    <FileUpload
                      fileTypes={FILE_TYPE}
                      multiple={true}
                      handleChange={handleAttchmentUpload}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className=''>
          <div className='my-2'>
            {claim?.status === 'Claim Received' && (
              <>
                <span className='font-bold'>Receipts:</span>
                {receipt && receiptLoading ? (
                  <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
                    <CircularILoader />{' '}
                  </div>
                ) : receipt?.length > 0 ? (
                  <>
                    <FilePreview
                      files={receipt}
                      handleCancel={handleReceiptCancel}
                    />
                  </>
                ) : (
                  <>
                    <FileUpload
                      fileTypes={FILE_TYPE}
                      multiple={true}
                      handleChange={handleReceiptUpload}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className='flex justify-end gap-4'>
        <button
          type='button'
          className='flex border border-solid text-[#77553D] text-base not-italic font-medium leading-6 border-[#77553D] w-40 justify-center bg-[ #fff] items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg border-[#77553D]'
          onClick={onCancel}
        >
          Cancel
        </button>
        {claim?.status === 'Claim Received' && (
          <button
            className='flex text-white text-base not-italic font-medium leading-6 w-40 bg-[#77553d] justify-center items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg'
            type='button'
            onClick={handleFileClaim}
          >
            Update Claim
          </button>
        )}
      </div>
    </form>
  );
}

export default UpdateClaim;
