import { useEffect } from 'react';
import {
  getAllShipmentsFromLocationQuery,
  getListPaymentMethodsQuery,
} from '../../api';
import useApi from '../../hook/useApi';
import { storeAllShipmentsAction } from '../../store';
import { storeAllPaymentsAction } from '../../store/slices/payments';
import { useSelector } from 'react-redux';
import {
  FORMATE_AMOUNT,
  FORMATE_DATE,
  PARSIFY,
  PREMIUM,
} from '../../helpers/helpers';

export const useActiveShipments = () => {
  const { shipments } = useSelector((state) => state);
  const [getAllShipmentsFromLocation] = useApi(
    getAllShipmentsFromLocationQuery,
    storeAllShipmentsAction
  );
  const [getListPaymentMethods] = useApi(
    getListPaymentMethodsQuery,
    storeAllPaymentsAction
  );

  const user = JSON.parse(localStorage.getItem('login-user'));

  const fetchPaymentMethodsList = async () => {
    const { data, errors } = await getListPaymentMethods(
      user.location && user.location.stripeCustomerID
    );
  };

  const filteredShipments = shipments.allShipments.filter(
    (shipments) => shipments.approved === true
  );

  useEffect(() => {
    user && getAllShipmentsFromLocation(user.locationId);
    fetchPaymentMethodsList();
  }, []);

  const modifiedShipmentsData = filteredShipments?.map((shipment) => {
    return {
      ...shipment,
      id: shipment.id,
      from: shipment.from.nickName,
      recipient: (PARSIFY(shipment.to) && PARSIFY(shipment.to).name) || '---',
      carrier: shipment.carrier && shipment.carrier.name,
      pickup: FORMATE_DATE(shipment?.pickUpDate, 'mm/dd/yy'),
      delivery: FORMATE_DATE(shipment?.deliveryDate, 'mm/dd/yy'),
      value: FORMATE_AMOUNT(shipment.totalValue),
      premium: FORMATE_AMOUNT(shipment.premium),
      // premium: PREMIUM(
      //   shipment.totalValue,
      //   shipment &&
      //     shipment.location &&
      //     shipment.location.entity &&
      //     (PARSIFY(shipment.to)?.country == 'United States' ||
      //       PARSIFY(shipment.to)?.country == 'USA')
      //     ? shipment.location.entity.rate
      //     : shipment.location.entity.internationalRate
      // ),
      confirmation: shipment.confirmationId ? shipment.confirmationId : 'N/A',
      reference:
        (PARSIFY(shipment.to) && PARSIFY(shipment.to).reference) || '---',
    };
  });

  return { data: modifiedShipmentsData };
};
