import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHookFormSubmission } from '../../../../../hook/useHookFormSubmission';
import { recipientSchema } from '../../../../shipments/schema/recipientDetailsSchema';
import { updateReportClaimFormAction } from '../../../../../store';
import { useNavigate } from 'react-router-dom';
import {
  FORMATE_DATE,
  FORMAT_PHONE_NUMBER_INPUT,
  ONLY_DIGITS,
  PARSIFY,
} from '../../../../../helpers/helpers';
import { getAllShipmentsFromLocationQuery } from '../../../../../api';
import useApi from '../../../../../hook/useApi';

export const useRecipientDetails = (increaseStepHandler) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedAddress } = useSelector((state) => state.addresses);
  const { step1 } = useSelector((state) => state.forms.claimFile.formData);
  const user = JSON.parse(localStorage.getItem('login-user'));

  const [phoneNumber, setPhoneNumber] = useState();
  const [claimableShipments, setClaimableShipments] = useState();
  const [selectedShipment, setSelectedShipment] = useState(null);

  const { handleSubmit, register, errors, watch, trigger, setValue } =
    useHookFormSubmission(recipientSchema);

  const { contact } = watch();

  const [getAllShipmentsFromLocation] = useApi(
    getAllShipmentsFromLocationQuery
  );

  useEffect(() => {
    const getData = async () => {
      const response = await getAllShipmentsFromLocation(user.locationId);

      const _claimableShipments = response?.data?.allShipmentFromLocation
        ?.filter((shipment) => shipment?.isDelivered == true && !shipment.claim)
        .map((shipment, index) => {
          return {
            id: shipment.id,
            value: shipment.id,
            totalValue: shipment.totalValue,
            name: `${index + 1}. Confirmation ID: ${
              shipment.confirmationId
            }, Reference: ${PARSIFY(shipment.to)?.reference}, from: ${
              shipment?.from?.nickName
            }(${shipment.location.name}) to: ${
              PARSIFY(shipment.to).name
            }, delivered on ${FORMATE_DATE(shipment?.deliveryDate)}`,
            label: `${index + 1}. Confirmation ID: ${
              shipment.confirmationId
            }, Reference: ${PARSIFY(shipment.to)?.reference}, from: ${
              shipment?.from?.nickName
            }(${shipment.location.name}) to: ${
              PARSIFY(shipment.to).name
            }, delivered on ${FORMATE_DATE(shipment?.deliveryDate)}`,
            location: PARSIFY(shipment.to)?.name,
            additionalNote: shipment.additionalNote,
            locationId: shipment.locationId,
            additionalNote: shipment.additionalNote,
          };
        });
      setClaimableShipments(_claimableShipments);
    };
    if (user) {
      getData();
    }
  }, []);

  // useEffect(() => {
  //   setValue(
  //     'claimAmount',
  //     selectedShipment
  //       ? selectedShipment?.totalValue
  //       : step1 && step1.selectedShipment?.totalValue
  //   );
  // }, [selectedShipment]);

  const parsedData =
    JSON.parse(localStorage.getItem('shipment'))?.to &&
    JSON.parse(JSON.parse(localStorage.getItem('shipment'))?.to);

  const currentUser = JSON.parse(localStorage.getItem('login-user'));

  useEffect(() => {
    setValue('location', parsedData && parsedData.name);
    setValue(
      'phoneNumber',
      FORMAT_PHONE_NUMBER_INPUT(currentUser && currentUser?.phoneNumber) ||
        FORMAT_PHONE_NUMBER_INPUT(step1 && step1.phoneNumber)
    );
    setValue('contact', contact || (step1 && step1.contact));
  }, [step1]);

  const onSubmit = (values) => {
    increaseStepHandler();
    values.shipmentId = selectedShipment?.id;
    values.locationId = selectedShipment?.locationId;
    values.additionalNote = selectedShipment?.additionalNote;
    dispatch(updateReportClaimFormAction({ step: 1, values }));
  };

  const handlePhoneNumberChange = (value) => {
    // Remove any non-digit characters
    const cleanedNumber = ONLY_DIGITS(value);

    // Ensure the input adheres strictly to the (XXX) XXX-XXXX pattern
    if (cleanedNumber.length <= 10) {
      // Update the state
      setPhoneNumber(FORMAT_PHONE_NUMBER_INPUT(cleanedNumber));
    }
  };

  return {
    selectedAddress,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    contact,
    navigate,
    trigger,
    phoneNumber,
    handlePhoneNumberChange,
    claimableShipments,
    setSelectedShipment,
  };
};
