import React from 'react';
import { EditShipment } from '../../features/shipments';

const EditShipmentPage = () => {
  return (
    <>
      <EditShipment />
    </>
  );
};

export default EditShipmentPage;
