import { useStepper } from './useStepper';
import { CustomText } from '../../../../common/custom';

const Stepper = ({ steps, currentStep }) => {
  const { newSteps, stepDesign } = useStepper(currentStep, steps);

  const displayStep = newSteps.map((step, index) => {
    return (
      <div
        key={index}
        className={
          index !== newSteps.length - 1
            ? `w-full flex items-center `
            : 'flex items-center'
        }
      >
        <div className='relative flex flex-col items-center '>
          <div
            className={`rounded-full transition  duration-500 ease-in-out  h-[24px] w-[24px] flex justify-center items-center ${
              step.highlighted && step.selected
                ? stepDesign.completed
                : step.highlighted && step.selected && !step.completed
                ? stepDesign.inProgress
                : stepDesign.notReached
            }`}
          >
            {step.completed ? (
              <span className='bg-foundation-brown w-full h-full rounded-full flex justify-center items-center text-sm text-white'>
                &#10003;
              </span>
            ) : (
              <span className='rounded-full bg-white h-[8px] w-[8px]'></span>
            )}
          </div>
          <CustomText
            className={`absolute top-0 mt-[36px] text-small-para font-[600]  w-[129px] flex justify-center  ${
              step.selected ? 'text-foundation-brown' : 'text-para-black'
            }`}
          >
            {step.description}
            {/* Display Descrption */}
          </CustomText>
        </div>
        <div
          className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
            step.completed ? 'border-foundation-brown' : ''
          }`}
        >
          {/* Display Line */}
        </div>
      </div>

      //
    );
  });

  return (
    <div className='px-4 mt-8 flex justify-between items-center max-w-[621px] mx-auto'>
      {displayStep}
    </div>
  );
};

export default Stepper;
