import { useEffect, useState } from 'react';
import { allShipmentFromEntityQuery } from '../../api/services/shipments.service';
import useApi from '../../hook/useApi';
import {
  FORMATE_AMOUNT,
  FORMATE_DATE,
  PARSIFY,
  PREMIUM,
} from '../../helpers/helpers';
import { useHookFormSubmission } from '../../hook/useHookFormSubmission';
import { filterBillingSchema } from './schema/filterBillingSchema';

export const useBilling = () => {
  const [getALlBillingCall, billingLoading, billingError, billingData] = useApi(
    allShipmentFromEntityQuery
  );

  const { handleSubmit, register, reset, errors, setValue } =
    useHookFormSubmission(filterBillingSchema);

  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isFilterCleared, setIsFilterCleared] = useState(true);

  const user = JSON.parse(localStorage.getItem('login-user'));

  useEffect(() => {
    getALlBillingCall(user.entityId);
  }, []);

  useEffect(() => {
    if (isFilterCleared === true) {
      const modifiedBillingData = billingData?.allShipmentFromEntity.map(
        (shipment) => {
          return {
            // ...shipment,
            id: shipment.id,
            from: shipment.from.nickName,
            recipient:
              (PARSIFY(shipment.to) && PARSIFY(shipment.to).name) || '---',
            carrier: shipment.carrier && shipment.carrier.name,
            pickup: FORMATE_DATE(shipment?.pickUpDate, 'mm/dd/yy'),
            reported: FORMATE_DATE(shipment?.createdAt, 'mm/dd/yy'),
            delivery: FORMATE_DATE(shipment?.deliveryDate, 'mm/dd/yy'),
            value: FORMATE_AMOUNT(shipment.totalValue),
            premium: PREMIUM(
              shipment.totalValue,
              shipment &&
                shipment.location &&
                shipment.location.entity &&
                (PARSIFY(shipment.to)?.country == 'United States' ||
                  PARSIFY(shipment.to)?.country == 'USA')
                ? shipment.location.entity.rate
                : shipment.location.entity.internationalRate
            ),
            confirmation: shipment.confirmationId
              ? shipment.confirmationId
              : 'N/A',
            status: shipment.isDelivered ? 'Delivered' : 'InTransit',
            reward: shipment.paid
              ? FORMATE_AMOUNT(
                  shipment.totalValue * shipment.location.entity.rate * 0.2
                )
              : '---',
          };
        }
      );

      setFilteredData(modifiedBillingData);
    }
  }, [billingData, isFilterCleared]);

  const handleOpenFilterModal = () => setShowFilterModal(true);
  const handleCloseFilterModal = () => setShowFilterModal(false);
  const handleFilterSubmit = (filters) => {
    setFilteredData([]);
    const { status, fromDate, toDate } = filters;
    const formatFrom = fromDate ? FORMATE_DATE(fromDate, 'mm/dd/yy') : null;
    const formatTo = toDate ? FORMATE_DATE(toDate, 'mm/dd/yy') : null;

    // console.log(formatFrom, formatTo);
    let _filteredData = billingData?.allShipmentFromEntity.filter((bill) => {
      const isStatusMatch =
        status && status?.toLowerCase() !== 'both'
          ? status?.toLowerCase() === 'delivered'
            ? bill.isDelivered
            : !bill.isDelivered
          : bill;

      const isStartDateMatch = formatFrom
        ? FORMATE_DATE(bill.createdAt, 'mm/dd/yy') >= formatFrom
        : bill;
      const isEndDateMatch = formatTo
        ? FORMATE_DATE(bill.createdAt, 'mm/dd/yy') <= formatTo
        : bill;
      console.log(isStatusMatch && isStartDateMatch && isEndDateMatch);

      return isStatusMatch && isStartDateMatch && isEndDateMatch;
    });

    // console.log(_filteredData);
    const filteredBilling = _filteredData.map((bill) => {
      return {
        ...bill,
        from: bill.from.nickName,
        recipient: (PARSIFY(bill.to) && PARSIFY(bill.to).name) || '---',
        carrier: bill.carrier && bill.carrier.name,
        to: (PARSIFY(bill.to) && PARSIFY(bill.to).name) || '---',
        confirmation: bill.confirmationId,
        reported: FORMATE_DATE(bill.createdAt),
        pickupDate: FORMATE_DATE(bill.pickUpDate),
        deliveryDate: FORMATE_DATE(bill.deliveryDate),
        status: bill.isDelivered ? 'Delivered' : 'InTransit',
        value: FORMATE_AMOUNT(bill.totalValue),
        premium: PREMIUM(
          bill.totalValue,
          bill &&
            bill.location &&
            bill.location.entity &&
            (PARSIFY(bill.to)?.country == 'United States' ||
              PARSIFY(bill.to)?.country == 'USA')
            ? bill.location.entity.rate
            : bill.location.entity.internationalRate
        ),
        paid: bill.paid,
        reward: bill.paid
          ? FORMATE_AMOUNT(bill.totalValue * bill.location.entity.rate * 0.2)
          : '---',
      };
    });

    // localStorage.setItem(
    //   'formFilter',
    //   JSON.stringify({
    //     entity: entity,
    //     status: status,
    //     fromDate: fromDate,
    //     toDate: toDate,
    //   })
    // );

    // if (filteredBilling.length === 0) {
    //   toast.dismiss();
    //   toast.error('Oooops! No Data Found.', {
    //     duration: TOAST_TIME,
    //   });
    // }

    setFilteredData(filteredBilling);
    setIsFilterCleared(false);
    setShowFilterModal(false);
  };

  const handleFilterReset = () => {
    reset();
    setFilteredData([]);
    handleCloseFilterModal();
  };

  // return { data: modifiedBillingData, loading: billingLoading };
  return {
    data: filteredData,
    loading: billingLoading,
    showFilterModal,
    handleOpenFilterModal,
    handleCloseFilterModal,
    handleFilterSubmit,
    handleFilterReset,
    handleSubmit,
    register,
    errors,
    setValue,
    isFilterCleared,
    setIsFilterCleared,
  };
};
