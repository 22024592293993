import React from 'react';

const PageNotFound = () => {
  return (
    <div className='mx-auto max-w-[1200px] pt-[50px] px-4'>
      <h1 className='text-5xl font-bold leading-tight text-gray-900'>
        Page Not Found
      </h1>
      <p className='mt-6 text-lg text-gray-700'>
        The page you are looking for does not exist.
      </p>
    </div>
  );
};

export default PageNotFound;
