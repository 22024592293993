import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ClientsPanelHeader,
  CustomButton,
  CustomDropdown,
  CustomInput,
} from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import CustomDatePicker from '../../../common/custom/CustomDatePicker';
import { PATHS } from '../../../constants/paths';
import {
  BEAUTIFY_ADDRESS,
  FILE_TYPE,
  PREMIUM,
  REBATE,
} from '../../../helpers/helpers';
import { CustomersDetailModal } from '../../modals';
import { useEditShipment } from './useEditShipment';
import { useCustomerDetails } from '../../modals/customer-details-modal/useCustomerDetails';
import AutoCompleteComponent from '../../components/auto-complete';
import FilePreview from './edit-shipment-attachments';
import { uploadFileIcon } from '../../../assets';
import FileUpload from '../../../common/custom/file-upload/file-upload.component';
import _ from 'lodash';

import {
  fetchCountries,
  fetchStates,
  fetchCities,
} from '../../../api/services/address.service';
import CustomDropdown2 from '../../../common/custom/custom-drop-down/index2';

const EditShipment = () => {
  const {
    rate,
    data,
    handleSubmit,
    onSubmit,
    register,
    errors,
    trigger,
    setValue,
    closeCustomerDetailModal,
    openCustomerDetailModal,
    getUpdateCustomerDetailData,
    streetPlaceholder,
    customerDetailModalOpen,
    declaredValue,
    handleDeclaredValue,
    navigate,
    updateLoading,
    watch,
    fieldsFromGoogle,
    setFieldsFromGoogle,
    handleGoogleAutoComplete,
    isLoading,
    handleUploadAttachments,
    documents,
    handleUploadAttachmentsCancel,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedCountry,
    selectedState,
    selectedCity,
    merchandiseTypeOptions,
    carriers,
    isValueEditable,
    fromAddresses,
    selectedFromAddress,
    setLocation,
  } = useEditShipment();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const countryValue = watch('country');
  let stateValue = watch('state');
  const cityValue = watch('city');
  const [resetState, setResetState] = useState(null);

  const parsedToAddress = JSON.parse(data?.to);

  useEffect(() => {
    let addressTo = data ? JSON.parse(data.to) : '';
    setValue(
      'referenceNumber',
      addressTo?.reference ? addressTo.reference : ''
    );
    setValue('destinationName', addressTo?.name ? addressTo.name : '');
    setValue('streetAddress', addressTo?.street ? addressTo?.street : '');
    setFieldsFromGoogle(addressTo?.street ? addressTo?.street : '');
    setValue('unitNumber', addressTo?.unitNo ? addressTo.unitNo : '');
    setValue('zipCode', addressTo?.zipCode ? addressTo.zipCode : '');
    setValue(
      'city',
      addressTo?.city ? addressTo.city : parsedToAddress?.city ?? ''
    );
    setValue(
      'state',
      addressTo?.state ? addressTo.state : parsedToAddress?.state ?? ''
    );
    setValue(
      'country',
      addressTo?.country ? addressTo.country : parsedToAddress?.country ?? ''
    );
    setSelectedCountry(addressTo?.country);
    setSelectedState(addressTo?.state);
    setSelectedCity(addressTo?.city);
    setValue(
      'additionalNote',
      data?.additionalNote ? data?.additionalNote : ''
    );
    setValue('documents', JSON.stringify(documents));

    const _fetchCountries = async () => {
      try {
        let _countries = await fetchCountries();

        _countries = _countries.map((item) => ({
          label: item.country,
          value: item.country,
        }));
        const existingCountry = JSON.parse(data.to)?.country;
        _countries.push({ label: existingCountry, value: existingCountry });
        setCountries(_countries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
    _fetchCountries();
  }, [data, documents]);

  useEffect(() => {
    const _fetchStates = async () => {
      try {
        let _states = await fetchStates(
          selectedCountry ? selectedCountry : countryValue
        );
        _states = _states.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        const existingState = JSON.parse(data.to)?.state;
        _states.push({ label: existingState, value: existingState });
        // console.log('_states:', _states);
        setStates(_states);
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    };
    setResetState(null);
    _fetchStates();
  }, [countryValue, selectedCountry]);

  useEffect(() => {
    setResetState(selectedState ? selectedState : stateValue);
  }, [stateValue, selectedState]);

  useEffect(() => {
    const _fetchCities = async () => {
      try {
        if (resetState != null) {
          let _cities = await fetchCities(
            selectedCountry ? selectedCountry : countryValue,
            selectedState ? selectedState : stateValue
          );
          _cities = _cities.map((item) => ({
            label: item.name,
            value: item.name,
          }));
          const existingCity = JSON.parse(data.to)?.city;
          _cities.push({ label: existingCity, value: existingCity });
          setCities(_cities);
        }
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };
    _fetchCities();
  }, [countryValue, resetState, selectedCountry, selectedState]);

  const customHtml = `
  <div class="flex flex-col h-[126px] cursor-pointer items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]">
    <div class="flex flex-col items-center">
    <img src=${uploadFileIcon} alt="upload" class="w-[20px] h-[20px]"/>
    <div class="flex flex-col gap-1 mt-3 items-center">
     <div class="flex gap-1">
     <div class="text-[#B2B7BF] text-sm not-italic font-semibold leading-5">Click to upload</div>
     <div class="text-[#475467] text-sm not-italic font-normal leading-5">or drag and drop</div>
     </div>
      <div class="text-[#475467] text-sm not-italic font-normal leading-5">SVG, PNG, JPG, JPEG, DOC or PDF</div>
    </div>
    </div>
  </div> 
`;

  return (
    <CustomContainer>
      <ClientsPanelHeader />
      <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
        <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
          Edit Shipment
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='items-center grid grid-cols-1 sm:grid-cols-2 gap-[16px] mb-[22px] sm:mb-[16x]'>
            <div className='w-full sm:col-span-2'>
              <CustomInput
                placeholder={parsedToAddress.name}
                label='Destination'
                name='destinationName'
                register={register}
                errors={errors}
                trigger={trigger}
                setValue={setValue}
              />
            </div>

            <div>
              <CustomInput
                name='streetAddress'
                placeholder={parsedToAddress.street}
                label='Street Address'
                register={register}
                errors={errors}
                trigger={trigger}
                setValue={setValue}
              />
            </div>
            <div>
              <CustomDropdown2
                label='Country*'
                placeholder={parsedToAddress?.country}
                options={countries}
                name='country'
                errors={errors}
                setValue={setSelectedCountry}
                value={selectedCountry}
              />
            </div>
            <div className=''>
              <CustomDropdown2
                label='State*'
                placeholder={parsedToAddress?.state}
                options={states}
                name='state'
                errors={errors}
                setValue={setSelectedState}
                value={selectedState}
              />
            </div>
            <div>
              <CustomDropdown2
                label='City*'
                placeholder={parsedToAddress?.state}
                options={cities}
                name='city'
                errors={errors}
                setValue={setSelectedCity}
                value={selectedCity}
              />
            </div>
            <div>
              <CustomInput
                name='zipCode'
                placeholder={parsedToAddress.zipCode}
                label='Zip Code'
                register={register}
                errors={errors}
                trigger={trigger}
                setValue={setValue}
              />
            </div>
          </div>
          <div>
            <CustomDropdown
              label='From Address*'
              placeholder='Select From Address'
              options={fromAddresses}
              // error={errFromAddress}
              setValue={setLocation}
              value={selectedFromAddress?.name}
            />
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
            <div>
              <CustomInput
                register={register}
                name='unitNumber'
                placeholder={parsedToAddress.unitNo}
                label='Unit Number'
                errors={errors}
                trigger={trigger}
                setValue={setValue}
              />
            </div>
            <div>
              <div>
                <CustomInput
                  placeholder={parsedToAddress.reference}
                  label='Reference'
                  register={register}
                  name='referenceNumber'
                  errors={errors}
                  trigger={trigger}
                  setValue={setValue}
                />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-[15px]'>
            <div className='mt-[5px]'>
              <CustomDropdown
                label='Merch Type'
                placeholder={data?.shipmentType.name}
                options={merchandiseTypeOptions}
                setValue={setValue}
                name='merchandiseType'
              />
            </div>
            <div className='md:mt-[5px]'>
              <div>
                <CustomDropdown
                  label='Select Mover'
                  placeholder={data?.carrier?.name}
                  options={carriers}
                  name='carrier'
                  setValue={setValue}
                />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-[15px]'>
            <div className=''>
              <div className='flex rounded-md relative'>
                <CustomDatePicker
                  label='Pickup Date'
                  name='pickupDate'
                  errors={errors}
                  register={register}
                />
              </div>
            </div>
            <div>
              <div className='flex rounded-md relative'>
                <CustomDatePicker
                  label='Delivery Date'
                  name='deliveryDate'
                  errors={errors}
                  register={register}
                />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-[15px]'>
            <div className='mb-4'>
              <CustomInput
                name='declaredValue'
                register={register}
                placeholder='Declared value (IN USD)'
                type='number'
                label='Declared Value'
                value={declaredValue}
                errors={errors}
                trigger={trigger}
                setValue={setValue}
                readOnly={!isValueEditable}
                onChange={(e) => handleDeclaredValue(e.target.value)}
              />

              <p
                className={`text-xs sm:text-sm text-gray-600 ${
                  errors.declaredValue ? 'mt-6' : 'mt-2'
                } `}
              >
                <span className='text-[#2F950B] font-inter text-[14px] font-[500] items-center'>
                  Premium:{' '}
                  <span className='text-[#2F950B] text-sm italic font-semibold leading-5'>
                    {PREMIUM(declaredValue, rate)}
                  </span>
                </span>
                <span className='px-1 text-[#E59111] text-sm italic font-medium leading-5'>
                  -
                </span>
                <span className='text-[#E59111] font-inter text-[14px] font-[500]'>
                  Rebate:{' '}
                  <span className='text-[#E59111] text-sm italic font-semibold leading-5'>
                    {REBATE(declaredValue, rate)}
                  </span>
                </span>
              </p>
            </div>

            <div className='mt-[5px]'>
              <CustomInput
                label='Confirmation Id'
                readOnly={true}
                value={data?.confirmationId || '---'}
                // setValue={setValue}
                name='confirmationId'
              />
            </div>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='mb-4'>
              <CustomInput
                name='paymentStatus'
                readOnly={true}
                // placeholder="Payment Status"
                // type="number"
                label='Payment Status'
                value={data?.paid === true ? 'Paid' : 'Pending'}
              />
            </div>

            <div className='mt-[]'>
              <CustomInput
                label='Shipment Status'
                readOnly={true}
                value={data?.approved === true ? 'Approved' : 'Pending'}
                // setValue={setValue}
                name='shipmentStatus'
              />
            </div>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
            <div className='mb-4'>
              <CustomInput
                name='additionalNote'
                register={register}
                placeholder={data?.additionalNote || '---'}
                type='text'
                label='Shipment / Attachment Notes'
                errors={errors}
                trigger={trigger}
                setValue={setValue}
              />
            </div>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
            {documents && documents.length > 0 ? (
              <FilePreview
                files={documents}
                isLoading={isLoading}
                handleCancel={handleUploadAttachmentsCancel}
              />
            ) : null}

            <FileUpload
              handleChange={handleUploadAttachments}
              fileTypes={FILE_TYPE}
              customHtml={customHtml}
              multiple={true}
            />
          </div>

          <div className=' flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
            <CustomButton
              type='button'
              onClick={() => {
                navigate(PATHS.SHIPMENTS);
              }}
              className='bg-none rounded-[8px] border-[1px] border-foundation-brown  text-foundation-brown max-w-[160px] w-full h-[48px]'
              variant='outline'
              text='Cancel'
            />

            <CustomButton
              className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
              text='Update'
              disabled={updateLoading ? true : false}
            />
          </div>
        </form>
      </div>
    </CustomContainer>
  );
};

export default EditShipment;
