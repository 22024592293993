// import * as yup from "yup";

// export const reportShipmentStepThreeSchema = yup.object().shape({
//   pickupDate: yup
//     .date()
//     .nullable()
//     .required("Pickup date is required"),
//     // .min(new Date(), "Pickup date must be a future date"),
//   deliveryDate: yup
//     .date()
//     .nullable()
//     .required("Delivery date is required")

//     // .when("pickupDate", (pickupDate, schema) => {
//     //   return pickupDate
//     //     ? schema.min(pickupDate, "Delivery date must be after pickup date")
//     //     : schema;
//     // }),
//     .test(
//       "pickup-before-delivery",
//       "Delivery date must be after pickup date",
//       function (value) {
//         const { pickupDate } = this.parent; // `this.parent` refers to the entire object being validated
//         return !pickupDate || value > pickupDate;
//       }
//     ),
//   carrier: yup.string().required("Please select carrier"),
// });
import * as yup from 'yup';

export const reportShipmentStepThreeSchema = yup.object().shape({
  pickupDate: yup
    .date()
    .nullable()
    .required('Pickup date is required'),
    // .min(
    //   new Date(new Date().setDate(new Date().getDate() - 1)),
    //   'Pickup date must be of yesterday or not much older'
    // ),
  deliveryDate: yup
    .date()
    .nullable()
    .required('Delivery date is required')
    .when('pickupDate', (pickupDate, schema) => {
      return pickupDate
        ? schema.min(pickupDate, 'Delivery date must be after pickup date')
        : schema;
    }),
  carrier: yup.string().required('Please select carrier'),
});
