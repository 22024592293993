import React from 'react';
import {
  AutoCompleteComponent,
  CustomButton,
  CustomInput,
  ValidationErrorText,
} from '../../../../common/custom';
import CustomContainer from '../../../../common/custom/CustomContainer';
import { useSignUpStepTwo } from './useSignUpStepTwo';

const SignUpStepTwo = ({ increaseStepHandler, decreaseStepHandler }) => {
  const {
    phoneNumber,
    handlePhoneNumberChange,
    handleSubmit,
    onSubmit,
    register,
    errors,
    fieldsFromGoogle,
    handleGoogleAutoComplete,
    trigger,
    setValue,
  } = useSignUpStepTwo(increaseStepHandler);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='flex flex-col my-8 gap-6 w-full'
    >
      <div className='flex flex-col sm:flex-row justify-center gap-6'>
        <CustomContainer className='w-full'>
          <AutoCompleteComponent
            setValue={setValue}
            trigger={trigger}
            isFormatted={true}
            register={register}
            errors={errors}
            label='Address'
            name='streetAddress'
            address={fieldsFromGoogle}
            onChange={handleGoogleAutoComplete}
            required
          />
        </CustomContainer>
        <CustomContainer className='w-full'>
          <CustomInput
            required
            name='phoneNumber'
            register={register}
            placeholder='Enter your phone number'
            label='Phone number'
            // value={phoneNumber}
            errors={errors}
            trigger={trigger}
            setValue={setValue}
            // onChange={(e) => handlePhoneNumberChange(e.target.value)}
          />
          <ValidationErrorText></ValidationErrorText>
        </CustomContainer>
      </div>
      <div className='flex flex-col sm:flex-row gap-6'>
        <CustomContainer className='w-full'>
          <CustomInput
            required
            name='emailAddress'
            register={register}
            placeholder='Enter your email address'
            label='Email address'
            errors={errors}
            trigger={trigger}
            setValue={setValue}
          />
        </CustomContainer>
        <CustomContainer className='w-full'>
          <CustomInput
            name='website'
            register={register}
            placeholder='Enter your website'
            label='Website(optional)'
            errors={errors}
            trigger={trigger}
            setValue={setValue}
          />
        </CustomContainer>
      </div>

      <div className='flex flex-col sm:flex-row justify-center mt-2 max-w-[349px] gap-3 mx-auto '>
        <CustomButton
          onClick={() => decreaseStepHandler()}
          type='button'
          text='Back'
          className='white-bg-btn'
        />
        <CustomButton
          text='Next'
          className='brown-bg-btn'
        />
      </div>
    </form>
  );
};

export default SignUpStepTwo;
