import React from 'react';
import { uploadFileIcon } from '../../../../../assets';
import CircularILoader from '../../../../../common/custom/circularLoader';
import FilePreview from '../../../../../common/custom/file-preview/file-preview';
import FileUpload from '../../../../../common/custom/file-upload/file-upload.component';
import { FILE_TYPE } from '../../../../../helpers/helpers';
import useUploadDocument from './use-upload-document';

const UploadDocument = ({ decreaseStepHandler }) => {
  const {
    purchaseFiles,
    pickUpFiles,
    damageLossFiles,
    handlePurchaseFiles,
    handlePickUpFiles,
    handleDamageLossFiles,
    handlePurchaseCancel,
    handlePickUpCancel,
    handleDamageLossCancel,
    handleClaimFIle,
    isLoading,
    pickupLoading,
    damageLossLoading,
  } = useUploadDocument(decreaseStepHandler);

  const customHtml = `
    <div class="flex flex-col h-[126px] cursor-pointer items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]">
      <div class="flex flex-col items-center">
      <img src=${uploadFileIcon} alt="upload" class="w-[20px] h-[20px]" />
      <div class="flex flex-col gap-1 mt-3 items-center">
       <div class="flex gap-1">
       <div class="text-[#B2B7BF] text-sm not-italic font-semibold leading-5">Click to upload</div>
       <div class="text-[#475467] text-sm not-italic font-normal leading-5">or drag and drop</div>
       </div>
        <div class="text-[#475467] text-sm not-italic font-normal leading-5">SVG, PNG, JPG, JPEG or PDF</div>
      </div>
      </div>
    </div> 
  `;

  return (
    <div className='stepper-cards mb-10'>
      <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
        Upload Document
      </h1>
      <form>
        <div className='grid gap-4'>
          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#344054] text-sm not-italic font-medium leading-5'>
              Upload proof of purchase, such as an invoice with a maximum file
              size of 20MB.*
            </div>
            {purchaseFiles && isLoading ? (
              <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
                <CircularILoader />{' '}
              </div>
            ) : purchaseFiles?.length > 0 ? (
              <FilePreview
                files={purchaseFiles}
                handleCancel={handlePurchaseCancel}
              />
            ) : (
              <FileUpload
                handleChange={handlePurchaseFiles}
                fileTypes={FILE_TYPE}
                customHtml={customHtml}
                multiple={true}
              />
            )}
          </div>

          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#344054] text-sm not-italic font-medium leading-5'>
              Upload proof of pick-up date and time, such as a delivery order,
              for the shipment.*
            </div>
            {pickUpFiles && pickupLoading ? (
              <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
                <CircularILoader />{' '}
              </div>
            ) : pickUpFiles?.length > 0 ? (
              <FilePreview
                files={pickUpFiles}
                handleCancel={handlePickUpCancel}
              />
            ) : (
              <FileUpload
                handleChange={handlePickUpFiles}
                fileTypes={FILE_TYPE}
                customHtml={customHtml}
                multiple={true}
              />
            )}
          </div>

          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#344054] text-sm not-italic font-medium leading-5'>
              Upload proof of damage or loss, such as photos or a signed
              delivery receipt*
            </div>
            {damageLossFiles && damageLossLoading ? (
              <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
                <CircularILoader />{' '}
              </div>
            ) : damageLossFiles?.length > 0 ? (
              <FilePreview
                files={damageLossFiles}
                handleCancel={handleDamageLossCancel}
              />
            ) : (
              <FileUpload
                handleChange={handleDamageLossFiles}
                fileTypes={FILE_TYPE}
                customHtml={customHtml}
                multiple={true}
              />
            )}
          </div>

          <div className='flex justify-end mt-[32px]'>
            <div className='flex gap-[32px]'>
              <button
                className='flex border border-solid text-[#77553D] text-base not-italic font-medium leading-6 border-[#77553D] w-40 justify-center bg-[ #fff] items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg border-[#77553D]'
                onClick={() => decreaseStepHandler()}
              >
                Previous
              </button>
              <button
                className='flex text-white text-base not-italic font-medium leading-6 w-40 bg-[#77553d] justify-center items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg'
                onClick={handleClaimFIle}
              >
                File Claim
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UploadDocument;
