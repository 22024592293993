import React from 'react';
import { deleteIcon } from '../../../../assets';
import { CustomButton } from '../../../../common/custom';

const ShipFromActionMenu = ({ openDeleteModal, editPagePath, data, upper }) => {
  // const [editDatta, setEditData] = useState(null);
  // useEffect(() => {
  //   setEditData(data);
  // }, []);
  return (
    <>
      <div
        className={`w-[202px] min-h-[50px] z-10 shadow-md  bg-foundational-white  absolute ${
          upper ? 'top-[-40px]' : 'top-[30px]'
        } right-[40px] rounded-[8px] flex flex-col gap-[10px] items-center justify-center border-[1px] border-solid border-[#EAECF0]   `}
      >
        {/* <CustomContainer className='flex gap-[8px] max-w-[172px] w-full  '>
          <CustomIcon icon={editIcon} />
          <CustomText className='font-inter font-[500]'>
            <Link
              // to={{
              //   pathname: `${PATHS.EDIT_SHIPMENT}/${data.id}`,
              //   state: data,
              // }}
              to={`${PATHS.EDIT_SHIP_FROM_ADDRESS}/${data.id}`}
            >
              Edit
            </Link>
          </CustomText>
        </CustomContainer> */}

        {/* <CustomContainer className='flex gap-[8px] items-center px-[8px] cursor-pointer   bg-red-800  w-full   min-h-[40px] '> */}
        {/* <CustomIcon icon={deleteIcon} /> */}
        <div
          className='flex gap-[8px] items-center px-[8px] cursor-pointer  w-full   min-h-[40px] bg-hover'
          onClick={openDeleteModal}
        >
          <CustomButton
            type='button'
            onClick={openDeleteModal}
            icon={deleteIcon}
            text='Delete'
            className=' font-inter font-[500] flex gap-2'
          />
        </div>
        {/* </CustomContainer> */}
      </div>
    </>
  );
};

export default ShipFromActionMenu;
