import React from 'react';

import { CustomText } from '../../../common/custom';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../constants/paths';

const SignUpStepperFooter = () => {
  return (
    <div className='flex justify-center flex-col items-center'>
      <div className='flex items-center'>
        <CustomText as='p'>Already have account ?</CustomText>
        <Link to={PATHS.DEFAULT_LOGIN}>
          <CustomText
            as='p'
            className='text-small-para text-foundation-brown font-roboto font-[600] ml-1 '
          >
            Login
          </CustomText>
        </Link>
      </div>
    </div>
  );
};

export default SignUpStepperFooter;
