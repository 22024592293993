import { useNavigate } from 'react-router-dom';
import { forgetPasswordSchema } from '../schema/forget-password';

import { PATHS } from '../../../constants/paths';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import useApi from '../../../hook/useApi';
import { forgetPasswordMutation } from '../../../api';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';

export const useForgetPassword = () => {
  const [forgetPassword, forgetPasswordLoading] = useApi(
    forgetPasswordMutation
  );
  const navigate = useNavigate();
  const { handleSubmit, register, trigger, setValue, errors } =
    useHookFormSubmission(forgetPasswordSchema);
  const onSubmit = async (values) => {
    toast.dismiss();
    toast.loading('Sending verification code to your email... Please wait');
    const { errors } = await forgetPassword(values);

    if (!errors) {
      toast.dismiss();
      toast.success('Verification code sent to your email. Check your inbox', {
        duration: TOAST_TIME,
      });
      navigate(`${PATHS.RESET_PASSWORD}?email=${values.email}`);
    }
    if (errors) {
      toast.dismiss();
      toast.error('Error sending verification code. Please try again later', {
        duration: TOAST_TIME,
      });
    }
  };

  return {
    handleSubmit,
    onSubmit,
    register,
    trigger,
    errors,
    setValue,
    forgetPasswordLoading,
  };
};
