import React, { useState } from "react";
import { Tooltip } from "@material-tailwind/react";
import { crossCircle, pdfIcon, wordIcon } from "../../../assets";
import { CustomIcon } from "../../../common/customAssets";
import PreviewFullImageModal from "../../../common/custom/Modals/PreviewFullImage/modal";
import useFilePreview from "../../../common/custom/file-preview/use-file-preview.hook";
import { FILE_SIZE_B_TO_MB } from "../../../helpers/helpers";

function FilePreview({ files, handleCancel }) {
  const { handleFullScreenPreview } = useFilePreview();
  const [openModalIndex, setOpenModalIndex] = useState(-1);

  const handleCloseModal = () => {
    setOpenModalIndex(-1);
  };

  const handleOpenModal = (index) => {
    setOpenModalIndex(index);
  };

  return (
    <div className="flex max-h-[126px] items-center justify-center gap-4 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]">
      {files && files.map((file, index) => (
        <div className="flex flex-col relative" key={index}>
          <PreviewFullImageModal
            title="View Document"
            show={openModalIndex === index}
            file={file}
            onClose={handleCloseModal}
          />
          {file.location?.split(".").pop() === "pdf" ? (
            <div className="flex items-center gap-4 p-2 rounded-lg bg-[#efefef]">
              <img
                src={pdfIcon}
                alt="file"
                className="h-10 hover:cursor-pointer"
                onClick={() => handleOpenModal(index)}
              />
              <div className="">
                <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[150px] text-[#1D3250] text-sm not-italic font-semibold leading-6">
                  {file?.originalname}
                </div>
                <div className="text-[#747474] text-xs not-italic font-normal leading-[normal]">
                  {FILE_SIZE_B_TO_MB(file.size)}MB
                </div>
              </div>
            </div>
          ) : (file.location?.split(".").pop() === "docx" || file.location?.split(".").pop() === "doc") ? (
            <div className="flex items-center gap-4 p-2 rounded-lg bg-[#efefef]">
              <img
                src={wordIcon}
                alt="file"
                className="h-10 hover:cursor-pointer"
                onClick={() => handleOpenModal(index)}
              />
              <div className="">
                <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[150px] text-[#1D3250] text-sm not-italic font-semibold leading-6">
                  {file?.originalname}
                </div>
                <div className="text-[#747474] text-xs not-italic font-normal leading-[normal]">
                  {FILE_SIZE_B_TO_MB(file.size)}MB
                </div>
              </div>
            </div>
          ) : (
            <img
              src={file?.location}
              alt="file"
              className="h-[80px] hover:cursor-pointer"
              onClick={() => handleOpenModal(index)}
            />
          )}
          <Tooltip className="" content="Cancel">
            <div
              className="absolute right-[-8px] top-[-12px]"
              onClick={() => handleCancel(file)}
            >
              <CustomIcon icon={crossCircle} className="w-6 h-6" />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
}

export default FilePreview;
