import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgetUserIdMutation, loginUser } from '../../../api';
import { PATHS } from '../../../constants/paths';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';

import { loginSchema } from '../schema/login';

export const useLogin = () => {
  const navigate = useNavigate();

  const [login, loginLoading] = useApi(loginUser);
  const [forgetUserIdCall, forgetUserIdLoading] = useApi(forgetUserIdMutation);

  const { handleSubmit, register, errors, trigger, setValue } =
    useHookFormSubmission(loginSchema);

  // useEffect(() => {
  //   dispatch(resetSignUpAction());
  // }, []);

  const onSubmit = async (values) => {
    toast.dismiss();
    toast.loading('loading');

    // const { data, errors } = await login({
    //   username: values.email,
    //   password: values.password,
    // });

    const { data, errors } = await forgetUserIdCall({
      email: values.email,
      password: values.password,
    });
    if (data) {
      toast.dismiss();
      toast.success('You have successfully logged in', {
        duration: TOAST_TIME,
      });

      localStorage.setItem('token', JSON.stringify(data.login.access_token));
      localStorage.setItem('login-user', JSON.stringify(data.login.user));
      navigate(PATHS.HOME);
    }
    if (errors) {
      toast.error(
        'Login unsuccessful. Please check your credentials or contact admin',
        { duration: TOAST_TIME }
      );
    }
  };

  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    trigger,
    setValue,
    loginLoading,
  };
};
