import toast from 'react-hot-toast';
import { addAddressMutation, getMyLocationsAddressesQuery } from '../../../api';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import { storeAllAddressesAction } from '../../../store';
import addShipFromAddressSchema from '../schema/add-ship-from-address.schema';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../constants/paths';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { useEffect, useMemo, useState } from 'react';
import {
  fetchCities,
  fetchCountries,
  fetchStates,
} from '../../../api/services/address.service';

export const useAddShipFromAddress = () => {
  const navigate = useNavigate();
  const [fieldsFromGoogle, setFieldsFromGoogle] = useState(null);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState('United States');
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [errCountry, setErrCountry] = useState(null);
  const [errState, setErrState] = useState(null);
  const [errCity, setErrCity] = useState(null);

  const [getMyLocationsAddressesCall] = useApi(
    getMyLocationsAddressesQuery,
    storeAllAddressesAction
  );

  const [addAddressCall, addAddressLoading, addAddressError, addResponseData] =
    useApi(addAddressMutation);

  const user = JSON.parse(localStorage.getItem('login-user'));

  const { handleSubmit, register, errors, trigger, setValue, watch } =
    useHookFormSubmission(addShipFromAddressSchema);

  useEffect(() => {
    setValue('streetAddress', fieldsFromGoogle);
  }, [fieldsFromGoogle]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let _countries = await fetchCountries();

        _countries = _countries.map((item) => ({
          label: item.country,
          value: item.country,
        }));

        setCountries(_countries);
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let _states = await fetchStates(selectedCountry);
        _states = _states.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        setStates(_states);
        setSelectedState(null);
        setSelectedCity(null);
      } catch (error) {}
    };

    fetchData();
  }, [selectedCountry]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let _cities = await fetchCities(selectedCountry, selectedState);
        _cities = _cities.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        setCities(_cities);
      } catch (error) {}
    };

    fetchData();
    setSelectedCity();
  }, [
    useMemo(
      () => [selectedCountry, selectedState],
      [selectedCountry, selectedState]
    ),
  ]);

  const onSubmit = async (values) => {
    if (selectedCountry == '' || selectedCountry == null) {
      setErrCountry('Country is required ');
      return;
    }
    if (selectedState == '' || selectedState == null) {
      setErrState('State is required ');
      return;
    }
    if (selectedCity == '' || selectedCity == null) {
      setErrCity('City is required ');
      return;
    }
    toast.dismiss();
    toast.loading('Adding address! Please wait...');
    const address = {
      street: values.streetAddress,
      unitNo: values.unitNumber,
      zipCode: values.zipCode,
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
    };

    const { data, errors } = await addAddressCall({
      locationId: user.locationId,
      nickName: values.locationName,
      address: JSON.stringify(address),
    });
    if (data) {
      const user = JSON.parse(localStorage.getItem('login-user'));
      toast.dismiss();
      toast.success('Address added successfully', { duration: TOAST_TIME });
      navigate(PATHS.SHIP_FROM);
      getMyLocationsAddressesCall(user.locationId);
    }
    if (errors) {
      toast.error('Failed to add address', { duration: TOAST_TIME });
    }
  };

  const handleGoogleAutoComplete = (address) => {
    setFieldsFromGoogle(address);
  };

  return {
    handleSubmit,
    register,
    onSubmit,
    errors,
    setValue,
    fieldsFromGoogle,
    handleGoogleAutoComplete,
    trigger,
    countries,
    setCountries,
    states,
    setStates,
    cities,
    setCities,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedCountry,
    selectedState,
    selectedCity,
    errCountry,
    errState,
    errCity,
  };
};
