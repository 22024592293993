import React from 'react';
import { Link } from 'react-router-dom';
import { CustomButton } from '../../../../common/custom';
import { plusGray } from '../../../../assets';

const PaymentMethodListHeader = ({ heading, butonText, link }) => {
  return (
    <div>
      <div className='flex flex-col sm:flex-row  items-start sm:items-center justify-center   px-[24px] gap-2 h-[88px]'>
        <div className='  w-full text-foundation-black font-[600] font-inter text-[16px] sm:text-[22px]'>
          {heading}
        </div>
        <Link
          to={link}
          className=' max-w-[207px] w-full'
        >
          <CustomButton
            icon={plusGray}
            className=' bg-foundation-white  px-[16px]  w-full rounded-[8px] border border-[1px]  border-[#D0D5DD] h-[40px] flex justify-between items-center text-[14px] font-[500]  gap-3'
            text={butonText}
          />
        </Link>
      </div>
    </div>
  );
};

export default PaymentMethodListHeader;
