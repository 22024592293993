import React, { useEffect, useState } from "react";

const CustomCheckbox = ({ checkboxText, getTermsAndConditionValue }) => {
  const [value, setValue] = useState(false);
  useEffect(() => {
    getTermsAndConditionValue(value);
  }, [value]);
  const changeHandler = (e) => {
    setValue((prevState) => !prevState);
  };
  return (
    <label className="flex items-center gap-[8px]">
      <input
        onChange={(e) => changeHandler(e)}
        value={value}
        type="checkbox"
        className="rounded border border-solid border-gray-600 "
      />
      <div className="text-[#464646] text-sm not-italic font-medium leading-5 font-roboto">
        {/* {checkboxText} */}I accept{" "}
        <a
          className="underline mr-1"
          target="_blank"
          href="https://ceasta.com/terms-conditions.php"
          rel="noreferrer"
        >
          {" "}
          terms and condition
        </a>
        and{" "}
        <a
          className="underline"
          target="_blank"
          href="https://ceasta.com/cookies-policy.php"
          rel="noreferrer"
        >
          privacy policy
        </a>
      </div>
    </label>
  );
};

export default CustomCheckbox;
