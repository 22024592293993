import { ceasataStamp } from '../assets';
export default function RefundPolicy() {
  return (
    <div className='mx-auto max-w-[1200px] pt-[50px] px-4'>
      <h1 className='text-[32px] font-bold'>Refund Policy</h1>

      <p className='mt-6 text-[16px]'>
        At Ceasta Assurance, we are committed to providing affordable insurance
        premiums to all our customers. To achieve this, we have implemented a
        non-refundable policy that helps keep our costs low.
      </p>
      <p className='mt-6 text-[16px]'>
        Upon confirmation of insurance, our system sets up an intent to charge.
        This means that if you need to make any modifications or cancellations,
        please edit them in the portal within 24 hours. Once the intent period
        has passed, we will charge the payment method on file. In case of any
        unforeseen issues that may arise after confirmation and/or before
        shipment, you will receive a refund minus a credit card fee of 3%.
      </p>
      <p className='mt-6 text-[16px]'>
        We strongly encourage our partner merchants to monitor their shipment
        records through our portal to avoid paying for unused premiums. By
        closely monitoring your shipments, you can ensure that you only pay for
        the necessary insurance coverage. Once the shipment has been delivered,
        there are no refunds.
      </p>
      <p className='mt-6 text-[16px]'>
        We understand that unexpected situations can arise, but our
        non-refundable policy is necessary to continue offering competitive
        premiums. We appreciate your understanding and thank you for choosing
        Ceasta Assurance for your protection.
      </p>

      <div className='flex justify-end w-[80%]'>
        <img
          src={`${ceasataStamp}`}
          alt='Stamp'
          width={150}
          className='transform -rotate-45 opacity-40'
        />
      </div>
    </div>
  );
}
