import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../../api';
import { PATHS } from '../../../constants/paths';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import {
  persistor,
  resetSignUpAction,
  storeLoginUserAction,
} from '../../../store';
import { loginSchema } from '../schema/login';

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, loginLoading] = useApi(loginUser);

  const { handleSubmit, register, errors, trigger, setValue } =
    useHookFormSubmission(loginSchema);

  useEffect(() => {
    localStorage.clear();
    dispatch(resetSignUpAction());
    setTimeout(() => {
      persistor.purge();
      persistor.purge();
    }, 200);
  }, []);

  const onSubmit = async (values) => {
    toast.dismiss();
    toast.loading('Logging in. Please wait...');
    try {
      const { data, errors } = await login({
        username: values.email,
        password: values.password,
      });
      // console.log('loginUserData ', data);
      if (data) {
        toast.dismiss();
        toast.success('Successfully logged in to system!', {
          duration: TOAST_TIME,
        });

        localStorage.setItem('token', JSON.stringify(data.login.access_token));
        dispatch(storeLoginUserAction(JSON.stringify(data.login.access_token)));
        localStorage.setItem('login-user', JSON.stringify(data.login.user));
        navigate(PATHS.DASHBOARD);
      }
      if (errors) {
        toast.error(
          errors?.length
            ? errors[0]?.message
            : 'Login unsuccessful. Please check your credentials and try again',
          { duration: TOAST_TIME }
        );
      }
    } catch (error) {
      // console.log('error2', error);
    }
  };

  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    trigger,
    setValue,
    loginLoading,
  };
};
