export const PATHS = {
  HOME: '/',
  DEFAULT_LOGIN: '/auth/login',
  FORGET_USER_ID_PAGE: '/auth/forget-user-id',
  DEFAULT_HOME: '/',
  SIGN_UP: '/auth/sign-up',
  FORGET_PASSWORD: '/auth/forget-password',
  CHECK_EMAIL: '/auth/check-email',
  RESET_PASSWORD: '/auth/reset-password',
  SIGN_UP_STEPPER: '/auth/sign-up',
  THANK_YOU_PAGE: '/auth/thank-you',
  // ****************claims
  CLAIMS: '/claims',
  CLAIM_FILE: '/claims/claim-file',
  EDIT_CLAIM: '/claims/edit-claim',
  CONCLUDE_TRANSIT: '/claims/conclude-transit',
  REPORT_MANUAL_CLAIM: '/claims/report-manual-claim',
  CLAIM_DETAIL: '/claims/claim-detail',
  MANUAL_CLAIM_DETAIL: '/claims/manual-claim-detail',
  // *****************Shipments
  SHIPMENTS: '/shipments',
  REPORTS_SHIPMENTS: '/shipments/report-shipment',
  EDIT_SHIPMENT: '/shipments/edit-shipment',
  VIEW_SHIPMENT_DETAILS: '/shipments/view-details',
  // ******************** ship from
  SHIP_FROM: '/ship-from',
  ADD_SHIP_FROM_ADDRESS: '/ship-from/add-ship-from-address',
  EDIT_SHIP_FROM_ADDRESS: '/ship-from/edit-ship-from-address',
  // *********************staff
  STAFF: '/staff',
  STAFF_ADD_STAFF_MEMBER: '/staff/add-staff-member',
  //************ payment ****************/
  PAYMENT: '/payment',
  PAYMENT_ADD_PAYMENT_METHOD_CARD: '/payment/add-payment-method/card',
  //************Footer Links Page***********************/
  CHARITY: '/charity',
  REBATE_PROGRAMME: '/rebate-programme',
  NO_CLAIM_REWARD_PROGRAMME: '/no-claim-reward-programme',
  EXTENDED_WARRANTY: '/extended-warranty',
  TERMS_AND_CONDITION: '/terms-and-condition',
  REFUND_POLICY: '/refund-policy',
  FINE_ART_TERMS: '/fine-art-terms',
  // FURNITURE_TERMS_AND_CONDITION: '/furniture-terms-and-condition',
  //**********************************************/
  DASHBOARD: '/dashboard',
  TEST_REPORT_SHIPMENT: '/dashboard/report-test-shipment',
  CALCULATOR: '/calculator',
  //**************billings************************/
  BILLINGS: '/billings',
  //**********************************************/
  //**************users************************/
  PROFILE: '/profile',
  SIGN_POLICY: '/sign-policy',
};
