import { Tooltip } from '@material-tailwind/react';
import React from 'react';
import CustomContainer from '../../../common/custom/CustomContainer';
import { CustomIcon } from '../../../common/customAssets';
import { CustomText } from '../../../common/custom';
import { deleteIcon } from '../../../assets';

const PaymentActionMenu = ({ handleClickDelete }) => {
  return (
    <div
      className={`w-[190px] z-10 shadow-md bg-foundational-white  absolute top-[30px] right-[50px] rounded-[8px] flex flex-col items-center justify-center  gap-[2px] py-[4px] border-[1px] border-solid border-[#EAECF0]`}
    >
      <CustomContainer
        onClick={handleClickDelete}
        className='cursor-pointer flex items-center w-full min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px]'
      >
        <Tooltip className='mt-[-10px] z-50' content='Delete'>
          <div className='flex items-center'>
            <CustomIcon icon={deleteIcon} />
            <CustomText className='font-inter font-[500] ml-[8px]'>
              Delete
            </CustomText>
          </div>
        </Tooltip>
      </CustomContainer>
    </div>
  );
};

export default PaymentActionMenu;
