import { ceasataStamp } from '../../assets';
export default function ExtendedWarranty() {
  return (
    <div className='mx-auto max-w-[1200px]  pt-[50px] px-8'>
      <h1 className='text-[32px] font-bold'>Extended Warranty</h1>
      <div className='mt-8 space-y-6'>
        <section>
          <div className='mt-8'>
            <h2 className='text-[16px] font-semibold font-inter'>Coverage:</h2>
            <p>
              This extended factory warranty covers all defects in materials and
              workmanship that were covered under the factory warranty of the
              insured piece.
            </p>
          </div>
        </section>
        <section>
          <div className='mt-8'>
            <h2 className='text-[16px] font-semibold font-inter'>Term:</h2>
            <p>
              The extended warranty is valid for a period of 12 months from the
              expiration date of the factory warranty.
            </p>
          </div>
        </section>
        <section>
          <div className='mt-8'>
            <h2 className='text-[16px] font-semibold font-inter'>
              Conditions:
            </h2>
            <p>
              To be eligible for coverage under this extended warranty, the
              furniture must have been purchased new and used solely for
              residential purposes. The insured piece must also have been
              installed, assembled, used, and maintained in accordance with the
              manufacturer's instructions.
            </p>
          </div>
        </section>
        <section>
          <div className='mt-8'>
            <h2 className='text-[16px] font-semibold font-inter'>
              Exclusions:
            </h2>
            <p>
              This extended warranty does not cover damage caused by any
              conditions excluded from the original factory warranty.
            </p>
          </div>
        </section>
        <section>
          <div className='mt-8'>
            <h2 className='text-[16px] font-semibold font-inter'>
              Repair or Replacement:
            </h2>
            <p>
              If a covered defect occurs during the warranty period, the
              manufacturer will, at its option, either repair or replace the
              affected furniture. If the furniture is replaced, it will be
              replaced with an item of equal or greater value.
            </p>
          </div>
        </section>
        <section>
          <div className='mt-8'>
            <h2 className='text-[16px] font-semibold font-inter'>
              Transferability:
            </h2>
            <p>
              This extended warranty is transferable to subsequent owners of the
              furniture, provided that the furniture is used solely for
              residential purposes and an inspection of the insurability is done
              by an authorized inspector. (additional inspection fees may apply)
            </p>
          </div>
        </section>
        <section>
          <div className='mt-8'>
            <h2 className='text-[16px] font-semibold font-inter'>
              Limitation of Liability:
            </h2>
            <p>
              The manufacturer's liability under this extended warranty is
              limited to the cost of repairing or replacing the affected item.
              The manufacturer is not liable for any incidental or consequential
              damages, including but not limited to lost profits or revenue,
              injury to persons or property, or other economic or non-economic
              losses.
            </p>
          </div>
        </section>
        <section>
          <div className='mt-8'>
            <h2 className='text-[16px] font-semibold font-inter'>
              Dispute Resolution:
            </h2>
            <p>
              Any disputes arising under this extended warranty will be resolved
              through binding arbitration in accordance with the rules of the
              American Arbitration Association. The arbitration will take place
              in the state where the insured piece was purchased.
            </p>
          </div>
        </section>
        <section>
          <div className='mt-8'>
            <h2 className='text-[16px] font-semibold font-inter'>
              Governing Law:
            </h2>
            <p>
              This extended warranty is governed by the laws of the state where
              the insured piece was purchased.
            </p>
          </div>
        </section>
        <section>
          <div className='mt-8'>
            <h2 className='text-[16px] font-semibold font-inter'>
              Entire Agreement:
            </h2>
            <p>
              This extended warranty constitutes the entire agreement between
              the purchaser and the manufacturer with respect to the covered &
              insured piece. No other warranties or representations, whether
              written or oral, are made or implied.
            </p>
          </div>
        </section>
      </div>
      <div className='flex justify-end w-[80%]'>
        <img
          src={`${ceasataStamp}`}
          alt='Stamp'
          width={150}
          className='transform -rotate-45 opacity-40'
        />
      </div>
    </div>
  );
}
