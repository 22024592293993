import { Link } from 'react-router-dom';
import CustomContainer from '../../common/custom/CustomContainer';
import { PATHS } from '../../constants/paths';
import { footerLinkList } from './footerLinksData';
import { useFooter } from './useFooter';

export default function Footer() {
  const { token } = useFooter();

  return (
    <footer className=''>
      <CustomContainer>
        <div className='flex flex-col lg:flex-row items-start justify-between lg:items-center mt-[20px] md:mt-[30px] lg:mt-[120px] py-[24px]'>
          <span className='mb-[12px] lg:mb-0 text-[color:var(--Foundation-Black-B100,#6B6B6B)] text-sm not-italic font-medium leading-[normal];'>
            © {new Date().getFullYear()} Ceasta Assurance
          </span>
          {/* <nav className=' gap-[12px] lg-gap-[4px] grid w-full justify-items-start justify-between grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:flex max-w-[100%] '> */}
          <nav className=' gap-[12px] lg-gap-[4px] grid w-full justify-items-start justify-between grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:flex max-w-[600px]  '>
            {token &&
              footerLinkList.map((menu) => {
                return (
                  <Link
                    className='text-[color:var(--Foundation-Black-B100,#6B6B6B)] text-sm not-italic font-medium leading-[normal]'
                    to={menu.link}
                  >
                    {menu.name}
                  </Link>
                );
              })}
          </nav>
        </div>
      </CustomContainer>
    </footer>
  );
}
