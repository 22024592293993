import React from 'react';

import CustomContainer from '../../../common/custom/CustomContainer';
import { NoClaimsComponent, AllClaims } from '../components';
import { ClientsPanelHeader } from '../../../common/custom';
import { useClaims } from './useClaims';

const Claims = () => {
  const {
    data,
    isLoading,
    reportClaimHandler,
    newClaimHandler,
    currentTab,
    handleTabSwitch,
  } = useClaims();

  return (
    <CustomContainer>
      <ClientsPanelHeader hideReportButton={true} />
      <AllClaims
        data={data}
        isLoading={isLoading}
        reportClaimHandler={reportClaimHandler}
        newClaimHandler={newClaimHandler}
        currentTab={currentTab}
        handleTabSwitch={handleTabSwitch}
      />{' '}
    </CustomContainer>
  );
};

export default Claims;
