import { createSlice } from "@reduxjs/toolkit";
import { storeAllPayments } from "./paymentsActions";

const initialState = {
  payments: [],
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    storeAllPaymentsAction: storeAllPayments,
  },
});

export const paymentsReducer = paymentsSlice.reducer;
export const { storeAllPaymentsAction } = paymentsSlice.actions;
