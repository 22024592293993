import {
  ApolloClient,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const defaultOptions = {
  watchQuery: {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  },
  mutate: {
    errorPolicy: "all",
  },
  query: {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  },
};

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BACKEND_BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  try {
    if (typeof window !== "undefined") {
      const token = JSON.parse(localStorage.getItem("token"));
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    }
    return {
      headers: {
        ...headers,
      },
    };
  } catch (e) {
    console.error("Error in Apollo-client", e);
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export default client;
