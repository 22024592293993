import { Dialog } from "@mui/material";
import { closeIcon } from "../../../../assets";
import { CustomImage } from "../../../customAssets";

export default function PreviewFullImageModal({
  title,
  show = false,
  onClose,
  file,
}) {
  return (
    <Dialog open={show} onClose={onClose}>
      <div className="max-w-[1219px] p-8 h-[723px] overflow-y-auto">
        <div className="flex justify-between items-center gap-3">
          <div className="text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]">
            {title}
          </div>
          <div onClick={onClose} className="hover:cursor-pointer">
            <CustomImage
              img={closeIcon}
              alt="back"
              className="w-[30px] h-[30px]"
            />
          </div>
        </div>
        <div className="!pt-8 pb-6 h-[500px]">
          {file && file.location && (
            file.location.split(".").pop() === "pdf" ||
            file.location.split(".").pop() === "docx" ||
            file.location.split(".").pop() === "doc" ? (
              <iframe
                title="PDF Viewer"
                width="100%"
                height="100%"
                src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                  file.location
                )}&embedded=true`}
                frameBorder="0"
              ></iframe>
            ) : (
              <img
                src={file.location}
                alt="Preview Image"
                style={{ width: "100%", height: "auto" }}
              />
            )
          )}
        </div>
      </div>
    </Dialog>
  );
}
