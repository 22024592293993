import React from 'react';
import FormImageLayout from '../component/FormImageLayout';
import { CustomButton, CustomText } from '../../../common/custom';
import { useCheckEmail } from './useCheckEmail';

import { PATHS } from '../../../constants/paths';
import CustomHeading from '../../../common/custom/CustomHeading';
import BackButton from '../../../common/custom/BackButton';
import { backIcon } from '../../../assets';
import { Link, useLocation } from 'react-router-dom';

const CheckEmail = () => {
  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');
  const { checkEmail } = useCheckEmail();

  return (
    <FormImageLayout>
      <div>
        <CustomHeading as='h1'>Check your email</CustomHeading>
        <div className='mb-[30px] mt-[12px]'>
          <CustomText
            as='p'
            className='text-medium-para   text-center text-para-black   font-roboto font-[600]'
          >
            We send a password reset link to
          </CustomText>
          <CustomText
            as='p'
            className='text-medium-para  text-center text-foundation-brown font-roboto font-[600]'
          >
            {email}
          </CustomText>
        </div>

        <CustomButton
          text='Check email'
          onClick={() => checkEmail(email)}
        />
        <div className='flex justify-center items-center mt-[32px]'>
          <CustomText
            as='p'
            className='text-[#464646] text-[14px] leading-[20px] font-[600] font-roboto'
          >
            Didn't receive the email
          </CustomText>

          <CustomText
            as='p'
            className='text-foundation-brown text-small-para font-roboto ml-1 font-[600] '
          >
            <Link to={PATHS.FORGET_PASSWORD}>Click to resend</Link>
          </CustomText>
        </div>

        <div className=' flex justify-center mt-[32px]'>
          <BackButton
            link={PATHS.DEFAULT_LOGIN}
            icon={backIcon}
          />
        </div>
      </div>
    </FormImageLayout>
  );
};

export default CheckEmail;
