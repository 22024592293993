import { createSlice } from '@reduxjs/toolkit';
import {
  resetAddressSlice,
  setAddress,
  storeAllAddresses,
} from './addressesAction';

const initialState = {
  addresses: [],
  selectedAddress: null,
};

const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    storeAllAddressesAction: storeAllAddresses,
    setAddressAction: setAddress,
    resetAddressSliceAction: resetAddressSlice,
  },
});

export const addressesReducer = addressesSlice.reducer;
export const {
  storeAllAddressesAction,
  setAddressAction,
  resetAddressSliceAction,
} = addressesSlice.actions;
