import NoClaimsComponent from './noCLaimsComponent';
import AllClaims from './allClaims';
import ClaimListItem from './claimListItem';
import ALlClaimsList from './allClaimsList';
import ClaimsActionMenu from './claimsActionMenu';
export {
  NoClaimsComponent,
  ClaimListItem,
  AllClaims,
  ALlClaimsList,
  ClaimsActionMenu,
};
