import { Dialog } from '@mui/material';
import {
  attachement,
  calendarIcon,
  closeIcon,
  informationIcon,
} from '../../../../assets';
import { CustomIcon, CustomImage } from '../../../customAssets';
import { FORMATE_DATE } from '../../../../helpers/helpers';

export default function ClaimModal({ title, show = false, onClose, data }) {
  return (
    <Dialog open={show} onClose={onClose}>
      <div className='max-w-[1219px] p-8 max-h-[900px] overflow-y-auto'>
        <div className='flex justify-between items-center gap-3'>
          <div className='text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]'>
            {title}
          </div>
          <div onClick={onClose} className='hover:cursor-pointer'>
            <CustomImage
              img={closeIcon}
              alt='back'
              className='w-[30px] h-[30px]'
            />
          </div>
        </div>
        <div className='!pt-8 mt-[28.5px] pb-6 flex w-[317px] justify-start items-center gap-4 p-3 shadow-[0px_0px_10px_0px_rgba(0,0,0,0.14)] rounded-xl bg-[#fff]'>
          <div>
            <CustomIcon
              icon={informationIcon}
              alt='info'
              className='w-[30px] h-[30px]'
            />
          </div>
          <div className='flex flex-col gap-1'>
            <div className='text-[#475467] text-lg not-italic font-semibold leading-[normal]'>
              {data.status}
            </div>

            <div className='text-[#7D7D7D] text-sm not-italic font-medium leading-[normal]'>
              Claim filed by {data.recipient}.
            </div>
            <div className='flex gap-3 mt-3'>
              <div className='flex gap-1'>
                <div>
                  <CustomIcon
                    icon={calendarIcon}
                    alt='calender'
                    className='w-4 h-4'
                  />
                </div>
                <div className='text-[#7D7D7D] text-sm not-italic font-normal leading-[normal]'>
                  {FORMATE_DATE(data.createdAt, 'mm/dd/yy')}
                </div>
              </div>
            </div>
            <div className='mt-3 border-solid border-0 border-t border-[#7D7D7D]-900'></div>
            {data?.attachments && (
              <div className='flex gap-3 mt-3'>
                <div className='flex gap-1'>
                  <div>
                    <CustomIcon
                      icon={attachement}
                      alt='attachement'
                      className='w-4 h-4'
                    />
                  </div>
                  <div className='text-[#7D7D7D] text-sm not-italic font-medium leading-[normal]'>
                    Attachment:
                  </div>
                </div>{' '}
                <a
                  className='text-[#7D7D7D] text-sm not-italic font-normal leading-[normal] underline'
                  href={JSON.parse(data.attachments)[0]?.location}
                  alt='attachement'
                  target='_blank'
                >
                  {JSON.parse(data.attachments)[0]?.originalname}
                </a>
              </div>
            )}
            {data?.invoice && (
              <div className='flex gap-3 mt-3'>
                <div className='flex gap-1'>
                  <div>
                    <CustomIcon
                      icon={attachement}
                      alt='attachement'
                      className='w-4 h-4'
                    />
                  </div>
                  <div className='text-[#7D7D7D] text-sm not-italic font-medium leading-[normal]'>
                    Invoice:
                  </div>
                </div>
                <a
                  className='text-[#7D7D7D] text-sm not-italic font-normal leading-[normal] underline'
                  href={JSON.parse(data.invoice)[0]?.location}
                  alt='attachement'
                  target='_blank'
                >
                  {JSON.parse(data.invoice)[0]?.originalname}
                </a>
              </div>
            )}
            {data?.receipts && (
              <div className='flex gap-3 mt-3'>
                <div className='flex gap-1'>
                  <div>
                    <CustomIcon
                      icon={attachement}
                      alt='attachement'
                      className='w-4 h-4'
                    />
                  </div>
                  <div className='text-[#7D7D7D] text-sm not-italic font-medium leading-[normal]'>
                    Receipt:
                  </div>
                </div>{' '}
                <a
                  className='text-[#7D7D7D] text-sm not-italic font-normal leading-[normal] underline'
                  href={JSON.parse(data.receipts)[0]?.location}
                  alt='receipt'
                  target='_blank'
                >
                  {JSON.parse(data.receipts)[0]?.originalname}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
