import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { isTokenExpired, logout } from '../helpers/helpers';
import { TOAST_TIME } from '../constants/toastNotifications';

const withAuth = (WrappedComponent) => {
  const WithAuthComponent = (props) => {
    useEffect(() => {
      if (isTokenExpired()) {
        toast.dismiss();
        toast.success('Token expired, logging out!', {
          duration: TOAST_TIME,
        });
        setTimeout(() => {
          logout();
        }, TOAST_TIME);
      }
    });

    return <WrappedComponent {...props} />;
  };

  return WithAuthComponent;
};

export default withAuth;
