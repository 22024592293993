import { CustomText } from "../custom";
import { useVerticalStepper } from "./use-vertical-stepper.hook";

const CustomVerticalStepper = ({ steps, currentStep }) => {
  const { newSteps, stepDesign } = useVerticalStepper(currentStep, steps);

  const displayStep = newSteps.map((step, index) => (
    <div key={index} className="flex items-center flex-col">
      <div className="relative flex flex-col items-center">
        <div
          className={`rounded-full transition duration-500 ease-in-out h-[24px] w-[24px] flex justify-center items-center ${
            step.highlighted && step.selected
              ? stepDesign.completed
              : step.highlighted && step.selected && !step.completed
              ? stepDesign.inProgressp
              : stepDesign.notReached
          }`}
        >
          {step.completed ? (
            <span className="bg-foundation-brown w-full h-full rounded-full flex justify-center items-center text-sm text-white">
              &#10003;
            </span>
          ) : (
            <span className="rounded-full bg-white h-[8px] w-[8px]"></span>
          )}
        </div>
        <CustomText
          className={`absolute top-[-40px] left-[30px] whitespace-nowrap mt-[36px] text-small-para font-[600] w-[129px] flex justify-center ${
            step.selected ? "text-foundation-brown" : "text-para-black"
          }`}
        >
          {step.description}
        </CustomText>
        {index !== newSteps.length - 1 && (
          <div
            // className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
            className={`flex border-2 h-[105px] transition duration-500 ease-in-out ${
              step.completed ? "border-foundation-brown" : ""
            }`}
          ></div>
        )}
      </div>
    </div>
  ));

  return (
    <div className="p-4 flex flex-col justify-between items-start max-w-[282px] w-full h-[309px]">
      {displayStep}
    </div>
  );
};

export default CustomVerticalStepper;
