import { combineReducers } from '@reduxjs/toolkit';
import { uiSliceReducer } from './ui-slice';
import { formsReducer } from './formsSlice';
import { shipmentsReducer } from './shipmentsSlice';
import { addressesReducer } from './addressesSlice';
import { staffReducer } from './staffSlice';
import { claimReducer } from './claimSlices';
import { paymentsReducer } from './payments';
import { loginUserReducer } from './loginUserSlice';

export const rootReducer = combineReducers({
  ui: uiSliceReducer,
  forms: formsReducer,
  shipments: shipmentsReducer,
  addresses: addressesReducer,
  staff: staffReducer,
  claim: claimReducer,
  payments: paymentsReducer,
  loginUser: loginUserReducer,
});
