import { ceasataStamp } from '../assets';
export default function TermsAndCondition() {
  return (
    <div className='mx-auto max-w-[1200px]  pt-[50px] px-8'>
      <h1 className='text-[32px] font-bold'>Terms and Conditions</h1>

      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Eligibility:</h2>
        <p>
          Ceasta Assurance. provides transit insurance coverage for furniture,
          encompassing a wide range of items such as lamps, light fixtures,
          chandeliers, vases, mirrors, kitchen cabinets, media consoles,
          bathroom fixtures and more.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Insure Limit:</h2>
        <p>
          Maximum coverage up to $75,000. If exceeding the maximum limit of
          $75,000, please call CAI for approval.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Coverage:</h2>
        <p>
          This insurance policy provides coverage for the shipping of insured
          furniture from the date of pick-up confirmation (as indicated by a
          bill of lading or pick-up order) to the destination, protecting
          against damage, theft, or loss during transit, up to the declared
          value.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Image:</h2>
        <p>
          Clear images of the front and back of the furniture must be submitted
          for insurance purposes. CAI reserves the right to decline coverage if
          the images are not clear.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Packaging:</h2>
        <p>
          The furniture must be securely packed or encased. After it's been
          packed or encased, provide a clear image of the package with an
          attached shipping label containing the name and address of the
          destination to CAI.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Insured Value:</h2>
        <p>
          The insured value of the furniture will be determined by the declared
          value.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Premium:</h2>
        <p>
          The premium for this insurance policy will be calculated as a
          percentage of the insured value of the shipment and will be charged to
          the merchant upon the conclusion of the transit phase. If the shipment
          is to be returned, the paid premium is non-refundable. Our policy does
          not cover the return of furniture
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Pick-Up Confirmation:
        </h2>
        <p>
          Wait for confirmation from CAI before allowing the pick-up of your
          furniture. Without this confirmation, no coverage will be provided.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Notification of Damage, Theft, or Loss:
        </h2>
        <p>
          The insured must notify CAI of any damage, theft, or loss within 72
          hours following the scheduled delivery date. A police report is
          required.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Deadline to File a Claim:
        </h2>
        <p>
          The insured must submit a claim to CAI no later than 72 hours after
          the scheduled arrival date, upon determination of damage, theft, or
          loss. Submit all necessary information, documentation, and evidence to
          support the claim. CAI will contact the carrier company to obtain a
          report and necessary documentation, in order to process the claim.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Required Document(s) to File a Claim:
        </h2>
        <p>
          Proof of purchase that verifies the name of the furniture store or
          showroom, purchase date and price, such as a purchase receipt.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Damaged Furniture:
        </h2>
        <p>
          If the furniture sustains damage during transit, our Contracted
          Professional Repairer will assess the extent of the damage and provide
          necessary scope of repair work.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Repairable:</h2>
        <p>
          CAI will arrange for transportation to the repairer's shop. Detailed
          images will be sent to the customer after repair has been completed.
          CAI will arrange for transportation of the furniture back to the
          customer, covering all expenses.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Lost/Stolen:</h2>
        <p>
          In the event of furniture being lost or stolen during transit, CAI
          will initiate a tracing with the carrier company. After 10 working
          days, if the furniture cannot be found, CAI will start the claim
          process. The processing time for such claims may vary depending on the
          nature of the loss, as it can be influenced by investigative
          procedures.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Inspection:</h2>
        <p>
          The insurance company retains the right to inspect the merchandise and
          the packaging at the point of origin or the final destination to
          verify the damage.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Exclusions:</h2>
        <p>
          This insurance policy does not cover damage caused by war, riots,
          strikes, confiscation by authorities, and any other perils excluded by
          the insurance company.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Subrogation:</h2>
        <p>
          The insurance company reserves the right to recover the paid amount
          from any responsible party or carrier.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Jurisdiction:</h2>
        <p>
          This insurance policy is governed by the laws of the jurisdiction
          where the shipment originated, and any dispute arising from this
          insurance policy shall be subject to the exclusive jurisdiction of the
          courts of that jurisdiction.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>Termination:</h2>
        <p>
          The insurance policy may be terminated by the policyholder or the
          insurance company for various reasons, including non-payment of
          premiums or fraudulent claims.
        </p>
      </div>
      <div className='mt-8'>
        <h2 className='text-[16px] font-semibold font-inter'>
          Indemnification:
        </h2>
        <p>
          The insurance company shall indemnify and hold the policyholder
          harmless from any claims, damages, or liabilities arising from the
          loss or damage of the item during transit or shipping.
        </p>
      </div>
      <div className='flex justify-end w-[80%]'>
        <img
          src={`${ceasataStamp}`}
          alt='Stamp'
          width={150}
          className='transform -rotate-45 opacity-40'
        />
      </div>
    </div>
  );
}
