import React from 'react';
import { ClientsPanelHeader } from '../../../common/custom';
import Attachments from './components/attachments.component';
import Checklist from './components/checklist.component';
import Details from './components/details.component';

import ShipmentDetail from './components/shipment-detail.component';
import UpdateClaim from './components/update-claim.component';
import UseClaimDetails from './use-claim-details.hook';
import { extractArrayData } from '../../../helpers/helpers';
import UploadDocument from './components/upload-signed-release/upload-signed-release.component';

function ClaimDetails() {
  const {
    claim,
    claimType,
    options,
    setClaimType,
    handleUpdateClaim,
    claimData,
    onCancel,
    releaseFile,
    setReleaseFile,
    fileError,
    setFileError,
    claimValue,
    setClaimValue,
  } = UseClaimDetails();

  return (
    <div className='sm:px-[120px]'>
      <ClientsPanelHeader />
      <div className='flex mt-[22.5px] gap-6 flex-col sm:flex-row px-[20px]'>
        <div className='w-full max-w-[746px] flex flex-col gap-6'>
          <Attachments
            invoice={extractArrayData(claimData?.invoice)}
            receipt={extractArrayData(claimData?.receipts)}
            attachment={extractArrayData(claimData?.attachments)}
          />

          <UploadDocument
            files={
              claimData?.signedReleaseClaim
                ? JSON.parse(claimData?.signedReleaseClaim)
                : null
            }
            handleUpdateClaim={handleUpdateClaim}
            releaseFile={releaseFile}
            setReleaseFile={setReleaseFile}
            fileError={fileError}
            setFileError={setFileError}
          />

          <UpdateClaim
            claim={claimData}
            claimType={claimType}
            options={options}
            setClaimType={setClaimType}
            handleUpdateClaim={handleUpdateClaim}
            onCancel={onCancel}
            claimValue={claimValue}
            setClaimValue={setClaimValue}
          />
        </div>
        <div className='flex flex-col max-w-[430px] gap-6'>
          <Checklist
            invoice={extractArrayData(claimData?.invoice)}
            receipt={extractArrayData(claimData?.receipts)}
            attachment={extractArrayData(claimData?.attachments)}
          />
          <ShipmentDetail claim={claimData} />
          <Details claim={claimData} />
        </div>
      </div>
    </div>
  );
}

export default ClaimDetails;
