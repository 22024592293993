import React from 'react';

import CustomContainer from '../../../common/custom/CustomContainer';
import SignUpHeader from '../component/SignUpHeader';

import Stepper from '../component/stepper';

import SignUpStepperdWrapper from '../component/SignUpCardWrapper';
import { useSignup } from './useSignUpStpper';
import { steps } from './stepsData';

const SignUpStepper = () => {
  const { currentStep, displaySteps } = useSignup();

  return (
    <CustomContainer className='flex justify-center items-center min-h-[100vh] p-1 '>
      <SignUpStepperdWrapper>
        <SignUpHeader />
        <div className='w-full mb-[28px]'>
          {/* Stepper */}
          <Stepper
            steps={steps}
            currentStep={currentStep}
          />
        </div>

        {displaySteps(currentStep)}
      </SignUpStepperdWrapper>
    </CustomContainer>
  );
};

export default SignUpStepper;
