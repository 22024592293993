import React from 'react';
import CustomText from '../CustomText';
import GoogleMapAutocomplete from '../google-maps-auto-complete';

const AutoCompleteComponent = ({
  onChange,
  street,
  errors,
  name,
  label = 'Address',
  address,
  setstreet,
  classes = 'border border-gray-400 rounded-md px-4 text-sm py-2  w-full',
  isFormatted = false,
  setValue,
  trigger,
  register,
  required,
  value,
}) => {
  const handlePlaceSelect = (address, latitude, longitude) => {};

  return (
    <div className=''>
      <CustomText className='mb-[6px] font-inter font-[500] text-[14px] text-[#344054]'>
        {label}
        {required && <span className='text-foundation-brown'>*</span>}
      </CustomText>
      <GoogleMapAutocomplete
        name={name}
        label={label}
        onChange={onChange}
        street={street}
        classes={classes}
        setstreet={setstreet}
        onPlaceSelect={handlePlaceSelect}
        isFormatted={isFormatted}
        address={address}
        setValue={setValue}
        trigger={trigger}
        errors={errors}
        register={register}
      />
    </div>
  );
};

export default AutoCompleteComponent;
