import React from 'react';

// todo.. what is purpose of these props ?
const CustomText = ({
  // will receive element in 'as' prop for example as=p,as=label
  as: Element = 'p', // if no 'as' prop passed in this componenet than p element will used as default

  // will receive className props  in which tailwind css classes will be passed
  className = 'text-para-black text-sm font-roboto font-[500] ', // if no className props are passed than these classes will aplied as default
  // these default classes are of small para from figma, which are used in many places

  // text content will be received as children example "something etc"
  children,
}) =>
  // Render the dynamic text  with dynamic styles and element based on props

  {
    return (
      <Element className={className}>
        {/* dynamic text will be resecived as children */}
        {children}
      </Element>
    );
  };
export default CustomText;

// Example usage:

// <CustomText
// as="label"
// className='text-pure-black font-roboto font-semibold text-xl md:text-2xl lg:text-3xl' >
// Hello World
// </CustomText>
