import { createSlice } from '@reduxjs/toolkit';

const loginUserSlice = createSlice({
  name: 'loginUser',
  initialState: { loginUser: null, token: null },
  reducers: {
    storeLoginUserAction: (state, action) => {
      state.token = action.payload;
    },
  },
});
export const loginUserReducer = loginUserSlice.reducer;
export const { storeLoginUserAction } = loginUserSlice.actions;
