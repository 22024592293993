// hooks/useGraphQlMutation.js
import { useState } from "react";
import { useDispatch } from "react-redux";

import toast from "react-hot-toast";
import { TOAST_TIME } from "../constants/toastNotifications";

const useApi = (api, action) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const executeFunction = async (variables) => {
    try {
      setLoading(true);
      // const response = await api(variables);
      const { data, errors } = await api(variables);

      if (data) {
        setError(null);
        setData(data);
        if (action) {
          dispatch(action(data));
        }
      } else if (errors) {
        toast.dismiss();
        toast.error("Error from backend", { duration: TOAST_TIME });
      }
      toast.dismiss();
      return { data, errors };
    } catch (err) {
      toast.dismiss();
      toast.error(err.message, { duration: TOAST_TIME });
      setData(null);
      setError(err.message);

      return err;
    } finally {
      setLoading(false);
    }
  };

  //

  return [executeFunction, loading, error, data];
};

export default useApi;
