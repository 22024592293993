import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

const CustomModal = ({ children }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  return createPortal(
    <div className='fixed inset-0 overflow-hidden z-[100] bg-black bg-opacity-40 backdrop-blur-3 flex justify-center items-center'>
      <div className=' p-4 w-full flex justify-center items-center h-full '>
        {children}
      </div>
    </div>,
    document.querySelector('.modal')
  );
};

export default CustomModal;
