import { PATHS } from "../../../constants/paths";

import {
  claimsMenyIcon,
  paymentsMenuIcon,
  staffMenuIcon,
  shipFromMenuIcon,
  billingMenuIcon,
  shipmentsMenuIcon,
  dashboard,
  billingIcon,
} from "../../../assets";

export const menuListData = [
  {
    menuName: "Dashboard",
    link: PATHS.DASHBOARD,
    icon: dashboard,
  },
  {
    menuName: "Shipments",
    link: PATHS.SHIPMENTS,
    icon: shipmentsMenuIcon,
  },
  // {
  //   menuName: 'Billing',
  //   link: '/billing',
  //   icon: billingMenuIcon,
  // },
  {
    menuName: "Claims",
    link: PATHS.CLAIMS,
    icon: claimsMenyIcon,
  },
  {
    menuName: "Ship From",
    link: PATHS.SHIP_FROM,
    icon: shipFromMenuIcon,
  },
  {
    menuName: "Staff",
    link: PATHS.STAFF,
    icon: staffMenuIcon,
  },
  {
    menuName: "Payment",
    link: PATHS.PAYMENT,
    icon: paymentsMenuIcon,
  },
  {
    menuName: "Billings",
    link: PATHS.BILLINGS,
    icon: billingIcon,
  },
];
