import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Select,
  MenuItem,
  Divider,
  Paper,
  Stack,
} from '@mui/material';
import CustomContainer from '../../common/custom/CustomContainer';
import BackButton from '../../common/custom/BackButton';
import { PATHS } from '../../constants/paths';
import { backIcon } from '../../assets';

// List of US states for the dropdown
const usStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

const Calculator = () => {
  const [shipmentType, setShipmentType] = useState('local');
  const [state, setState] = useState('');
  const [totalValue, setTotalValue] = useState('');
  const [isTrial, setIsTrial] = useState('no'); // Changed to string for radio button
  const [premium, setPremium] = useState(null);
  const [rebate, setRebate] = useState(null);

  // Function to calculate the premium based on the shipment type and value
  const handleCalculate = () => {
    if (!totalValue || (shipmentType === 'local' && !state)) {
      setPremium(null);
      setRebate(null);
      return;
    }

    let calculatedPremium = 0;
    const value = parseFloat(totalValue);

    // Check if it's a trial shipment
    if (isTrial === 'yes') {
      if (value <= 5000) {
        setPremium(0);
        setRebate(0);
        return;
      } else {
        // Charge only on the amount above $5000
        const chargeableAmount = value - 5000;
        if (shipmentType === 'local') {
          if (state === 'Alaska' || state === 'Hawaii') {
            calculatedPremium = chargeableAmount * 0.02; // 0.2% for Alaska and Hawaii
          } else {
            calculatedPremium = chargeableAmount * 0.0125; // 1.25% for other states
          }
        } else {
          calculatedPremium = chargeableAmount * 0.0075; // 0.075% for international shipments
        }
      }
    } else {
      // Normal calculation for non-trial shipments
      if (shipmentType === 'local') {
        if (state === 'Alaska' || state === 'Hawaii') {
          calculatedPremium = value * 0.02; // 0.2% for Alaska and Hawaii
        } else {
          calculatedPremium = value * 0.0125; // 1.25% for other states
        }
      } else {
        calculatedPremium = value * 0.0075; // 0.075% for international shipments
      }
    }

    setPremium(calculatedPremium.toFixed(2));
    setRebate((calculatedPremium * 0.2).toFixed(2)); // 20% rebate of premium
  };

  // Recalculate premium whenever any of the relevant inputs change
  useEffect(() => {
    handleCalculate();
  }, [shipmentType, state, totalValue, isTrial]);

  return (
    <Box
      sx={{
        background: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
      }}
    >
      <CustomContainer>
        <Box sx={{ pt: { md: '40px', xs: '20px' } }}>
          <Card
            sx={{
              p: 3,
              boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.1)',
              borderRadius: '15px',
            }}
          >
            <div className='flex justify-between'>
              <BackButton
                link={PATHS.DASHBOARD}
                icon={backIcon}
                text='Back to Dashboard'
              />
            </div>
            <Typography variant='h4' align='center' gutterBottom>
              Shipment Premium Calculator
            </Typography>
            <Typography variant='body1' align='center' gutterBottom>
              Calculate your shipment's premium in seconds.
            </Typography>
            {/* Shipment Type Selection */}
            <FormControl component='fieldset' sx={{ mt: 3, mb: 3 }}>
              <FormLabel component='legend'>Shipment Type</FormLabel>
              <RadioGroup
                row
                aria-label='shipment-type'
                name='shipment-type'
                value={shipmentType}
                onChange={(e) => setShipmentType(e.target.value)}
              >
                <FormControlLabel
                  value='local'
                  control={<Radio />}
                  label='Local Shipment'
                />
                <FormControlLabel
                  value='international'
                  control={<Radio />}
                  label='International Shipment'
                />
              </RadioGroup>
            </FormControl>
            <br />
            {/* Trial Shipment Radio Buttons */}
            <FormControl component='fieldset' sx={{ mb: 3 }}>
              <FormLabel component='legend'>
                Is this your first shipment (Trial Shipment)?
              </FormLabel>
              <RadioGroup
                row
                aria-label='trial-shipment'
                name='trial-shipment'
                value={isTrial}
                onChange={(e) => setIsTrial(e.target.value)}
              >
                <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                <FormControlLabel value='no' control={<Radio />} label='No' />
              </RadioGroup>
            </FormControl>
            {/* US States Dropdown for Local Shipments */}
            {shipmentType === 'local' && (
              <FormControl fullWidth sx={{ mb: 3 }}>
                <FormLabel component='legend'>Select State</FormLabel>
                <Select
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value=''>Select a state</MenuItem>
                  {usStates.map((stateName) => (
                    <MenuItem key={stateName} value={stateName}>
                      {stateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {/* Total Value Input */}
            <FormControl fullWidth sx={{ mb: 3 }}>
              <TextField
                label='Total Value of Shipment ($)'
                variant='outlined'
                value={totalValue}
                onChange={(e) => setTotalValue(e.target.value)}
                type='number'
                inputProps={{ min: '0' }}
                helperText='Enter the total value of your shipment'
              />
            </FormControl>
            {/* Results Section */}
            {premium !== null && (
              <Paper
                elevation={4}
                sx={{
                  p: 3,
                  bgcolor: 'background.paper',
                  borderRadius: '15px',
                  mt: 1,
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                }}
              >
                {/* Divider */}
                {/* <Divider sx={{ my: 4 }} />
                <Typography variant='h6' align='center' gutterBottom>
                  Calculation Results
                </Typography> */}
                <Stack spacing={2}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant='body1' fontWeight='bold'>
                      Premium:
                    </Typography>
                    <Typography variant='body1' fontWeight='bold'>
                      ${premium}
                    </Typography>
                  </Box>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant='body1'>Rebate (20%):</Typography>
                    <Typography variant='body1'>${rebate}</Typography>
                  </Box>
                  {/* <Box display='flex' justifyContent='space-between'>
                    <Typography variant='body1' fontWeight='bold'>
                      Total Amount to Pay:
                    </Typography>
                    <Typography variant='body1' fontWeight='bold'>
                      ${(premium - rebate).toFixed(2)}
                    </Typography>
                  </Box> */}
                </Stack>
              </Paper>
            )}
          </Card>
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default Calculator;
