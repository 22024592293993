export default function Charity() {
  return (
    <div className="mx-auto max-w-[1200px] pt-[50px] px-4">
      <h1 className="text-5xl font-bold leading-tight text-gray-900">
        Dental Care for Underprivileged Children
      </h1>
      <p className="mt-6 text-lg text-gray-700">
        At Ceasta Assurance, we firmly believe that both businesses and
        individuals have a crucial role to play in giving back to our
        communities and striving to make the world a better place.
      </p>
      <p className="mt-4 text-lg text-gray-700">
        With this in mind, we are proud to commit a portion of our income to{" "}
        <a className="text-blue-600 underline" href="#">
          Stevenson Dental Foundation
        </a>
        , a 501(c)(3) nonprofit organization that shares our vision of a world
        in which underprivileged children have access to quality oral care.
        {"\n      "}
      </p>
      <p className="mt-4 text-lg text-gray-700">
        We warmly invite you to join us in supporting the Mobile Dental Clinic
        Project, an initiative that focuses on providing essential oral care to
        underprivileged children.
      </p>
      <div className="mt-6 space-y-4">
        <div className="flex items-start">
          <HeartIcon className="mt-1 mr-2 h-5 w-5 text-red-600" />
          <p className="text-lg text-gray-700">
            By opting to donate a portion or even all of your rebates, you can
            make a real difference in the lives of those who need it most.
          </p>
        </div>
        <div className="flex items-start">
          <HeartIcon className="mt-1 mr-2 h-5 w-5 text-red-600" />
          <p className="text-lg text-gray-700">
            Moreover, you can amplify your impact by offering in-kind donations.
          </p>
        </div>
      </div>
      <p className="mt-6 text-lg text-gray-700">
        Together, let's work toward a world in which quality oral care is
        accessible to everyone, starting with the most vulnerable members of our
        society – our children. Your partnership in this endeavor is invaluable,
        and we appreciate your support in helping us create a brighter future
        for all.
      </p>
      <p className="mt-4 text-lg text-gray-700">
        We will regularly update our generous donors of progress as we work
        together to transform smiles, nurture futures, and provide dental care
        for underprivileged children.
      </p>
    </div>
  );
}

function HeartIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
    </svg>
  );
}
