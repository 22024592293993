import { useEffect, useState } from 'react';
import { getAllClaimsQuery } from '../../../api';
import { FORMATE_AMOUNT, FORMATE_DATE } from '../../../helpers/helpers';
import useApi from '../../../hook/useApi';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../constants/paths';

export const useClaims = () => {
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState('claims');

  const [getAllClaims, isLoading, getAllClaimsError, data] =
    useApi(getAllClaimsQuery);

  useEffect(() => {
    getAllClaims();
  }, []);

  const reportClaimHandler = () => {
    navigate(`${PATHS.REPORT_MANUAL_CLAIM}`);
  };

  const newClaimHandler = () => {
    navigate(`${PATHS.CLAIM_FILE}`);
  };

  const handleTabSwitch = (tab) => {
    setCurrentTab(tab);
  };

  const currentClaims =
    currentTab == 'claims'
      ? data?.allMyClaims?.claims
      : data?.allMyClaims?.manualClaims;

  const modifiedData = currentClaims?.map((claim) => {
    return {
      ...claim,
      id: claim.id,
      recipient: (
        <div className='flex flex-col'>
          {claim?.filedByUserType === 'User'
            ? claim?.filedByUser?.firstName + ' ' + claim?.filedByUser?.lastName
            : !claim?.filedByEntityMember // this check is for shipment claims which do not have entity member type
            ? claim?.filedByUser?.firstName + ' ' + claim?.filedByUser?.lastName
            : claim?.filedByEntityMember?.firstName +
              ' ' +
              claim?.filedByEntityMember?.lastName}
        </div>
      ),
      from:
        claim.shipment && claim.shipment.from && claim.shipment.from.nickName,
      carrier:
        claim.shipment && claim.shipment.carrier && claim.shipment.carrier.name,
      shipmentType:
        claim.shipment &&
        claim.shipment.shipmentType &&
        claim.shipment.shipmentType.name,
      value: claim.claimValue && FORMATE_AMOUNT(claim.claimValue),
      shipValue: claim.shipment && FORMATE_AMOUNT(claim?.shipment?.totalValue),
      adjusted: claim.claimAmount ? FORMATE_AMOUNT(claim.claimAmount) : '-',
      partial: claim.partToBeClaimed ? true : false,
      filledOn: claim.createdAt && FORMATE_DATE(claim.createdAt),
      status: claim.status,
      createdAt: claim.createdAt,
    };
  });

  return {
    data: modifiedData,
    isLoading,
    reportClaimHandler,
    newClaimHandler,
    currentTab,
    handleTabSwitch,
  };
};
