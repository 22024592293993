import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHookFormSubmission } from '../../../../hook/useHookFormSubmission';
import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getAllShipmentsFromLocationQuery,
  reportShipmentMutation,
} from '../../../../api';
import { PATHS } from '../../../../constants/paths';
import useApi from '../../../../hook/useApi';
// import { addShipmentAction } from '../../../../store';
import { resetReportShipmentFormAction } from '../../../../store/slices/formsSlice';
import { reportShipmentStepThreeSchema } from '../../schema/reportShipmentStepThreeSchema';
import { TOAST_TIME } from '../../../../constants/toastNotifications';
import { storeAllShipmentsAction } from '../../../../store';
import { getCarriersQuery } from '../../../../api/services/admin.service';

export const useReportShipmentStepThree = () => {
  const { pathname } = useLocation()
  const testReportShipment = pathname.includes('report-test-shipment')
  const { addresses } = useSelector((state) => state);

  const [getAllShipmentsFromLocation] = useApi(
    getAllShipmentsFromLocationQuery,
    storeAllShipmentsAction
  );

  const [reportShipmentCall, reportShipmentCallLoading] = useApi(
    reportShipmentMutation
  );

  const [getCarriers] = useApi(getCarriersQuery);
  const [carriers, setCarriers] = useState([]);

  const { reportShipment } = useSelector((state) => state.forms);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { handleSubmit, register, errors, setValue, watch } =
    useHookFormSubmission(reportShipmentStepThreeSchema);

  const { pickupDate } = watch();
  const watchedCarrier = watch('carrier');

  useEffect(() => {
    const getData = async () => {
      const res = await getCarriers();
      setCarriers(res?.data?.allCarriers);
    };
    getData();
  }, []);

  useEffect(() => {
    setValue(
      'pickupDate',
      reportShipment.formData.step3?.pickupDate
        ? reportShipment.formData.step3.pickupDate
        : ''
    );

    setValue(
      'deliveryDate',
      reportShipment.formData.step3?.deliveryDate
        ? reportShipment.formData.step3.deliveryDate
        : ''
    );
    setValue(
      'carrier',
      reportShipment.formData.step3?.carrier
        ? reportShipment.formData.step3.carrier
        : ''
    );
  }, []);

  const onSubmit = async (values) => {
    toast.dismiss();
    toast.loading('Reporting shipment...');
    const user = JSON.parse(localStorage.getItem('login-user'));
    let deliveryDate = new Date(values.deliveryDate);
    deliveryDate = DateTime.fromJSDate(deliveryDate).toFormat('yyyy-MM-dd');
    let pickupDate = new Date(values.pickupDate);
    pickupDate = DateTime.fromJSDate(pickupDate).toFormat('yyyy-MM-dd');
    // const from = parseInt(addresses.selectedAddress.id);
    const from = reportShipment.formData.step1.selectedFromAddress.id;
    const uid = parseInt(JSON.parse(localStorage.getItem('login-user')).id);

    if (addresses.selectedAddress && uid) {
      const { data, errors } = await reportShipmentCall({
        ...reportShipment.formData.step1,
        ...reportShipment.formData.step2,
        ...values,
        deliveryDate,
        pickupDate,
        locationId: user.locationId,

        // additionalNote: user.additionalNote ? user.additionalNote : '',
        entityId: uid,
        from,
        ...(testReportShipment && { paymentStatus: "test" }),  // Conditionally add paymentStatus
      });

      if (data) {
        // dispatch(addShipmentAction(data));
        const user = JSON.parse(localStorage.getItem('login-user'));
        getAllShipmentsFromLocation(user.locationId);
        dispatch(resetReportShipmentFormAction());
        toast.dismiss();
        toast.success(`${testReportShipment ? "Test" : ''} Shipment reported successfully`, {
          duration: TOAST_TIME,
        });
        navigate(testReportShipment ? PATHS.DASHBOARD : PATHS.SHIPMENTS);
      } else if (errors) {
        toast.dismiss();
        toast.error('Failed to report...try again later', {
          duration: TOAST_TIME,
        });
      }
    } else {
      toast.error('From address or uid not present', { duration: TOAST_TIME });
    }
  };

  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    pickupDate,
    reportShipmentCallLoading,
    watchedCarrier,
    carriers,
  };
};

// ERROR in CREATE SHIPMENT FROM BACKEND
