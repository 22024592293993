import { useState, useEffect } from 'react';

export default function useFilterBilling() {
  // const [entity, setEntity] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const Object = JSON.parse(localStorage.getItem('formFilter'));
    if (Object) {
      // setEntity(Object.entity);
      setStatus(Object.status);
    }
  }, []);
  return {
    // entity,
    status,
    setStatus,
    // setEntity,
  };
}
