import { DateTime } from 'luxon';
import moment from 'moment';
import toast from 'react-hot-toast';
import { jwtDecode } from 'jwt-decode';
import { PATHS } from '../constants/paths';

export const FILE_TYPE = ['JPG', 'PNG', 'GIF', 'SVG', 'PDF', 'JPEG', 'DOCX'];

// Function to filter files that exceed the size limit (1 MB = 1024 * 1024 bytes)
export const HANDLE_FILE_SIZE_CHECK = (size = 1, file) => {
  const filteredFile = file && file.size <= size * 1024 * 1024;

  if (!filteredFile) {
    // Display an error message if all files exceed the size limit
    toast.error(`The maximum file size shall be ${size} MB`);
    return [];
  }
  return filteredFile;
};

// Convert Date in 2024-01-12 formate
export const DATE_FORMATE_CONVERSION = (date) => {
  return date ? new Date(date).toISOString().split('T')[0] : null;
  // return date ? new Date(date).toISOString().split()[0] : null;
};

export const CAPITALIZE_FIRST_LETTER = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
};

export const FORMATE_AMOUNT = (value) => {
  // Parse the value to a float and fix it to 2 decimal places
  const amount = parseFloat(value).toFixed(2);

  // Check if the parsed amount is not a number
  if (isNaN(amount)) {
    console.error('Invalid Amount');
    return `$0.00`; // Default to $0.00 if the amount is invalid
  }

  // Format the number with commas and two decimal places
  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2, // Ensure at least 2 decimal places
    maximumFractionDigits: 2, // Limit maximum to 2 decimal places
  }).format(amount);

  return formattedAmount;
};

export const ONLY_DIGITS = (value) => {
  return value && value.replace(/\D/g, '');
};

export const ALL_LOWER_CASE = (string) => {
  return (string && string.toLowerCase()) || '';
};

export const ALL_UPPER_CASE = (string) => {
  return (string && string.toUpperCase()) || '';
};

export const FORMAT_PHONE_NUMBER_INPUT = (phoneNumber) => {
  // Ensure the input adheres strictly to the (XXX) XXX-XXXX pattern
  const formattedNumber =
    phoneNumber &&
    phoneNumber.replace(
      /^(\d{0,3})?(\d{0,3})?(\d{0,4})?$/,
      (_, areaCode, exchangeCode, lineNumber) => {
        let formatted = '';
        if (areaCode) {
          formatted += `(${areaCode}`;
        }
        if (exchangeCode) {
          formatted += `) ${exchangeCode}`;
        }
        if (lineNumber) {
          formatted += `-${lineNumber}`;
        }
        return formatted;
      }
    );

  return formattedNumber;
};

// Convert file size byes to mega bytes
export const FILE_SIZE_B_TO_MB = (sizeInBytes) => {
  return (sizeInBytes / (1024 * 1024)).toFixed(2);
};

export const PARSIFY = (string) => {
  let recipient = null;

  try {
    recipient = string ? JSON.parse(string) : null;
    return recipient;
  } catch (error) {
    console.error('Error parsing JSON giving string:', error);
  }
};

/**
 * Formats a given date according to the specified format.
 * @param {string} inputDate - The input date string to be formatted.
 * @param {string} format - The desired format for the output date.
 * @returns {string} - The formatted date string or an error message for an invalid date or format.
 */
export const FORMATE_DATE = (inputDate) => {
  return !inputDate
    ? 'Invalid Date'
    : moment.utc(inputDate).format('MM/DD/YYYY');
};

export const FORMATE_ADDRESS = (address) => {
  if (address === '' || address === undefined) return address;
  const { street, unitNo, city, state, zipCode } = JSON.parse(address);

  const streetString = street ? `${street.trim()}` : '';
  const unitNoString = unitNo ? ` ${String(unitNo).trim()}` : '';
  const cityString = city ? `${city.trim()},` : '';
  return `${streetString}${unitNoString}, ${cityString} ${state} ${zipCode ? zipCode : ''
    }`;
};

export const BEAUTIFY_ADDRESS = (address) => {
  if (address === '') return address;
  const { street, unitNo, city, state, zipCode } = address;
  const streetString = street ? `${street.trim()}` : '';
  const unitNoString = unitNo ? ` ${String(unitNo).trim()}` : '';
  const cityString = city ? `${city.trim()},` : '';
  return `${streetString}${unitNoString}, ${cityString} ${state} ${zipCode}`;
};

export const PREMIUM = (declaredValue, rate) => {
  return declaredValue * rate > 0.5
    ? FORMATE_AMOUNT(declaredValue * rate)
    : FORMATE_AMOUNT(0.5);
};

export const REBATE = (declaredValue, rate) => {
  return declaredValue ? FORMATE_AMOUNT(declaredValue * rate * 0.2) : '0.00';
};

// Function to serialize data without circular references
export const SAFE_STRINGIFY = (data) => {
  const seen = new WeakSet();
  return JSON.stringify(data, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return '[Circular]';
      }
      seen.add(value);
    }
    return value;
  });
};

export function calculateUniversalDate(dateString) {
  try {
    // Split the input date string into date, time, and timezone offset parts
    const [datePart, timeAndOffsetPart] = dateString.split('T');
    // const [timePart, timezonePart] = timeAndOffsetPart.split(/[+-]/);
    console.log('datePart:', datePart);
    const timePart = timeAndOffsetPart?.substring(
      0,
      timeAndOffsetPart?.length - 6
    );
    console.log('timePart:', timePart);

    const timezonePart = timeAndOffsetPart?.substring(
      timeAndOffsetPart?.length - 6,
      timeAndOffsetPart?.length
    );
    console.log('timezonePart:', timezonePart);

    // Parse the date components
    const [year, month, day] = datePart
      .split('-')
      .map((part) => parseInt(part, 10));
    console.log('y:m:d', year, month, day);

    // Parse the time components
    const [hours, minutes, seconds] = timePart
      .split(':')
      .map((part) => parseInt(part, 10));
    console.log('h:m:s', hours, minutes, seconds);

    // Parse the timezone offset components
    const [timezoneHours, timezoneMinutes] = timezonePart
      .match(/\d{2}/g)
      .map((part) => parseInt(part, 10));
    console.log('tzh:tzm', timezoneHours, timezoneMinutes);

    // Calculate the universal date by adjusting hours and minutes based on the timezone offset
    let universalHours = timezonePart?.startsWith('-')
      ? hours - timezoneHours
      : hours + timezoneHours;
    let universalMinutes = minutes + timezoneMinutes;
    console.log('uh:um', universalHours, universalMinutes);

    // Adjust date if time goes negative or exceeds 24 hours
    if (universalHours < 0) {
      // Borrow from the previous day
      universalHours += 24;
      const utcDate = new Date(
        Date.UTC(
          year,
          month - 1,
          day - 1,
          universalHours,
          universalMinutes,
          seconds
        )
      );
      console.log('utcDate:', utcDate.toISOString());
      return utcDate.toISOString();
    } else if (universalHours >= 24) {
      // Carry to the next day
      universalHours -= 24;
      const utcDate = new Date(
        Date.UTC(
          year,
          month - 1,
          day + 1,
          universalHours,
          universalMinutes,
          seconds
        )
      );
      console.log('utcDate:', utcDate.toISOString());
      return utcDate.toISOString();
    }
    // console.log('d3:',day, universalHours);

    // Create a new Date object for the calculated universal date
    const universalDate = new Date(
      Date.UTC(year, month - 1, day, universalHours, universalMinutes, seconds)
    );

    // Return the universal date as a string
    return universalDate.toISOString();
  } catch (error) {
    console.error('Error calculating universal date:', error);
    return null;
  }
}

export const DOWNLOAD_FILE = async (file) => {
  const link = document.createElement('a');
  link.href = file;
  link.download = 'downloaded_image.png';
  link.click();
};

export const isTokenExpired = () => {
  const token = JSON.parse(localStorage.getItem('token'));

  if (!token) return true;

  const { exp } = jwtDecode(token);
  if (!exp) return true;

  const expirationTime = exp * 1000; // Convert to milliseconds
  return Date.now() > expirationTime;
};

export const logout = () => {
  localStorage.clear();
  window.location.href = PATHS.DEFAULT_LOGIN;
};

export const extractArrayData = (str) => {
  try {
    let data = [];
    if (str) {
      // Parse the JSON string
      const arr = JSON.parse(str);

      // Flatten the array structure if nested
      const flattenArray = (input) => {
        if (Array.isArray(input)) {
          return input.reduce(
            (acc, item) => acc.concat(flattenArray(item)),
            []
          );
        }
        return [input];
      };

      const flatArr = flattenArray(arr);

      // Extract the required fields
      flatArr.forEach((item) => {
        if (item && typeof item === 'object') {
          data.push({
            location: item.location,
            originalname: item.originalname,
          });
        }
      });
    }

    return data;
  } catch (err) {
    console.log('error in parsing array data:', err);
    return [];
  }
};

export function has24HoursPassed(dateString) {
  // Parse the input date string
  const inputDate = new Date(dateString);
  // Get the current date and time
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = currentDate - inputDate;

  // Calculate the difference in hours
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

  // Check if the difference is greater than or equal to 24 hours
  return differenceInHours >= 24;
}

export function flattenFilesArray(files) {
  // Flatten the array recursively to handle any level of nesting
  const flatten = (arr) => {
    return arr.reduce((flat, toFlatten) => {
      return flat.concat(
        Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
      );
    }, []);
  };

  return flatten(files);
}


export function cleanAddressString(addressString) {
  if (!addressString) return '';

  // Split by commas and spaces, and clean each part
  const parts = addressString
    .split(/[\s,]+/) // Split by both spaces and commas
    .map(part => part.trim())
    .filter(Boolean);

  // Create a map to store unique words and their first occurrence position
  const wordPositions = new Map();
  const normalizeWord = (word) => word.toLowerCase().replace(/[^a-z0-9]/g, '');

  // First pass: record positions of normalized words
  parts.forEach((word, index) => {
    const normalized = normalizeWord(word);
    if (!wordPositions.has(normalized)) {
      wordPositions.set(normalized, {
        originalWord: word,
        position: index
      });
    }
  });

  // Second pass: reconstruct address with unique words in original order
  const uniqueParts = parts.filter((word, index) => {
    const normalized = normalizeWord(word);
    return wordPositions.get(normalized).position === index;
  });

  // Group numbers with their preceding words (for postal codes, street numbers, etc.)
  const result = [];
  for (let i = 0; i < uniqueParts.length; i++) {
    const current = uniqueParts[i];
    const next = uniqueParts[i + 1];

    if (next && /^\d+$/.test(next)) {
      result.push(current + ' ' + next);
      i++; // Skip the next iteration since we've used this number
    } else {
      result.push(current);
    }
  }

  return result.join(', ');
}