import React from 'react';
import { Link } from 'react-router-dom';
import { CustomIcon } from '../customAssets';
import CustomText from './CustomText';

const BackButton = ({ link, icon, text }) => {
  return (
    <Link to={link} className=' flex justify-center min-w-[112px]'>
      <CustomIcon icon={icon} className='mr-2' />
      <CustomText
        className='text-para-black text-sm font-roboto font-[600] '
        as='p'
      >
        {text ? text : 'Back to login'}
      </CustomText>
    </Link>
  );
};

export default BackButton;
