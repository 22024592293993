import { ceasataStamp } from '../../assets';

export default function FineArtTerms() {
  return (
    <div className='mx-auto max-w-[1200px]  pt-[50px] px-8'>
      <h1 className='text-[32px] font-bold'>Fine Art Terms</h1>

      <div class='mt-8'>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>1. Insured Risk:</h2>
          <p>
            Ceasta Assurance Inc. (CAI) provides transit insurance coverage for
            Mounted and Framed Artwork, Sculptures, Stretched Canvases, and all
            pieces related to fine art.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            2. Insure Limit with Proof of Purchase:
          </h2>
          <ul>
            <li>Maximum coverage up to $25,000.</li>
            <li>
              If exceeding the maximum limit of $25,000, please call CAI for
              approval.
            </li>
          </ul>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            3. Insure Limit without Proof of Purchase:
          </h2>
          <ul>
            <li>Maximum coverage up to $10,000.</li>
            <li>
              Refer to “10.3. Without Proof of Purchase” for more details.
            </li>
          </ul>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>4. Coverage:</h2>
          <p>
            This insurance policy covers the shipping of the insured artwork
            from the point of pick-up confirmation timestamped to the
            destination against damage, theft, or loss during transit. The
            shipping company that is hired to transport the insured artwork must
            be CAI-approved.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            5. Image of Artwork:
          </h2>
          <p>
            Clear images of the front and back of the artwork must be submitted
            for insurance purposes. CAI reserves the right to decline coverage
            if the images are not clear.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            6. Packaging of Artwork:
          </h2>
          <p>
            The artwork must be securely packed or encased. After it’s been
            packed or encased, provide a clear image of the package with an
            attached shipping label containing the name and address of the
            destination to CAI.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            7. Insured Value:
          </h2>
          <p>
            The insured value of the artwork will be determined by the declared
            value.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>8. Premium:</h2>
          <p>
            The premium for this insurance policy will be calculated as a
            percentage of the insured value of the shipment and will be charged
            to the merchant upon the conclusion of the transit phase. If the
            shipment is to be returned, the paid premium is non-refundable. Our
            policy does not cover the return of artwork.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            9. Pick-Up Confirmation:
          </h2>
          <p>
            Wait for confirmation from CAI before allowing the pickup of your
            artwork. Without this confirmation, no coverage will be provided.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            10. Deadline to File a Claim:
          </h2>
          <p>
            The insured must submit a claim to CAI within 72 hours after the
            scheduled arrival date, upon determination of damage, theft, or
            loss. Submit all necessary information, documentation, and evidence
            to support the claim. If the artwork has been damaged, CAI will
            contact the shipping company to provide a damage report and
            necessary documentation, to process the claim.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            11. Required Document(s) to File a Claim:
          </h2>
          <ol>
            <li className='mt-2'>
              <h3>11.1. Artwork Purchased From An Auction House:</h3>
              <p>
                Documentation must show the auction house, date, and final
                price.
              </p>
            </li>
            <li className='mt-2'>
              <h3>11.2. Artwork Purchased from a Gallery or Fine Art Store:</h3>
              <p>
                Proof of Purchase: Provide documentation that verifies the
                purchase of the artwork from a gallery or fine art store.
              </p>
            </li>
            <li className='mt-2'>
              <h3>11.3. Without Proof of Purchase:</h3>
              <p>
                In cases where there is no proof of purchase or receipt
                available, the maximum coverage is $10,000. CAI reserves the
                right to engage a certified appraiser to determine the true
                market value of the artwork. If the appraised value is lower
                than the declared value, CAI will only pay the appraised value
                and CAI will reimburse any excess paid premium on the claimed
                piece. Customers have the option to utilize their certified
                appraisal, provided it was conducted within the last 12 months.
                CAI will accept the declared value if it is equal to or lower
                than the appraised value. CAI will cover the cost of the
                appraisal and accessorial charges.
              </p>
            </li>
            <li className='mt-2'>
              <h3>
                11.4. The premium of the claim must be paid before the claim can
                be processed.
              </h3>
            </li>
          </ol>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            12. Covered Artwork:
          </h2>
          <p>
            CAI will only honor coverage for shipments that are picked up and
            with the confirmation timestamped, up to the declared value.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            13. Damaged Artwork:
          </h2>
          <ol>
            <li className='mt-2'>
              <h3>13.1. If Repairable:</h3>
              <p>
                CAI will present to the customer the scope of the restoration
                work for approval. CAI will make the arrangements to transport
                the artwork to the restoration company, upon the customer’s
                approval of the work. Detailed images will be sent to the
                customer after restoration has been completed. CAI will make
                arrangements to transport the artwork back to the customer. All
                expenses will be paid by CAI.
              </p>
            </li>
            <li className='mt-2'>
              <h3>13.2. If NOT Repairable (theft or loss):</h3>
              <p>
                CAI will pay the claim based on declared value. Proof of
                Purchase is required. Refer to Terms & Conditions number 9.
              </p>
            </li>
          </ol>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            14. Lost/Stolen Artwork:
          </h2>
          <p>
            If the artwork is lost or stolen during transit, CAI will conduct
            tracing with the transportation company. After 10 working days, if
            the artwork cannot be found, CAI will start the claim process.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            15. Damaged Frame:
          </h2>
          <ol>
            <li className='mt-2'>
              <h3>15.1. If Repairable:</h3>
              <p>
                If the frame has been damaged and not the artwork, while in
                transit, our Certified Professional Framer Fine Art Solutions
                will examine and repair the damage. CAI will present to the
                customer the scope of work for approval. CAI will make the
                arrangements to transport the artwork to the framer’s company,
                upon the customer’s approval of the work. Detailed images will
                be sent to the customer after a repair has been completed. CAI
                will make arrangements to transport the artwork back to the
                customer. All expenses will be paid by CAI.
              </p>
            </li>
            <li className='mt-2'>
              <h3>15.2. If NOT Repairable:</h3>
              <p>
                CAI will replace the frame with the same quality frame, by our
                contracted frame company, Fine Art Solutions.
              </p>
            </li>
          </ol>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            16. Notification of Damage, Theft, or Loss:
          </h2>
          <p>
            The insured must notify CAI of any damage, theft, or loss within 72
            hours, after the scheduled delivery date.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>17. Inspection:</h2>
          <p>
            The insurance company reserves the right to inspect the artwork and
            the packaging at the point of origin or the final destination to
            verify the damage.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>18. Exclusions:</h2>
          <p>
            This insurance policy does not cover damage caused by war, riots,
            strikes, confiscation by authorities, and any other perils excluded
            by the insurance company.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>19. Subrogation:</h2>
          <p>
            The insurance company reserves the right to recover the paid amount
            from any responsible party or carrier.
          </p>
        </div>

        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            20. Jurisdiction:
          </h2>
          <p>
            This insurance policy is governed by the laws of the jurisdiction
            where the shipment originated, and any dispute arising from this
            insurance policy shall be subject to the exclusive jurisdiction of
            the courts of that jurisdiction.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>21. Termination:</h2>
          <p>
            The insurance policy may be terminated by the policyholder or the
            insurance company for various reasons, including non-payment of
            premiums or fraudulent claims.
          </p>
        </div>
        <div className='mt-8'>
          <h2 class='text-[16px] font-semibold font-inter'>
            22. Indemnification:
          </h2>
          <p>
            The insurance company shall indemnify and hold the policyholder
            harmless from any claims, damages, or liabilities arising from the
            loss or damage of the item during transit or shipping.
          </p>
        </div>
      </div>

      <div className='flex justify-end w-[80%]'>
        <img
          src={`${ceasataStamp}`}
          alt='Stamp'
          width={150}
          className='transform -rotate-45 opacity-40'
        />
      </div>
    </div>
  );
}
