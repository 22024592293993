import React from 'react';
import {
  AddPaymentMethodAlertComponent,
  CustomHeading,
} from '../../../../common/custom';
import CustomContainer from '../../../../common/custom/CustomContainer';
import AllShipsList from '../allShipmentsList';

const AllShipments = ({ data }) => {
  const user = JSON.parse(localStorage.getItem('login-user'));
  return (
    <>
      <div className='border-[1px] border-[#EAECF0] border-[color:var(--Gray-200,#EAECF0)] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)] rounded-xl border-solid   '>
        {user?.entity?.isManualPayment === false && (
          <AddPaymentMethodAlertComponent />
        )}

        <CustomContainer className='p-6 min-h-[75px] flex justify-center items-center'>
          <CustomHeading className='p-1 lg:p-0 text-[#141414] font-inter text-small-heading font-[600] max-w-[1152px] w-full h-full  '>
            All Shipments
          </CustomHeading>
        </CustomContainer>

        <AllShipsList allShipmentsData={data} />
      </div>
    </>
  );
};

export default AllShipments;
