import toast from 'react-hot-toast';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';

import addStaffMemberSchema from '../schema/addStaffMember.schema';
import { addMemberMutation } from '../../../api';

import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../constants/paths';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import {
  FORMAT_PHONE_NUMBER_INPUT,
  ONLY_DIGITS,
} from '../../../helpers/helpers';
import { useState } from 'react';

export const useAddStaffMember = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  // const [addAddressCall] = useApi(addMemberMutation, storeAllStaffAction);
  const [addMembersCall, addMembersLoading] = useApi(addMemberMutation);

  const user = JSON.parse(localStorage.getItem('login-user'));

  const { handleSubmit, register, errors, trigger, setValue } =
    useHookFormSubmission(addStaffMemberSchema);

  const onSubmit = async (values) => {
    toast.loading('Adding staff member...');
    if (user.entityId != undefined && user.locationId != undefined) {
      const { data, errors } = await addMembersCall({
        entityId: parseInt(user.entityId),
        emailAddress: values.emailAddress,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        password: values.password,
        locationId: parseInt(user.locationId),
        userId: user.id,
      });
      if (data) {
        toast.dismiss();
        toast.success('Staff member successfully added', {
          duration: TOAST_TIME,
        });
        navigate(PATHS.STAFF);
      }
      if (errors) {
        toast.dismiss();
        toast.error(
          ' Member Creation Failed - Email Already Exists. Please use a different email address',
          {
            duration: TOAST_TIME,
          }
        );
      }
    } else {
      toast.dismiss();
      toast.error('enity id or location id not found', {
        duration: TOAST_TIME,
      });
    }
  };

  const cancelFunctionality = () => {
    navigate(PATHS.STAFF);
  };

  const handlePhoneNumberChange = (value) => {
    // Remove any non-digit characters

    const cleanedNumber = value && value.length <= 14 && ONLY_DIGITS(value);
    // Ensure the input adheres strictly to the (XXX) XXX-XXXX pattern
    if (cleanedNumber.length <= 10) {
      // Update the state
      setPhoneNumber(FORMAT_PHONE_NUMBER_INPUT(cleanedNumber));
    }
  };

  return {
    handleSubmit,
    register,
    onSubmit,
    errors,
    setValue,
    cancelFunctionality,
    trigger,
    phoneNumber,
    handlePhoneNumberChange,
    addMembersLoading,
  };
};
