export const dashboardStyles = {
    card: {
      boxShadow: "none",
      border: "1px solid #D0D5DD",
      padding: "24px",
      borderRadius: "18px",
      display: "flex",
      gap: "40px",
      flexDirection: "column",
    },
  
    welcomeText: {
      color: "#101828",
      fontSize: "30px",
      fontWeight: 600,
    },
  
    descriptionText: {
      color: "#475467",
    },
  
    innerCard: {
      boxShadow: "0px 1px 2px 0px #1018280F",
      flexGrow: 1,
      border: "1px solid #D0D5DD",
      padding: "24px",
      borderRadius: "18px",
      flexShrink: 0,
    },
  
    totalShipmentsText: {
      fontSize: "14px",
      color: "#475467",
    },
  
    shipmentCount: {
      fontSize: "30px",
      color: "#101828",
      fontWeight: 600,
    },
  
    percentageBox: (count) => ({
      background: count >= 10 ? "#ECFDF3" : "#FEF3F2",
      width: "64px",
      height: "24px",
      borderRadius: "16px",
      color: count >= 10 ? "#027A48" : "#F04438",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "4px",
    }),
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "16px",
      flexWrap: "wrap",
    },
    countBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
    },
  };