import React from 'react';
import { downArrow } from '../../../assets';
import useDropdown from './use-drop-down.hook';
import ValidationErrorText from '../ValidationErrorText';

const CustomDropdown = ({
  required,
  label,
  options,
  placeholder,
  register,
  name,
  setValue,
  value,
  errors,
  icon,
  onIconClick,
}) => {
  const {
    dropdownRef,
    handleToggleDropdown,
    selectedOption,
    isOpen,
    handleOptionClick,
    dropdownPosition,
  } = useDropdown(name, setValue);

  return (
    <div className='flex flex-col'>
      <div
        className={`relative text-left w-full flex items-start ${
          dropdownPosition === 'top' ? 'bottom-0' : ''
        }`}
        ref={dropdownRef}
      >
        <div className='w-full relative flex gap-[6px] flex-col justify-between'>
          <div className='flex justify-between'>
            {label && (
              <div className='text-[#344054] text-sm not-italic font-medium leading-5'>
                {label}
              </div>
            )}
            {icon && (
              <div className='flex'>
                <img
                  src={icon}
                  className='h-6 cursor-pointer'
                  onClick={onIconClick}
                />{' '}
                Add New
              </div>
            )}
          </div>
          <button
            onClick={handleToggleDropdown}
            type='button'
            className='text-[#98A2B3] text-base not-italic font-normal leading-6 inline-flex h-[44px] justify-start items-center w-full rounded-md border border-solid border-[#E7ECF4] shadow-sm px-4 py-2 bg-[#F8FAFB] hover:bg-[#F8FAFB] focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:bg-gray-200 transition ease-in-out duration-150'
          >
            {value ||
              selectedOption?.country ||
              selectedOption?.state ||
              selectedOption?.merchandiseType ||
              selectedOption?.name ||
              (selectedOption?.firstName
                ? selectedOption?.firstName + ' ' + selectedOption?.lastName
                : placeholder)}
            <div className='absolute right-2.5 bottom-2.5'>
              <img
                className={`${isOpen ? 'rotate-180' : ''}`}
                src={downArrow}
                alt=''
              />
            </div>
          </button>
        </div>

        {isOpen && (
          <div
            className={`origin-top-right absolute z-[10] py-2 ${
              dropdownPosition === 'top' ? 'bottom-12' : 'top-[100%]'
            } right-0 mt-2 w-full rounded-md shadow-lg bg-[#F8FAFB] ring-1 ring-black ring-opacity-5 overflow-y-auto max-h-60`}
          >
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option)}
                className='block px-4 py-2 text-sm  text-[#98A2B3] cursor-pointer bg-hover'
              >
                {option.firstName
                  ? option.firstName + ' ' + option.lastName
                  : option.country ||
                    option.state ||
                    option.merchandiseType ||
                    option.name}
              </div>
            ))}
          </div>
        )}
      </div>

      {!value && errors && errors[name] && (
        <div className='mt-1'>
          <ValidationErrorText>{errors[name].message}</ValidationErrorText>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
